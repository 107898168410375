import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import Map from '../components/common/Map';
import ContentStats from '../components/ratings/ContentStats';
import ReviewForm from '../components/ratings/ReviewForm';
import ReviewStats from '../components/ratings/ReviewStats';
import ReviewList from '../components/ratings/ReviewList';
import SharePopup from '../components/common/SharePopup';
import DealListingCard from '../components/deals/DealListingCard';
import LoadMore from '../components/common/LoadMore';

const DealDetails = () => {
    const { slug } = useParams();
    const [deal, setDeal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [showSharePopup, setShowSharePopup] = useState(false);
    const [otherDeals, setOtherDeals] = useState([]);
    const [visibleOtherDeals, setVisibleOtherDeals] = useState([]);
    const [hasMoreDeals, setHasMoreDeals] = useState(true);
    const [otherDealsVisibleCount, setOtherDealsVisibleCount] = useState(3);
    const [businessHasActiveSubscription, setBusinessHasActiveSubscription] = useState(false);
    const [isPremiumBusiness, setIsPremiumBusiness] = useState(false);

    const itemsPerPage = 6;

    const calculateDiscountPercentage = (price, discountedPrice) => {
        if (price && discountedPrice) {
            return ((price - discountedPrice) / price) * 100;
        }
        return 0;
    };

    const formatTime = (time) => {
        if (!time) return 'Closed';
        const [hour, minute] = time.split(':');
        const date = new Date(0, 0, 0, hour, minute);
        return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    };

    const getDealStatus = (startDate, endDate) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0); 
        const start = new Date(startDate);
        start.setHours(0, 0, 0, 0);
        const end = new Date(endDate);
        end.setHours(23, 59, 59, 999); 
        const msPerDay = 24 * 60 * 60 * 1000;
    
        if (today < start) {
            const daysToStart = Math.ceil((start - today) / msPerDay);
            return { status: 'upcoming', days: daysToStart };
        } else if (today > end) {
            const daysExpired = Math.floor((today - end) / msPerDay);
            return { status: 'expired', days: daysExpired };
        } else {
            const daysLeft = Math.ceil((end - today) / msPerDay);
            if (daysLeft === 1) {
                return { status: 'ending', days: 0 };
            }
            return { status: 'active', days: daysLeft };
        }
    };

    useEffect(() => {
        const fetchDealDetails = async () => {
            try {
                const response = await fetch(`/api/deal/${slug}/`);
                const data = await response.json();
                // console.log('Fetched deal details:', data);

                if (data.business_location && data.business_location.geometry) {
                    const geometry = data.business_location.geometry;
                    const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
                    const match = geometry.match(pointRegex);

                    if (match) {
                        setCoordinates({
                            lat: parseFloat(match[2]),
                            lng: parseFloat(match[1]),
                        });
                    }
                }

                setDeal(data);
                setOtherDeals(data.other_deals || []);
                setVisibleOtherDeals((data.other_deals || []).slice(0, itemsPerPage));
                setHasMoreDeals((data.other_deals || []).length > itemsPerPage);
                setBusinessHasActiveSubscription(data.business_has_active_subscription);
                setIsPremiumBusiness(data.is_premium_business);
            } catch (error) {
                // console.error('Error fetching deal details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchDealDetails();
    }, [slug]);



    const fetchReviews = useCallback(async () => {
        if (deal && deal.id) {
            try {
                await fetch(`/api/reviews-list/deal/${deal.id}/`);
            } catch (error) {
                // console.error('Error fetching reviews:', error);
            }
        }
    }, [deal]);

    useEffect(() => {
        if (deal) {
            fetchReviews();
        }
    }, [deal, fetchReviews]);

    const handleLoadMoreDeals = () => {
        const newDealsVisibleCount = otherDealsVisibleCount + itemsPerPage;
        setVisibleOtherDeals(otherDeals.slice(0, newDealsVisibleCount));
        setOtherDealsVisibleCount(newDealsVisibleCount);
        setHasMoreDeals(newDealsVisibleCount < otherDeals.length);
    };

    if (loading) {
        return <Spinner />;
    }

    if (!deal) {
        return <div>Deal not found</div>;
    }

    const shareableLink = `${window.location.origin}/deal/${slug}`;
    const discountPercentage = calculateDiscountPercentage(deal.price, deal.discounted_price);
    const dealStatus = getDealStatus(deal.start_date, deal.end_date);

    const whatsappLink = deal.formatted_whatsapp_number
        ? `https://wa.me/${deal.formatted_whatsapp_number}?text=I%20would%20like%20to%20enquire%20about%20this%20product:%20${encodeURIComponent(deal.title)}%20-%20listed%20on%20${encodeURIComponent(window.location.href)}`
        : `tel:${deal.business_phone}`;

    return (
        <>
            <section className="listing-header parallex-bg"></section>
            <div className="container">
                <div className="bg-white p-3">
                    <ContentStats contentType="deal" contentId={deal.id} />
                </div>
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="deal_detail position-relative">
                            <img src={deal.image || "assets/images/800x530.jpg"} alt={deal.title} className="img-fluid mb-3" />
                            
                            <div className="position-absolute top-0 start-0 m-2">
                                {discountPercentage > 0 && (
                                    <span className="badge bg-danger">
                                        {Math.round(discountPercentage)}% OFF
                                    </span>
                                )}
                            </div>
                            
                            <div className="position-absolute top-0 end-0 m-2">
                                {dealStatus.status === 'upcoming' && dealStatus.days <= 5 && (
                                    <span className="badge bg-info">
                                        Starts in {dealStatus.days} day{dealStatus.days !== 1 ? 's' : ''}
                                    </span>
                                )}
                                
                                {dealStatus.status === 'active' && (
                                    <span className="badge bg-warning text-dark">
                                        {dealStatus.days} day{dealStatus.days !== 1 ? 's' : ''} left
                                    </span>
                                )}
                                
                                {dealStatus.status === 'ending' && (
                                    <span className="badge bg-danger text-white">
                                        Ending today
                                    </span>
                                )}
                                
                                {dealStatus.status === 'expired' && (
                                    <span className="badge bg-secondary">
                                        Expired
                                    </span>
                                )}
                            </div>
    
                            <h2 className="mb-2">{deal.title}</h2>
                            <p className="text-muted"><i className="fa fa-map-marker"></i> {deal.business_name}</p>

                            <ReviewStats contentType="deal" contentId={deal.id} />

                            <div className="bg-light p-3 mb-3">
                                <h5 className="font-weight-bold">Highlights</h5>
                                <p>{deal.description}</p>
                            </div>

                            <div className="bg-light p-3 mb-3">
                                <h5 className="font-weight-bold">Pricing</h5>
                                <p><strong>Original Price:</strong> ${deal.price}</p>
                                {deal.discounted_price && (
                                    <>
                                        <p><strong>Discounted Price:</strong> ${deal.discounted_price}</p>
                                        <p><strong>Discount Percentage:</strong> {Math.round(discountPercentage)}%</p>
                                    </>
                                )}
                            </div>

                            <div className="bg-light p-3 mb-3" id="review">
                                <h5 className="font-weight-bold">Write a Review</h5>
                                <ReviewForm contentType="deal" 
                                    contentId={deal.id}
                                    onReviewSubmitted={() => {
                                        fetchReviews(); // Refresh the review list after submission
                                }}
                                 />
                               
                            </div>

                            <div className="bg-light p-3 mb-3" id="reviews">
                                <h5 className="font-weight-bold">Customer Reviews</h5>
                                <ReviewList contentType="deal" contentId={deal.id} />
                            </div>

                            {businessHasActiveSubscription && visibleOtherDeals.length > 0 && (
                                <div className="detail-wp" id="other-deals">
                                    <div className="detail-heading">
                                        <h3>Other Deals from {deal.business_name}</h3>
                                    </div>
                                    <div className="row">
                                        {visibleOtherDeals.map((otherDeal) => (
                                            <div key={otherDeal.id}>
                                                <DealListingCard deal={otherDeal} />
                                            </div>
                                        ))}
                                    </div>
                                    <LoadMore isLoading={false} hasMore={hasMoreDeals} onLoadMore={handleLoadMoreDeals} />
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="bg-white border p-3 mb-3">
                            <p className="text-danger"><strong>${deal.discounted_price}</strong> <del>${deal.price}</del> ({Math.round(discountPercentage)}% OFF)</p>

                            <div className="button-group" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <a href={whatsappLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-block">
                                {deal.formatted_whatsapp_number ? 'Chat on WhatsApp' : 'Call the Business'}
                                    </a>
                                    {isPremiumBusiness && (
                                        <a href={deal.deal_website_link} target="_blank" rel="noopener noreferrer" className="btn btn-info btn-block">
                                            Buy from Vendor Website
                                    </a>
                                    )}
                                <button className="btn btn-outline-secondary btn-block" onClick={() => setShowSharePopup(true)}>
                                    <i className="fa fa-share-alt"></i> Share This Deal
                                </button>
                            </div>
                        </div>

                        <div className="bg-white border p-3 mb-3">
                            <h5 className="font-weight-bold brand-bg-heading">Contact Info</h5>
                            <h6 className="font-weight-bold" style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>
                                <i className="fa fa-building"></i> {deal.business_name}
                            </h6>
                            <ul className="list-unstyled">
                                {deal.business_address && <li><i className="fa fa-map-marker"></i> {deal.business_address}</li>}
                                {deal.business_phone && <li><i className="fa fa-phone"></i> {deal.business_phone}</li>}
                                {deal.business_email && <li><i className="fa fa-envelope"></i> {deal.business_email}</li>}
                                {isPremiumBusiness && deal.business_website_url && (
                                    <li><i className="fa fa-globe"></i> <a href={deal.business_website_url} target="_blank" rel="noopener noreferrer">{deal.business_website_url}</a></li>
                                )}
                            </ul>
                        </div>

                        <div className="bg-white border p-3 mb-3">
                            <h5 className="font-weight-bold">Opening Hours</h5>
                            <ul className="list-unstyled">
                                {deal.business_hours && deal.business_hours.map((hour, index) => (
                                    <li key={index}>
                                        <strong>{hour.day_of_week}:</strong>{" "}
                                        {hour.is_closed ? 'Closed' : `${formatTime(hour.opening_time)} - ${formatTime(hour.closing_time)}`}
                                    </li>
                                ))}
                            </ul>
                        </div>

                        <div className="bg-white border p-3 mb-3">
                            <h5 className="font-weight-bold">Additional Information</h5>
                            <p><strong>Start Date:</strong> {new Date(deal.start_date).toLocaleDateString()}</p>
                            <p><strong>End Date:</strong> {new Date(deal.end_date).toLocaleDateString()}</p>
                            <p><strong>Available Stocks:</strong> {deal.available_stocks}</p>
                        </div>

                        {coordinates.lat && coordinates.lng && (
                            <div className="bg-white border p-3 mb-3">
                                <h5 className="font-weight-bold">Location</h5>
                                <Map latitude={coordinates.lat} longitude={coordinates.lng} />
                            </div>
                        )}
                    </div>
                </div>

                {showSharePopup && (
                    <SharePopup link={shareableLink} onClose={() => setShowSharePopup(false)} />
                )}
            </div>
        </>
    );
};

export default DealDetails;