import React from 'react';


const PrivacyPolicy = () => {
  return (
    <section className="listing-header parallex-bg">
            
    <div className="container">
      <div className="row">
        <div className="col-12">
          <h2>Privacy Policy</h2>
          <p>
            Welcome to Desi Firms, the online platform connecting you with a wide range of businesses, events, deals, and more! This Privacy Policy explains how we collect,
            use, and protect your personal information when you use our website or services. By accessing or using Desi Firms, you agree to the terms of this policy.
          </p>
          <h3>Last Updated:</h3>
          <p>[ 02 September 2024 ]</p>

          <h3>Our Commitment to Your Privacy</h3>
          <p>
            At Desi Firms, we prioritize your privacy. This policy explains how we handle your personal information, from collection and use to storage and disclosure.
            We also outline your rights and choices regarding your data. We collect the following personal information: name, email address, phone number (optional),
            business details (name, address, website, etc.), and IP addresses.
          </p>
          <p>
            This information helps us to verify your identity, process your transactions, and ensure that our platform remains secure and trustworthy.
          </p>

          <h3>Data Use and Sharing:</h3>
          <p>We primarily use the data we collect for the following purposes:</p>
          <ul>
            <li>Processing Subscriptions: We use your data to manage your subscription plan, including processing payments, generating invoices, and providing ongoing support.</li>
            <li>Verifying Business Legitimacy: We verify the legitimacy of your business using available information and public records to ensure a safe and trustworthy platform.</li>
            <li>Personalizing User Experience: We use your data to personalize your experience on our platform, such as suggesting relevant features and offers based on your interactions.</li>
            <li>Improving User Search Results: We use your data to improve the search experience on our platform, including displaying relevant search results based on location, keywords, and preferences.</li>
          </ul>

          <h4>Data Sharing with Third Parties:</h4>
          <p>We may share your data with third-party vendors in the following limited circumstances:</p>
          <ul>
            <li>
              Payment Processing: We share your payment information with Stripe, our secure payment processing partner, to facilitate subscription payments. Please refer to Stripe's Privacy Policy for more information about their data practices: <a href="https://stripe.com/au/legal/privacy-center" target="_blank" rel="noopener noreferrer">Stripe's Privacy Policy</a>.
            </li>
            <li>Legal and Regulatory Compliance: We may share your data with law enforcement or regulatory authorities if required by law or to comply with legal obligations.</li>
            <li>
              Business Transfers: In the event of a merger, acquisition, or asset sale, your data may be transferred to the acquiring entity. We will notify you in advance of any such transfer and ensure that the acquiring entity abides by the terms of this Privacy Policy.
            </li>
          </ul>
          <p>We will never share your data with third parties for marketing purposes without your explicit consent.</p>

          <h5>Your Choices:</h5>
          <p>You have the right to access, modify, or delete your personal data at any time through your user dashboard.</p>

          <h3>Location Data and Cookies:</h3>
          <p>Desi Firms uses your approximate location (derived from your IP address or other available means) to:</p>
          <ul>
            <li>Show relevant businesses and deals: We personalize search results and recommendations based on your location, helping you find nearby businesses and deals.</li>
            <li>Improve platform experience: Location data helps us analyze user behavior and trends, ultimately improving the overall platform experience.</li>
            <li>
              Session cookies: These temporary cookies help remember your preferences and activity during your browsing session, ensuring a smooth experience. They expire when you close your browser.
            </li>
            <li>
              Analytics cookies: We use anonymized analytics cookies to understand how users interact with our platform, allowing us to make data-driven improvements.
            </li>
          </ul>
          <p>
            You can control your cookie preferences through your browser settings. Most browsers allow you to block or clear cookies altogether or manage them on a site-by-site basis. Please note that disabling cookies may affect certain functionalities of our platform.
          </p>

          <h3>Updates to This Policy</h3>
          <p>This policy may change from time to time to reflect changes in our practices or legal requirements. We'll post any updates on our website and notify you of significant changes as required.</p>

          <h3>Subscription Model and Payment Processing</h3>
          <p>
            Desi Firms offers subscription plans for users. For secure payment processing, we integrate with Stripe, a third-party provider. Stripe handles payments directly, and we never store your full payment details. We may retain minimal information like payment method IDs and subscription IDs for billing purposes. Stripe's privacy practices are subject to their own policies, which you agree to when using our platform.
          </p>

          <h3>Manage Your Data with Confidence</h3>
          <p>Desi Firms gives you complete control over your information on the platform. Your dashboard empowers you to:</p>
          <ul>
            <li>Effortlessly manage all details: Regularly review, update, and edit your profile to ensure its accuracy and reflects your latest preferences.</li>
            <li>Keep information error-free: Discover any inaccuracies? Quickly and easily correct them directly in your dashboard.</li>
            <li>
              Full control over your presence: Decide to move on? Simply request complete data deletion through your dashboard, and we'll remove all your information from our system. Note: For security reasons, certain information like your Australian Business Number (ABN) may be retained for a limited period to prevent potential misuse of free trials. This information will never be used for marketing purposes and will be securely deleted as per our data retention policy.
            </li>
          </ul>
          <p>For any questions or concerns regarding your data, please contact our support team at <a href="mailto:desifirms@gmail.com">desifirms@gmail.com</a></p>

          <h3>Protecting Children's Privacy</h3>
          <p>
            Desi Firms takes children's privacy seriously. Our services are not intended for children under 13, and we do not knowingly collect personal information from them without verifiable parental consent. If we learn about such information being collected unintentionally, please contact us immediately for removal.
          </p>

          <h3>Business Approval and Banning</h3>
          <p>
            Desi Firms reserves the right to approve, deny, or ban businesses from listing on our platform. This decision may be based on various factors, including compliance with our terms of service, the nature of the business, or other relevant considerations. These decisions are final and not subject to appeal.
          </p>
          <p>
            By registering as a user on our platform, you confirm that you have read, comprehended, and agree to be bound by these Terms and Conditions. 
            <a href="/api/register/" className="ml-2">
              <span className="label">Click here to register.</span>
            </a>
          </p>
        </div>
      </div>
    </div>
    </section>
  );
};

export default PrivacyPolicy;