import React from 'react';
import { MapPin, Calendar, Clock, Globe } from 'lucide-react';
import StarRating from '../ratings/StarRating';  // Import the StarRating component

const EventCard = ({ event, isSortedByDistance, showTypeLabel }) => {
  const formatDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
  };

  const formatTime = (time) => {
    return new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
  };

  const getEventStatus = () => {
    const now = new Date();
    const eventStart = new Date(`${event.start_date}T${event.opening_time}`);
    const eventEnd = new Date(`${event.end_date}T${event.closing_time}`);

    if (now < eventStart) {
      return { text: 'Upcoming', class: 'badge bg-success' };
    } else if (now > eventEnd) {
      return { text: 'Expired', class: 'badge bg-danger' };
    } else {
      return { text: 'Ongoing', class: 'badge bg-primary' };
    }
  };

  const eventStatus = getEventStatus();

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100 shadow-sm">
        <div className="position-relative">
          <img 
            src={event.image || '/assets/images/default-event.jpg'} 
            className="card-img-top" 
            alt={event.title}
            style={{ height: '200px', objectFit: 'cover' }}
          />
          {eventStatus && (
            <span className={`position-absolute top-0 end-0 m-2 ${eventStatus.class}`}>
              {eventStatus.text}
            </span>
          )}
          {isSortedByDistance && event.distance !== undefined && (
            <span className="position-absolute bottom-0 start-0 m-2 badge bg-info">
              {typeof event.distance === 'number' ? `${event.distance.toFixed(2)} km away` : event.distance}
            </span>
          )}
        </div>
        <div className="card-body">
          {showTypeLabel && <span className="badge bg-warning mb-2">Event</span>}
          <h5 className="card-title mb-1">{event.title || 'Unnamed Event'}</h5>
          <div className="d-flex justify-content-between mb-2">
            <span className="badge bg-secondary">{event.city_name || 'City not available'}</span>
            <span className="badge bg-secondary">{event.category_name || 'Category not available'}</span>
          </div>
          <p className="card-text text-muted small">
            {event.description?.substring(0, 100) || 'No description available'}
          </p>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <StarRating rating={Math.round(event.average_rating || 0)} />
            <span className="text-muted small">
              {event.average_rating ? event.average_rating.toFixed(1) : 0} ({event.reviews_with_rating || 0} reviews)
            </span>
          </div>
          <ul className="list-unstyled mb-2">
            <li className="mb-1">
              <Calendar size={16} className="me-2" />
              {formatDate(event.start_date)}
            </li>
            {event.address && (
              <li className="mb-1">
                <MapPin size={16} className="me-2" />
                {event.address}
              </li>
            )}
            <li className="mb-1">
              <Clock size={16} className="me-2" />
              {formatTime(event.opening_time)} - {formatTime(event.closing_time)}
            </li>
            <li className="mb-1">
              <Globe size={16} className="me-2" />
              Time Zone: {event.timezone}
            </li>
          </ul>
        </div>
        <div className="card-footer">
          <a href={`/event/${event.slug}`} className="btn btn-primary btn-sm">View Event</a>
        </div>
      </div>
    </div>
  );
};

export default EventCard;


// import React from 'react';
// import { MapPin, Calendar, Clock, Globe, Star } from 'lucide-react';


// const EventCard = ({ event, isSortedByDistance, showTypeLabel }) => {
//   const formatDate = (date) => {
//     const dateObj = new Date(date);
//     return dateObj.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
//   };

//   const formatTime = (time) => {
//     return new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
//   };

//   const getEventStatus = () => {
//     const now = new Date();
//     const eventStart = new Date(`${event.start_date}T${event.opening_time}`);
//     const eventEnd = new Date(`${event.end_date}T${event.closing_time}`);

//     if (now < eventStart) {
//       return { text: 'Upcoming', class: 'badge bg-success' };
//     } else if (now > eventEnd) {
//       return { text: 'Expired', class: 'badge bg-danger' };
//     } else {
//       return { text: 'Ongoing', class: 'badge bg-primary' };
//     }
//   };

//   const eventStatus = getEventStatus();

//   return (
//     <div className="col-lg-4 col-md-6 mb-4">
//       <div className="card h-100 shadow-sm">
//         <div className="position-relative">
//           <img 
//             src={event.image || '/assets/images/default-event.jpg'} 
//             className="card-img-top" 
//             alt={event.title}
//             style={{ height: '200px', objectFit: 'cover' }}
//           />
//           {eventStatus && (
//             <span className={`position-absolute top-0 end-0 m-2 ${eventStatus.class}`}>
//               {eventStatus.text}
//             </span>
//           )}
//           {isSortedByDistance && event.distance !== undefined && (
//             <span className="position-absolute bottom-0 start-0 m-2 badge bg-info">
//               {typeof event.distance === 'number' ? `${event.distance.toFixed(2)} km away` : event.distance}
//             </span>
//           )}
//         </div>
//         <div className="card-body">
//           {showTypeLabel && <span className="badge bg-warning mb-2">Event</span>}  {/* Type label */}
//           <h5 className="card-title mb-1">{event.title || 'Unnamed Event'}</h5>
//           <div className="d-flex justify-content-between mb-2">
//             <span className="badge bg-secondary">{event.city_name || 'City not available'}</span>
//             <span className="badge bg-secondary">{event.category_name || 'Category not available'}</span>
//           </div>
//           <p className="card-text text-muted small">
//             {event.description?.substring(0, 100) || 'No description available'}
//           </p>
//           <ul className="list-unstyled mb-2">
//             <li className="mb-1">
//               <Calendar size={16} className="me-2" />
//               {formatDate(event.start_date)}
//             </li>
//             {event.address && (
//               <li className="mb-1">
//                 <MapPin size={16} className="me-2" />
//                 {event.address}
//               </li>
//             )}
//             <li className="mb-1">
//               <Clock size={16} className="me-2" />
//               {formatTime(event.opening_time)} - {formatTime(event.closing_time)}
//             </li>
//             <li className="mb-1">
//               <Globe size={16} className="me-2" />
//               Time Zone: {event.timezone}
//             </li>
//           </ul>
//         </div>
//         <div className="card-footer">
//         <a href={`/event/${event.slug}`} className="btn btn-primary btn-sm">View Event</a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EventCard;

// import React from 'react';
// import { MapPin, Clock, Calendar, Star, Globe } from 'lucide-react';

// const EventCard = ({ event, isSortedByDistance,  showTypeLabel }) => {
//   const formatDate = (date) => {
//     const dateObj = new Date(date);
//     return dateObj.toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric', year: 'numeric' });
//   };

//   const formatTime = (time) => {
//     return new Date(`2000-01-01T${time}`).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
//   };

//   const getEventStatus = () => {
//     const now = new Date();
//     const eventStart = new Date(`${event.start_date}T${event.opening_time}`);
//     const eventEnd = new Date(`${event.end_date}T${event.closing_time}`);

//     if (now < eventStart) {
//       return { text: 'Upcoming', class: 'badge bg-success' };
//     } else if (now > eventEnd) {
//       return { text: 'Expired', class: 'badge bg-danger' };
//     } else {
//       return { text: 'Ongoing', class: 'badge bg-primary' };
//     }
//   };

//   const eventStatus = getEventStatus();

//   return (
//     <div className="col-md-6 col-lg-4 mb-4">
//       <div className="card h-100">
//       {showTypeLabel && <span className="badge bg-warning mb-2">Event</span>}
//         <img src={event.image || '/assets/images/default-event.jpg'} className="card-img-top" alt={event.title} style={{height: '200px', objectFit: 'cover'}} />
//         <div className="card-body">
//           <div className="d-flex justify-content-between align-items-center mb-2">
//             <span className={eventStatus.class}>{eventStatus.text}</span>
//             <span className="badge bg-secondary">{event.city_name}</span>
//           </div>
//           <h5 className="card-title">{event.title}</h5>
//           <p className="card-text fw-bold text-primary mb-2">
//             <Calendar size={18} className="me-2" />
//             {formatDate(event.start_date)}
//           </p>
//           <p className="card-text fw-bold text-secondary mb-3">
//             <MapPin size={18} className="me-2" />
//             {event.address}
//           </p>
//           <ul className="list-unstyled">
//             <li className="mb-2">
//               <Clock size={16} className="me-2" />
//               {formatTime(event.opening_time)} - {formatTime(event.closing_time)}
//             </li>
//             <li className="mb-2">
//               <Globe size={16} className="me-2" />
//               Time Zone:
//               {event.timezone}
//             </li>
//             {event.category_name && (
//               <li className="mb-2">
//                 <Star size={16} className="me-2" />
//                 {event.category_name}
//               </li>
//             )}
//             {isSortedByDistance && event.distance !== undefined && (
//               <li>
//                 <span className="badge bg-info">
//                   {typeof event.distance === 'number' ? `${event.distance.toFixed(2)} km away` : event.distance}
//                 </span>
//               </li>
//             )}
//           </ul>
//           <p className="card-text text-muted">{event.description?.substring(0, 100)}...</p>
//         </div>
//         <div className="card-footer">
//           <a href={`/event/${event.id}`} className="btn btn-primary btn-sm">View Event</a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EventCard;