import React, { useState, useEffect } from 'react';
import { getCookie } from '../../utils/csrf';
import LoginPopup from '../common/LoginPopup';
import { Star } from 'lucide-react';
import Spinner from '../common/Spinner';

const ReviewForm = ({ contentType, contentId, onReviewSubmitted }) => {
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [hasReview, setHasReview] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const checkAuthStatus = async () => {
            try {
                const response = await fetch('/api/auth-status/', {
                    credentials: 'include',
                });
                const data = await response.json();
                setIsAuthenticated(data.isAuthenticated);

                if (data.isAuthenticated) {
                    const emailResponse = await fetch('/api/get-user-email/', {
                        credentials: 'include',
                    });
                    const emailData = await emailResponse.json();
                    setUserEmail(emailData.email);

                    const reviewResponse = await fetch(`/api/review/${contentType}/${contentId}/`);
                    if (reviewResponse.ok) {
                        const reviewData = await reviewResponse.json();
                        if (reviewData.user_review) {
                            setRating(reviewData.user_review.rating);
                            setComment(reviewData.user_review.comment);
                            setHasReview(true);
                        }
                    }
                }
            } catch (error) {
                // console.error('Error checking auth status:', error);
            }
        };
        checkAuthStatus();
    }, [contentType, contentId]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!isAuthenticated) {
            setShowPopup(true);
            return;
        }

        setLoading(true);
        setSuccessMessage('');
        setError('');

        if (!rating) {
            setError('Please select a rating.');
            setLoading(false);
            return;
        }

        const formData = { rating, comment };

        try {
            const response = await fetch(`/api/review/${contentType}/${contentId}/`, {
                method: hasReview ? 'PUT' : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const data = await response.json();
                onReviewSubmitted(data);
                setSuccessMessage(hasReview ? 'Review updated successfully!' : 'Review submitted successfully!');
                setRating(data.rating);
                setComment(data.comment);
                setHasReview(true);
                setIsEditing(false);
            } else {
                // console.error('Error submitting review:', response.statusText);
            }
        } catch (error) {
            // console.error('Error submitting review:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const response = await fetch(`/api/delete-review/${contentType}/${contentId}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            });

            if (response.ok) {
                setRating(0);
                setComment('');
                setHasReview(false);
                setIsEditing(false);
                setSuccessMessage('Review deleted successfully.');
            } else {
                // console.error('Error deleting review:', response.statusText);
            }
        } catch (error) {
            // console.error('Error deleting review:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirmLogin = () => {
        window.location.href = `/api/login/?next=${window.location.pathname}`;
    };

    const renderStars = (count, interactive = false) => {
        return Array(5).fill(0).map((_, index) => (
            <Star
                key={index}
                size={24}
                onClick={() => interactive && setRating(index + 1)}
                fill={index < count ? "#FFD700" : "none"}
                stroke={index < count ? "#FFD700" : "#000000"}
                style={{ cursor: interactive ? 'pointer' : 'default' }}
            />
        ));
    };

    const renderReviewForm = () => (
        <div className="review-form">
            <h3>{hasReview ? 'Edit Review' : 'Write Review'}</h3>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label>Your Rating for this listing</label>
                    <div className="listing_rating">
                        {renderStars(rating, true)}
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                </div>
                <div className="form-group">
                    <label>Email</label>
                    <input type="email" className="form-control" disabled value={userEmail} />
                </div>
                <div className="form-group">
                    <label>Review</label>
                    <textarea
                        className="form-control"
                        placeholder="Your Experience"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                </div>
                <div className="form-group d-flex gap-2">
                    <input type="submit" className="btn btn-primary" value={hasReview ? 'Update Review' : 'Submit Review'} />
                    {isEditing && (
                        <button type="button" className="btn btn-secondary" onClick={() => setIsEditing(false)}>Cancel</button>
                    )}
                </div>
            </form>
        </div>
    );

    const renderExistingReview = () => (
        <div>
            <h3>Your Review</h3>
            <div className="listing_rating">
                {renderStars(rating)}
            </div>
            <p>Comment: {comment}</p>
            <div className="d-flex gap-2 mt-2">
                <button className="btn btn-primary" onClick={handleEdit}>Edit Review</button>
                <button className="btn btn-danger" onClick={handleDelete}>Delete Review</button>
            </div>
        </div>
    );

    if (loading) return <Spinner />;

    return (
        <div>
            {hasReview && !isEditing ? renderExistingReview() : renderReviewForm()}
            {successMessage && <p className="text-success">{successMessage}</p>}
            {showPopup && <LoginPopup message="You need to be logged in to submit a review." onConfirm={handleConfirmLogin} onCancel={() => setShowPopup(false)} />}
        </div>
    );
};

export default ReviewForm;

