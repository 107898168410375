import React from 'react';
import DealList from '../components/deals/DealList';


const Deals = () => {
  return (
    <div>
    
      <DealList />
    </div>
  );
};

export default Deals;