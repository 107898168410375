import React from 'react';
import ClassifiedList from '../components/classifieds/ClassifiedList';

const Classifieds = () => {
  return (
    <div>
      <ClassifiedList />
    </div>
  );
};

export default Classifieds;