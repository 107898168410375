import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../components/common/Spinner';
import Map from '../components/common/Map';
import ContentStats from '../components/ratings/ContentStats';
import ReviewForm from '../components/ratings/ReviewForm';
import ReviewStats from '../components/ratings/ReviewStats';
import ReviewList from '../components/ratings/ReviewList';
import SharePopup from '../components/common/SharePopup';

const EventDetails = () => {
    const { slug } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
    const [showSharePopup, setShowSharePopup] = useState(false);

    useEffect(() => {
        const fetchEventDetails = async () => {
            try {
                const response = await fetch(`/api/event/${slug}/`);
                const data = await response.json();
                // console.log('Fetched event details:', data);

                const geometry = data.geometry;
                const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
                const match = geometry.match(pointRegex);

                if (match) {
                    setCoordinates({
                        lat: parseFloat(match[2]),
                        lng: parseFloat(match[1]),
                    });
                }

                setEvent({ ...data.properties, id: data.id });
            } catch (error) {
                // console.error('Error fetching event details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchEventDetails();
    }, [slug]);

    const fetchReviews = useCallback(async () => {
        if (event && event.id) {
            try {
                await fetch(`/api/reviews-list/event/${event.id}/`);
            } catch (error) {
                // console.error('Error fetching reviews:', error);
            }
        }
    }, [event]);

    useEffect(() => {
        if (event) {
            fetchReviews();
        }
    }, [event, fetchReviews]);

    if (loading) {
        return <Spinner />;
    }

    if (!event) {
        return <div>Event not found</div>;
    }

    const shareableLink = `${window.location.origin}/event/${slug}`;

    return (
        <div>
            <section id="inner_banner_new" className="intro-bg parallex-bg section-padding">
                <div className="container">
                    <div className="row">
                        {/* You can add a title or other content here if needed */}
                    </div>
                </div>
                <div className="dark-overlay"></div>
            </section>
           
            <div className="container mt-2">          
                <div className="row">
                <div className="bg-white p-2 mt-5">      
                            <ContentStats contentType="event" contentId={event.id} />
                        </div>  
                    <div className="col-lg-8 col-md-12">
                        
                        <div className="event_detail">
                            <div className="event_img">
                                <img src={event.image || "assets/images/800x530.jpg"} alt={event.title} />
                            </div>
                            
                            <h1 className="event_detail_title">{event.title}</h1>
                        
                        </div>

                        {/* <div className="layout-three-address">
                            <div className="view_map_style3">
                                <a href="#viewmap" className="js-target-scroll"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
                            </div>
                            <p className="style_3_address">{event.address}</p>
                            <span className="post-date">
                        <p className="style_3_address">Start Date: {new Date(event.start_date).toLocaleDateString()}</p>
                        </span>
                        </div>
                        <p className="style_3_address">City:{event.city_name || 'City not available'}</p> */}
                        <div className="layout-three-address d-flex align-items-start mb-3">
                            <div className="view_map_style3">
                                <a href="#viewmap" className="js-target-scroll">
                                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                                </a>
                            </div>
                            <div>
                                <p className="style_3_address">
                                    {event.address}
                                </p>
                                <p className="style_3_address text-muted mb-1">
                                    <i className="fa fa-calendar-alt"></i> Start Date: {new Date(event.start_date).toLocaleDateString()}
                                </p>
                                <p className="style_3_address text-muted">
                                    <i className="fa fa-city"></i> City: {event.city_name || 'City not available'}
                                </p>
                                <ReviewStats contentType="event" contentId={event.id} />
                            </div>
                           
                        </div>

                       

                        <div className="detail-wp">
                            <div className="detail-heading">
                                <h3>Event Schedule</h3>
                            </div>
                            <div className="date-item">
                                <div className="event_start_m">
                                    <div className="date-item_date">Event Start Date</div>
                                    <div className="date-item_hours">{new Date(event.start_date).toLocaleDateString()}</div>
                                </div>
                                <div className="event_start_t">
                                    <div className="date-item_date">Opening Time</div>
                                    <div className="date-item_hours">{event.opening_time}</div>
                                </div>
                            </div>
                            <div className="date-item">
                                <div className="event_start_m">
                                    <div className="date-item_date">Event End Date</div>
                                    <div className="date-item_hours">{new Date(event.end_date).toLocaleDateString()}</div>
                                </div>
                                <div className="event_start_t">
                                    <div className="date-item_date">Closing Time</div>
                                    <div className="date-item_hours">{event.closing_time}</div>
                                </div>
                            </div>
                        </div>

                        <div className="detail-wp">
                            <div className="detail-heading">
                                <h3>Event Venue</h3>
                            </div>
                            <span className="map_icon"><i className="fa fa-map-marker"></i></span>
                            {event.address}
                        </div>

                        {/* <div className="detail-wp map-container" id="viewmap">
                            <div className="detail-heading">
                                <h3>Map</h3>
                            </div>
                            {coordinates.lat && coordinates.lng && (
                                <Map latitude={coordinates.lat} longitude={coordinates.lng} />
                            )}
                        </div> */}

                        <div className="detail-wp">
                            <div className="detail-heading">
                                <h3>About Event</h3>
                            </div>
                            <p>{event.description}</p>
                        </div>

                        <div className="detail-wp" id="review">
                            <div className="detail-heading">
                                <h3>Write Comment</h3>
                            </div>
                            <ReviewForm contentType="event" contentId={event.id} onReviewSubmitted={fetchReviews} />
                        </div>

                        <div className="detail-wp" id="reviews">
                            <ReviewList contentType="event" contentId={event.id} />
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <div className="event_detail">
                            <div className="listing-sidebar">
                                <div className="sidebar_wrap listing_action_btn">
                                    <ul>
                                        <li><a onClick={() => setShowSharePopup(true)}><i className="fa fa-share-alt"></i>Share This</a></li>
                                        <li><a href="#review" className="js-target-scroll"><i className="fa fa-comment"></i>Write Comment</a></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="listing-sidebar listing_contact_info">
                                <div className="sidebar-heading">
                                    <h5>Contact Info</h5>
                                </div>
                                <ul>
                                    {event.address && (
                                        <li><i className="fa fa-map-marker"></i>{event.address}</li>
                                    )}
                                    {event.city_name && (
                                        <li><i className="fa fa-globe"></i>{event.city_name}</li>
                                    )}
                                    {event.phone && (
                                        <li><i className="fa fa-phone"></i>{event.phone}</li>
                                    )}
                                    {event.email && (
                                        <li><i className="fa fa-envelope"></i>{event.email}</li>
                                    )}
                                    {event.website_url && (
                                        <li><i className="fa fa-globe"></i>{event.website_url}</li>
                                    )}
                                    {event.timezone && (
                                        <li><i className="fa fa-clock"></i>{event.timezone}</li>
                                    )}
                                </ul>
                            </div>
                            {coordinates.lat && coordinates.lng && (
                            <div className="bg-white border p-3 mb-3">
                                <h5 className="font-weight-bold">Location</h5>
                                <Map latitude={coordinates.lat} longitude={coordinates.lng} />
                            </div>
                        )}
                          
                           
                        </div>
                    </div>
                </div>
            </div>

            {showSharePopup && (
                <SharePopup link={shareableLink} onClose={() => setShowSharePopup(false)} />
            )}
        </div>
    );
};

export default EventDetails;

