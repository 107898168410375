import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Search from '../common/Search';
import Filters from '../layout/Filters';
import { useUser } from '../../context/UserContext';
import DealListingCard from './DealListingCard';
import Spinner from '../common/Spinner';
import LoadMore from '../common/LoadMore';

const DealsList = () => {
  const [allDeals, setAllDeals] = useState([]); 
  const [visibleDeals, setVisibleDeals] = useState([]); 
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ratingFilter, setRatingFilter] = useState([]); // Update here to use an array like in BusinessList
  const [searchParams, setSearchParams] = useSearchParams();
  const [userLocation, setUserLocation] = useState(null);
  const { categories, cities } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true); 
  const itemsPerPage = 12; 
  const [visibleCount, setVisibleCount] = useState(itemsPerPage); 

  const keyword = searchParams.get('keyword') || '';
  const category = searchParams.get('category') || '';

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
        },
        (error) => {
          // console.error("Error getting user location:", error);
          setUserLocation('144.9631 -37.8136'); 
        }
      );
    } else {
      // console.log("Geolocation is not supported by this browser.");
      setUserLocation('144.9631 -37.8136'); 
    }
  }, []);

  useEffect(() => {
    const fetchDeals = async () => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        if (category) params.append('category', category);
        if (keyword) params.append('keyword', keyword);
        if (userLocation) params.append('location', userLocation);
        if (ratingFilter.length > 0) {
          const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
          params.append('rating', minRating);
        }
  
        const response = await fetch(`/api/deal_list/?${params.toString()}`);
        const data = await response.json();
        console.log({"api response":data});
  
        // Check for error in response
        if (data.error) {
          console.error("API Error:", data.error);
          setAllDeals([]);
          setVisibleDeals([]);
          setHasMore(false);
          return;
        }
  
        // Handle the features array from the response
        if (data && data.features && Array.isArray(data.features)) {
          const dealsData = data.features.map(feature => {
            // Extract nested business location data
            const businessLocation = feature.business_location || {};
            
            return {
              ...feature,
              location: businessLocation.geometry || null,
              business_name: feature.business_name || businessLocation?.properties?.title,
            };
          });
  
          setAllDeals(dealsData);
          setVisibleDeals(dealsData.slice(0, itemsPerPage));
          setHasMore(dealsData.length > itemsPerPage);
        } else {
          setAllDeals([]);
          setVisibleDeals([]);
          setHasMore(false);
        }
      } catch (error) {
        console.error('Error fetching deals:', error);
        setAllDeals([]);
        setVisibleDeals([]);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchDeals();
  }, [searchParams, category, keyword, userLocation, ratingFilter]);

  const handleLoadMore = () => {
    const newVisibleCount = visibleCount + itemsPerPage;
    setVisibleDeals(allDeals.slice(0, newVisibleCount));
    setVisibleCount(newVisibleCount);
    setHasMore(newVisibleCount < allDeals.length);
  };

  const handleSearch = ({ category, keyword }) => {
    setSearchParams({ category, keyword });
    setVisibleCount(itemsPerPage); 
  };

  useEffect(() => {
    const today = new Date();
    
    const filteredDeals = allDeals.filter(deal => {
      let matchesFilter = true;
  
      if (filter) {
        matchesFilter = matchesFilter && deal.category_name?.toLowerCase() === filter.toLowerCase();
      }
  
      if (cityFilter) {
        matchesFilter = matchesFilter && deal.city_name?.toLowerCase() === cityFilter.toLowerCase();
      }
  
      if (keyword) {
        const lowerKeyword = keyword.toLowerCase();
        matchesFilter = matchesFilter && (
          (deal.title && deal.title.toLowerCase().includes(lowerKeyword)) ||
          (deal.description && deal.description.toLowerCase().includes(lowerKeyword))
        );
      }

      if (ratingFilter.length > 0) {
        const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
        // console.log(`Checking if deal meets rating: ${deal.title} with average_rating: ${deal.average_rating}`);
        matchesFilter = matchesFilter && deal.average_rating !== undefined && deal.average_rating >= minRating;
    }
  
      const startDate = new Date(deal.start_date);
      const diffDays = (startDate - today) / (1000 * 60 * 60 * 24);
  
      if (diffDays > 5) {
        matchesFilter = false;
      }
  
      return matchesFilter;
    });
  
    const sortedDeals = filteredDeals.sort((a, b) => {
      if (sort === 'distance_asc') {
        return (a.distance || Infinity) - (b.distance || Infinity);
      } else if (sort === 'distance_desc') {
        return (b.distance || -Infinity) - (a.distance || -Infinity);
      } else if (sort === 'highest_discount') {
        return b.discount_percentage - a.discount_percentage;
      } else if (sort === 'lowest_discount') {
        return a.discount_percentage - b.discount_percentage;
      } else if (sort === 'asc') {
        return a.title.localeCompare(b.title);
      } else if (sort === 'desc') {
        return b.title.localeCompare(a.title);
      }
      return 0;
    });
  
    setVisibleDeals(sortedDeals.slice(0, visibleCount));
    setHasMore(visibleCount < sortedDeals.length);
  }, [allDeals, filter, cityFilter, ratingFilter, sort, keyword, category, visibleCount, categories, cities]);

  const handleFilterChange = (e) => {
    const [type, value] = e.target.value.split(':');
    switch (type) {
      case 'category':
        setFilter(value);
        break;
      case 'city':
        setCityFilter(value);
        break;
      case 'rating':
        setRatingFilter(prevRating => {
          if (prevRating.includes(value)) {
            return prevRating.filter(r => r !== value);
          } else {
            return [...prevRating, value];
          }
        });
        break;
      default:
        setSort(e.target.value);
    }
    setVisibleCount(itemsPerPage); 
  };

  const handleClearFilters = () => {
    setFilter('');
    setCityFilter('');
    setRatingFilter([]); // Reset the rating filter
    setSort('');
    setVisibleCount(itemsPerPage); 
  };

  const isSortedByDistance = sort === 'distance_asc' || sort === 'distance_desc';

  return (
    <div data-new-title="Deals Listings | Your Site Title">
      <section id="banner" className="parallex-bg section-padding">
        <div className="container">
          <div className="white-text text-center div_zindex">
            <h1>Deals Listings</h1>
            <Search categories={categories} onSearch={handleSearch} />
          </div>
        </div>
        <div className="dark-overlay"></div>
      </section>

      <section id="inner_pages">
        <div className="container content-space-1">
          <div className="row">
            <div className="col-lg-3">
              <div className="sticky-below-nav">
                <div className="navbar-expand-lg mb-5">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="navbar-toggler btn btn-white mb-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarVerticalNavMenuEg2"
                      aria-label="Toggle navigation"
                      aria-expanded="false"
                      aria-controls="navbarVerticalNavMenuEg2"
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <span className="text-dark">Filter</span>
                        <span className="navbar-toggler-default">
                          <i className="bi-list"></i>
                        </span>
                        <span className="navbar-toggler-toggled">
                          <i className="bi-x"></i>
                        </span>
                      </span>
                    </button>
                  </div>

                  <div id="navbarVerticalNavMenuEg2" className="collapse navbar-collapse show">
                    <div className="w-100">
                      <Filters
                        categories={categories}
                        cities={[...new Set(allDeals.map(deal => deal.city_name).filter(city => city))]}
                        onFilterChange={handleFilterChange}
                        onClearFilters={handleClearFilters}
                        selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
              {keyword || category ? (
              <div className="alert alert-info text-center">
                 <p>Your search results for {keyword && `"${keyword}"`} {category && `in category "${category}"`}:</p>
               </div>
            ) : (
                <div className="alert alert-info text-center">
                  <p>Showing all Deals:</p>
               </div>
              )}

              <div className="sticky-below-nav sticky-sort-dropdown">
                <div className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
                  <h5>Deal Listings</h5>
                  <div className="d-flex align-items-center">
                    <div className="mb-2 mb-sm-0 me-sm-2">
                      <select
                        className="form-select form-select-sm"
                        onChange={handleFilterChange}
                        value={sort}
                      >
                        <option value="">Sort by</option>
                        <option value="asc">A-Z (asc order)</option>
                        <option value="desc">Z-A (desc order)</option>
                        <option value="distance_asc">Location (nearest to farthest)</option>
                        <option value="distance_desc">Location (farthest to nearest)</option>
                        <option value="highest_discount">Highest Discount</option>
                        <option value="lowest_discount">Lowest Discount</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {isLoading && visibleCount === itemsPerPage ? (
                <Spinner />
              ) : visibleDeals.length > 0 ? (
                <>
                  <div className="row">
                    {visibleDeals.map(deal => (
                      <DealListingCard
                        key={deal.id}
                        deal={deal}
                        isSortedByDistance={isSortedByDistance}
                      />
                    ))}
                  </div>
                  <LoadMore isLoading={isLoading} hasMore={hasMore} onLoadMore={handleLoadMore} />
                </>
              ) : (
                <div className="alert alert-info text-center">
                  <p>Sorry, there are no deals to show at the moment.</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DealsList;

// import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import Search from '../common/Search';
// import Filters from '../layout/Filters';
// import { useUser } from '../../context/UserContext';
// import DealListingCard from './DealListingCard';
// import Spinner from '../common/Spinner';
// import LoadMore from '../common/LoadMore';

// const DealsList = () => {
//   const [allDeals, setAllDeals] = useState([]); 
//   const [visibleDeals, setVisibleDeals] = useState([]); 
//   const [filter, setFilter] = useState('');
//   const [sort, setSort] = useState('');
//   const [cityFilter, setCityFilter] = useState('');
//   const [ratingFilter, setRatingFilter] = useState('');
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [userLocation, setUserLocation] = useState(null);
//   const { categories, cities } = useUser();
//   const [isLoading, setIsLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true); 
//   const itemsPerPage = 1; 
//   const [visibleCount, setVisibleCount] = useState(itemsPerPage); 

//   const keyword = searchParams.get('keyword') || '';
//   const category = searchParams.get('category') || '';

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
//         },
//         (error) => {
//           console.error("Error getting user location:", error);
//           setUserLocation('144.9631 -37.8136'); 
//         }
//       );
//     } else {
//       console.log("Geolocation is not supported by this browser.");
//       setUserLocation('144.9631 -37.8136'); 
//     }
//   }, []);

//   useEffect(() => {
//     const fetchDeals = async () => {
//       setIsLoading(true);
//       try {
//         const params = new URLSearchParams();
//         if (category) params.append('category', category);
//         if (keyword) params.append('keyword', keyword);
//         if (userLocation) params.append('location', userLocation);

//         const response = await fetch(`/api/deal_list/?${params.toString()}`);
//         const data = await response.json();
//         console.log({"api response":data});

//         if (data.features && Array.isArray(data.features)) {
//           const dealsData = data.features.map(feature => ({
//             ...feature,
//             ...feature.properties, 
//             location: feature.geometry,
//           }));

//           setAllDeals(dealsData);
//           setVisibleDeals(dealsData.slice(0, itemsPerPage));
//           setHasMore(dealsData.length > itemsPerPage);
//         } else {
//           console.error('Unexpected API response:', data);
//           setAllDeals([]);
//           setVisibleDeals([]);
//           setHasMore(false);
//         }
//       } catch (error) {
//         console.error('Error fetching deals:', error);
//         setAllDeals([]);
//         setVisibleDeals([]);
//         setHasMore(false);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchDeals();
//   }, [searchParams, category, keyword, userLocation]);

//   const handleLoadMore = () => {
//     const newVisibleCount = visibleCount + itemsPerPage;
//     setVisibleDeals(allDeals.slice(0, newVisibleCount));
//     setVisibleCount(newVisibleCount);
//     setHasMore(newVisibleCount < allDeals.length);
//   };

//   const handleSearch = ({ category, keyword }) => {
//     setSearchParams({ category, keyword });
//     setVisibleCount(itemsPerPage); 
//   };

//   useEffect(() => {
//     const today = new Date();
    
//     const filteredDeals = allDeals.filter(deal => {
//       let matchesFilter = true;
  
//       if (filter) {
//         matchesFilter = matchesFilter && deal.category_name?.toLowerCase() === filter.toLowerCase();
//       }
  
//       if (cityFilter) {
//         matchesFilter = matchesFilter && deal.city_name?.toLowerCase() === cityFilter.toLowerCase();
//       }
  
//       if (keyword) {
//         const lowerKeyword = keyword.toLowerCase();
//         matchesFilter = matchesFilter && (
//           (deal.title && deal.title.toLowerCase().includes(lowerKeyword)) ||
//           (deal.description && deal.description.toLowerCase().includes(lowerKeyword))
//         );
//       }
  
//       const startDate = new Date(deal.start_date);
//       const diffDays = (startDate - today) / (1000 * 60 * 60 * 24);
  
//       if (diffDays > 5) {
//         matchesFilter = false;
//       }
  
//       return matchesFilter;
//     });
  
//     // Define `sortedDeals` within the scope
//     const sortedDeals = filteredDeals.sort((a, b) => {
//       if (sort === 'distance_asc') {
//         return (a.distance || Infinity) - (b.distance || Infinity);
//       } else if (sort === 'distance_desc') {
//         return (b.distance || -Infinity) - (a.distance || -Infinity);
//       } else if (sort === 'highest_discount') {
//         return b.discount_percentage - a.discount_percentage;
//       } else if (sort === 'lowest_discount') {
//         return a.discount_percentage - b.discount_percentage;
//       } else if (sort === 'asc') {
//         return a.title.localeCompare(b.title);
//       } else if (sort === 'desc') {
//         return b.title.localeCompare(a.title);
//       }
//       return 0;
//     });
  
//     setVisibleDeals(sortedDeals.slice(0, visibleCount));
//     setHasMore(visibleCount < sortedDeals.length);
//   }, [allDeals, filter, cityFilter, ratingFilter, sort, keyword, category, visibleCount, categories, cities]);

//         console.log("Filter:", filter);
//         console.log("City Filter:", cityFilter);
//         console.log("Keyword:", keyword);
//         // console.log("Sorted Deals:", sortedDeals);

//   const handleFilterChange = (e) => {
//     const [type, value] = e.target.value.split(':');
//     switch (type) {
//       case 'category':
//         setFilter(value);
//         break;
//       case 'city':
//         setCityFilter(value);
//         break;
//       case 'rating':
//         setRatingFilter(value);
//         break;
//       default:
//         setSort(e.target.value);
//     }
//     setVisibleCount(itemsPerPage); 
//   };

//   const handleClearFilters = () => {
//     setFilter('');
//     setCityFilter('');
//     setRatingFilter('');
//     setSort('');
//     setVisibleCount(itemsPerPage); 
//   };

//   const isSortedByDistance = sort === 'distance_asc' || sort === 'distance_desc';
 
//   return (
//     <div data-new-title="Deals Listings | Your Site Title">
//       <section id="banner" className="parallex-bg section-padding">
//         <div className="container">
//           <div className="white-text text-center div_zindex">
//             <h1>Deals Listings</h1>
//             <Search categories={categories} onSearch={handleSearch} />
//           </div>
//         </div>
//         <div className="dark-overlay"></div>
//       </section>

//       <section id="inner_pages">
//         <div className="container content-space-1">
//           <div className="row">
//             <div className="col-lg-3">
//               <div className="sticky-below-nav">
//                 <div className="navbar-expand-lg mb-5">
//                   <div className="d-grid">
//                     <button
//                       type="button"
//                       className="navbar-toggler btn btn-white mb-3"
//                       data-bs-toggle="collapse"
//                       data-bs-target="#navbarVerticalNavMenuEg2"
//                       aria-label="Toggle navigation"
//                       aria-expanded="false"
//                       aria-controls="navbarVerticalNavMenuEg2"
//                     >
//                       <span className="d-flex justify-content-between align-items-center">
//                         <span className="text-dark">Filter</span>
//                         <span className="navbar-toggler-default">
//                           <i className="bi-list"></i>
//                         </span>
//                         <span className="navbar-toggler-toggled">
//                           <i className="bi-x"></i>
//                         </span>
//                       </span>
//                     </button>
//                   </div>

//                   <div id="navbarVerticalNavMenuEg2" className="collapse navbar-collapse show">
//                     <div className="w-100">
//                       <Filters
//                         categories={categories}
//                         cities={[...new Set(allDeals.map(deal => deal.city).filter(city => city))]}
//                         onFilterChange={handleFilterChange}
//                         onClearFilters={handleClearFilters}
//                         selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-9">
//               {keyword || category ? (
//               <div className="alert alert-info text-center">
//                  <p>Your search results for {keyword && `"${keyword}"`} {category && `in category "${category}"`}:</p>
//                </div>
//             ) : (
//                 <div className="alert alert-info text-center">
//                   <p>Showing all Deals:</p>
//                </div>
//               )}

 

//           <div className="sticky-below-nav sticky-sort-dropdown">
//         <div className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
//           <h5>Deal Listings</h5>
//           <div className="d-flex align-items-center">
//             <div className="mb-2 mb-sm-0 me-sm-2">
//               <select
//                 className="form-select form-select-sm"
//                 onChange={handleFilterChange}
//                 value={sort}
//               >
//                 <option value="">Sort by</option>
//                 <option value="asc">A-Z (asc order)</option>
//                 <option value="desc">Z-A (desc order)</option>
//                 <option value="distance_asc">Location (nearest to farthest)</option>
//                 <option value="distance_desc">Location (farthest to nearest)</option>
//                 <option value="highest_discount">Highest Discount</option>
//                 <option value="lowest_discount">Lowest Discount</option>
//               </select>
//             </div>
//           </div>
//         </div>
//       </div>

//         {isLoading && visibleCount === itemsPerPage ? (
//         <Spinner />
//     ) : visibleDeals.length > 0 ? (
//         <>
//         <div className="row">
//             {visibleDeals.map(deal => (
//             <DealListingCard
//                 key={deal.id}
//                 deal={deal}
//                 isSortedByDistance={isSortedByDistance}
//             />
//             ))}
//         </div>
//         <LoadMore isLoading={isLoading} hasMore={hasMore} onLoadMore={handleLoadMore} />
//         </>
//     ) : (
//         <div className="alert alert-info text-center">
//         <p>Sorry, there are no deals to show at the moment.</p>
//         </div>
//     )}
//     </div>
//   </div>
// </div>
//   </section>
// </div>
// );
// };

// export default DealsList;




