import React, { useEffect, useRef, useState } from 'react';

const Map = ({ latitude, longitude }) => {
    const mapRef = useRef(null);
    const [apiKey, setApiKey] = useState(null);

    useEffect(() => {
        // Fetch the API key from the backend
        const fetchApiKey = async () => {
            try {
                const response = await fetch('/api/google-api-key/');
                const data = await response.json();
                setApiKey(data.google_api_key);
            } catch (error) {
                console.error('Error fetching API key:', error);
            }
        };

        fetchApiKey();
    }, []);

    useEffect(() => {
        if (apiKey) {
            // Function to initialize the map
            const initializeMap = () => {
                if (window.google && mapRef.current) {
                    const map = new window.google.maps.Map(mapRef.current, {
                        center: { lat: latitude, lng: longitude },
                        zoom: 15,
                    });

                    new window.google.maps.Marker({
                        map,
                        position: { lat: latitude, lng: longitude },
                    });
                }
            };

            // Function to load the Google Maps script
            const loadGoogleMapsScript = () => {
                const script = document.createElement('script');
                script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
                script.async = true;
                script.defer = true;
                script.onload = () => console.log("Google Maps script loaded successfully.");
                script.onerror = () => console.error("Error loading Google Maps script.");
                document.head.appendChild(script);

                // Attach the initializeMap function to the global window object
                window.initMap = initializeMap;
            };

            if (!window.google) {
                loadGoogleMapsScript();
            } else {
                initializeMap();
            }
        }
    }, [apiKey, latitude, longitude]);

    return <div ref={mapRef} style={{ width: '100%', height: '300px' }}></div>;
};

export default Map;