import React, { useState } from 'react';

const SharePopup = ({ link, onClose }) => {
    const [copySuccess, setCopySuccess] = useState('');

    const handleCopyLink = () => {
        navigator.clipboard.writeText(link).then(() => {
            setCopySuccess('Link copied to clipboard!');
            setTimeout(() => setCopySuccess(''), 2000); // Clear the message after 2 seconds
        }).catch(err => {
            setCopySuccess('Failed to copy link.');
            // console.error('Could not copy text: ', err);
        });
    };

    const encodedLink = encodeURIComponent(link);
    const socialPlatforms = [
        {
            name: 'Facebook',
            url: `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`,
            icon: 'fa fa-facebook',
            color: '#3b5998',
        },
        {
            name: 'LinkedIn',
            url: `https://www.linkedin.com/shareArticle?url=${encodedLink}`,
            icon: 'fa fa-linkedin',
            color: '#0077b5',
        },
        {
            name: 'X',
            url: `https://twitter.com/intent/tweet?url=${encodedLink}`,
            icon: 'fa fa-twitter',
            color: '#1DA1F2',
        },
        {
            name: 'WhatsApp',
            url: `https://wa.me/?text=${encodedLink}`,
            icon: 'fa fa-whatsapp',
            color: '#25D366',
        },
        {
            name: 'Instagram',
            url: '#',
            icon: 'fa fa-instagram',
            color: '#E4405F',
            customAction: () => alert('Instagram sharing works best through stories. Copy the link and share it in your Instagram story or bio!')
        },
    ];

    return (
        <div className="modal fade show" tabIndex="-1" role="dialog" style={{ display: 'block' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Share This Listing</h5>
                        <button type="button" className="close" onClick={onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input type="text" className="form-control" value={link} readOnly />
                        <button className="btn btn-primary mt-2" onClick={handleCopyLink}>Copy Link</button>
                        {copySuccess && <p className="text-success mt-2">{copySuccess}</p>}
                        <hr />
                        <div className="social-share-buttons">
                            <p>Share on:</p>
                            <div className="d-flex justify-content-around">
                                {socialPlatforms.map(platform => (
                                    <a
                                        key={platform.name}
                                        href={platform.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="btn"
                                        style={{ backgroundColor: platform.color, color: '#fff', marginRight: '10px', width: '100%' }}
                                        onClick={(e) => {
                                            if (platform.customAction) {
                                                e.preventDefault();
                                                platform.customAction();
                                            }
                                        }}
                                    >
                                        <i className={platform.icon}></i>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SharePopup;