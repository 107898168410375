import React, { useState, useEffect } from 'react';
import { getCookie } from '../../utils/csrf';
import LoginPopup from '../common/LoginPopup';

const LikeButton = ({ contentType, contentId, initialLikes, userHasLiked }) => {
    const [likes, setLikes] = useState(initialLikes);
    const [liked, setLiked] = useState(userHasLiked);
    const [showPopup, setShowPopup] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const fetchAuthStatus = async () => {
            try {
                const response = await fetch('/api/auth-status/', {
                    credentials: 'include',
                });
                const data = await response.json();
                setIsAuthenticated(data.isAuthenticated);
            } catch (error) {
                // console.error('Error checking auth status:', error);
            }
        };
        fetchAuthStatus();
    }, []);

    const handleLike = async () => {
        if (!isAuthenticated) {
            setShowPopup(true);
            return;
        }

        try {
            const response = await fetch(`/api/toggle-like/${contentType}/${contentId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            });

            if (response.ok) {
                const data = await response.json();
                setLikes(data.like_count);
                setLiked(data.liked);  // Set the liked state based on the backend response
            } else {
                // console.error('Error toggling like:', response.statusText);
            }
        } catch (error) {
            // console.error('Error toggling like:', error);
        }
    };

    const handleConfirmLogin = () => {
        window.location.href = `/api/login/?next=${window.location.pathname}`;
    };

    return (
        <>
            <div className={`meta-wp ${liked ? 'liked-animation' : ''}`}>
                <i className={`fa ${liked ? 'fa-heart' : 'fa-heart-o'}`} onClick={handleLike}></i> {likes} Likes
            </div>
            {showPopup && (
                <LoginPopup 
                    message="You need to be logged in to like this content."
                    onConfirm={handleConfirmLogin}
                    onCancel={() => setShowPopup(false)}
                />
            )}
        </>
    );
};

export default LikeButton;