import React, { useEffect, useState } from 'react';
import StarRating from './StarRating'; // Assuming you have a StarRating component

const ReviewStats = ({ contentType, contentId }) => {
    const [reviewStats, setReviewStats] = useState({
        reviewCount: 0,
        averageRating: 5.0, // Default to 5.0
    });

    useEffect(() => {
        const fetchReviewStats = async () => {
            try {
                const response = await fetch(`/api/review-count/${contentType}/${contentId}/`);
                if (response.ok) {
                    const data = await response.json();
                    setReviewStats({
                        reviewCount: data.review_count,
                        averageRating: data.average_rating,
                    });
                } else {
                    // console.error('Failed to fetch review stats:', response.statusText);
                }
            } catch (error) {
                // console.error('Error fetching review stats:', error);
            }
        };

        fetchReviewStats();
    }, [contentType, contentId]);

    return (
        <div className="review-stats">
            <StarRating rating={reviewStats.averageRating} /> {/* Display average rating */}
            <span>({reviewStats.reviewCount} reviews)</span>
        </div>
    );
};

export default ReviewStats;