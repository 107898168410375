import React from 'react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <section className="listing-header parallex-bg">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Terms of Service</h2>
            <p>Welcome to Desi Firms. We, Desi Firms, provide this website, including all information, tools, and services available from this site to you, the user, subject to your acceptance of all terms, conditions, policies, and notices stated here.</p>
            <p>By using our site and/or our services, you agree to these Terms of Service. These Terms apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/or contributors of content.</p>
            <p>Please read these Terms of Service carefully before using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.</p>
            <p>Any new features or tools added to the current site shall also be subject to the Terms of Service. We reserve the right to update, change, or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes.</p>
            <p>Desi Firms is an independent platform that provides a listing and discovery service for various businesses, events, deals, and more. We facilitate connections but do not directly process payments for any transactions. All transactions are directly between the user and the vendor.</p>

            <h3>Section 1 - Online Platform Terms</h3>
            <p>By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site.</p>
            <p>You may not use our platform for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).</p>
            <p>You must not transmit any worms or viruses or any code of a destructive nature.</p>
            <p>A breach or violation of any of the Terms will result in an immediate termination of your access to our platform.</p>

            <h3>Section 2 - General Conditions</h3>
            <p>We reserve the right to refuse service to anyone for any reason at any time.</p>
            <p>You understand that your content (not including credit card information) may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices.</p>
            <p>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us.</p>
            <p>All refund and return policies are set by individual vendors. We encourage you to inquire about these policies before making a purchase. Desi Firms is not responsible for any payment-related issues with vendors.</p>
            <p>The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms.</p>

            <h3>Section 3 - Data Collection and Privacy</h3>
            <p>At Desi Firms, we are committed to protecting your privacy. By using our platform, you agree to our collection and use of personal data as outlined in our <Link to="/privacy-policy">Privacy Policy</Link>.</p>
            <p>We collect the following types of personal data:</p>
            <ul>
              <li><strong>Registration Data:</strong> Includes your name, email address, IP address, and device information. This data helps us verify your identity and manage your account.</li>
              <li><strong>Transaction Data:</strong> Information related to payments and subscriptions, including payment method IDs and transaction history. This data is used for billing and subscription management.</li>
              <li><strong>Interaction Data:</strong> Data on how you interact with our platform, including searches, clicks, and content uploads. This data helps us personalize your experience and improve our services.</li>
              <li><strong>Location Data:</strong> Approximate location derived from your IP address or other means to personalize content and improve your platform experience.</li>
            </ul>
            <p>All data is collected and used in accordance with our <Link to="/privacy-policy">Privacy Policy</Link>, which details how we handle your information and your rights regarding your data.</p>

            <h3>Section 4 - Intellectual Property</h3>
            <p>All content on Desi Firms, including text, images, graphics, and logos, is the intellectual property of Desi Firms or its licensors. You may not reproduce, distribute, or otherwise use any content without our express written consent.</p>

            <h3>Section 5 - Limitation of Liability</h3>
            <p>Desi Firms is not liable for any damages resulting from the use or inability to use our platform, including but not limited to direct, indirect, incidental, punitive, and consequential damages. Your use of our platform is at your own risk.</p>

            <h3>Section 6 - Governing Law</h3>
            <p>These Terms of Service are governed by and construed in accordance with the laws of Australia. Any disputes arising from these Terms will be resolved in the courts of Australia.</p>

            <h3>Section 7 - Pricing Adjustments</h3>
            <p>Desi Firms reserves the right to adjust pricing for subscription plans and other fees at any time without prior notice. We recommend that you periodically review our pricing page to stay informed of any changes. Continued use of the platform after any pricing changes indicates your acceptance of the new rates.</p>

            <h3>Section 8 - Changes to Terms of Service</h3>
            <p>We may update these Terms of Service from time to time. Any changes will be posted on this page with an updated date. Your continued use of our platform following any changes indicates your acceptance of the new terms.</p>

            <h3>Section 9 - Contact Information</h3>
            <p>If you have any questions about these Terms of Service, please contact us at <a href="mailto:desifirms@gmail.com">desifirms@gmail.com</a>.</p>

            <p>By using Desi Firms, you confirm that you have read, understood, and agree to be bound by these Terms of Service.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsOfService;