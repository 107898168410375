import React from 'react';


const HowItWorks = () => {
  const pageTitle = "How it Works"; // You can set this dynamically if needed
  const siteTitle = "Desi Firms"; // You can set this dynamically if needed

  return (
    <div data-new-title={`${pageTitle} | ${siteTitle}`}>
      <section id="banner" className="parallex-bg section-padding">
        <div className="container">
          <div className="white-text text-center div_zindex">
            <h1>How it Works</h1>
          </div>
        </div>
        <div className="dark-overlay"></div>
      </section>

      <section id="inner_pages">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="how_it_work_info">
                <span className="step_n">01</span>
                <h3>Register an Account</h3>
                <p>Sign up by filling out a simple registration form with your details. Once you submit, an activation code will be sent to your email.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="how_it_work_img">
                <img src={`${process.env.PUBLIC_URL}/static/images/hw1.png`} alt="Register" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="how_it_work_info">
                <span className="step_n">02</span>
                <h3>Activate Your Account</h3>
                <p>Check your email inbox and click on the activation link we sent you to verify your account and complete the registration process.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="how_it_work_img">
                <img src={`${process.env.PUBLIC_URL}/static/images/hw2.png`} alt="Activate" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="how_it_work_info">
                <span className="step_n">03</span>
                <h3>Choose a Plan</h3>
                <p>After activating your account, choose a subscription plan that best suits your needs. We offer multiple plans to fit every budget and requirement.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="how_it_work_img">
                <img src={`${process.env.PUBLIC_URL}/static/images/hw4.png`} alt="Choose Plan" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="how_it_work_info">
                <span className="step_n">04</span>
                <h3>Add Your Listings, Deals, or Events</h3>
                <p>Once your plan is activated, you can start adding your business listings, deals, or events. Make sure to provide as much detail as possible to attract potential customers.</p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="how_it_work_img">
                <img src={`${process.env.PUBLIC_URL}/static/images/hw3.png`} alt="Add Listings" />
              </div>
            </div>
          </div>
          <div className="sub_heading">
            <p>Don't wait any longer to get started! Register today and start listing your business with Desi Firms.</p>
            <a href="/api/register/" className="btn">Start Now!</a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HowItWorks;