import React from 'react';
import CombinedList from '../components/CombinedList';



const TotalListings = () => {
  return (
    <div>
    
      <CombinedList />
    </div>
  );
};

export default TotalListings;