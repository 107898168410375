import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import Link

const PopularCities = () => {
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const fetchCities = async () => {
      const response = await fetch('/api/cities/');
      const data = await response.json();
      setCities(data);
    };

    fetchCities();
  }, []);

  return (
    <section id="popular_cities" className="section-padding">
      <div className="container">
        <div className="section-header text-center">
          <h2>Explore Australian Cities for Business & Events</h2>
          <p>Explore Australia's business hubs and event hotspots. From Melbourne to Sydney, discover unique opportunities in each city. Find local enterprises or plan your next event in these thriving urban centers.</p>
        </div>
        <div className="row">
          {cities.map((city) => (
            <div className="col-sm-6 col-md-3" key={city.id}>
              <div className="cities_list" style={{ background: `url(${city.image_url}) no-repeat center`, backgroundSize: 'cover' }}>
                <div className="city_listings_info">
                  <h4>{city.name}</h4>
                  <div className="listing_number"><span>{city.published_listings} Listings</span></div>
                </div>
                <Link to={`/total-list/${city.id}?id_type=city`} className="overlay_link" aria-label={`View listings for ${city.name}`}></Link> 
              </div>
            </div>
          ))}
        </div>
        <div className="text-center">
        <Link to="/all-listings" className="btn">See All Listings</Link>
        </div>
      </div>
    </section>
  );
};

export default PopularCities;