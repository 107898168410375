import React, { useEffect, useState } from 'react';
import { Star } from 'lucide-react';

const ReviewList = ({ contentType, contentId }) => {
    const [reviews, setReviews] = useState([]);
    const [showAllReviews, setShowAllReviews] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await fetch(`/api/reviews-list/${contentType}/${contentId}/`);
                if (response.ok) {
                    const data = await response.json();
                    setReviews(data);
                } else {
                    // console.error('Failed to fetch reviews:', response.statusText);
                }
            } catch (error) {
                // console.error('Error fetching reviews:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReviews();
    }, [contentType, contentId]);

    const visibleReviews = showAllReviews ? reviews : reviews.slice(0, 5);

    return (
        <div className="detail-wp">
            <div className="detail-heading">
                <h3>Reviews</h3>
            </div>

            {loading ? (
                <p>Loading reviews...</p>
            ) : (
                <>
                    {visibleReviews.map((review, index) => (
                        <div key={index} className="review-item">
                            <div className="review-header">
                                <div className="review-rating">
                                    {[...Array(5)].map((_, i) => (
                                        <Star
                                            key={i}
                                            fill={i < review.rating ? "#FFD700" : "none"}
                                            stroke={i < review.rating ? "#FFD700" : "#000000"}
                                            size={20}
                                        />
                                    ))}
                                </div>
                                <span>{review.user}</span>
                            </div>
                            <div className="review-body">
                                <p>{review.comment}</p>
                                <small>{new Date(review.created_at).toLocaleDateString()}</small>
                            </div>
                        </div>
                    ))}
                    {reviews.length > 5 && (
                        <button 
                            className="btn btn-block btn-sm mt-2"
                            onClick={() => setShowAllReviews(!showAllReviews)}
                        >
                            {showAllReviews ? 'Show Less ▲' : 'Show More ▼'}
                        </button>
                    )}
                </>
            )}
        </div>
    );
};

export default ReviewList;