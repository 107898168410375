import React, { useState, useEffect } from 'react';
import Search from '../components/common/Search';
import CategoryCards from '../components/homepage/CategoryCards';
import CategorySlider from '../components/homepage/CategorySlider';
import PopularCities from '../components/homepage/PopularCities';
import FeaturedBusiness from '../components/homepage/FeaturedBusiness';
import FeaturedClassifiedSlider from '../components/classifieds/FeaturedClassifiedSlider';
// import styled from 'styled-components';

// const AdSection = styled.div`
//   background-color: #f1f1f1; /* Placeholder color for ads */
//   text-align: center;
//   padding: 10px 0;
//   margin-bottom: 20px;
//   font-size: 14px;
//   color: #333;
// `;

const Home = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response = await fetch('/api/categories/');
      const data = await response.json();
      setCategories(data);
    };

    fetchCategories();
  }, []);

  return (
    <div data-new-title="Home Page | Desi Firms">
      {/* Placeholder for ads */}
      {/* <AdSection>
        <p>Future Ad Space: Location-based ads or other promotions can go here</p>
      </AdSection> */}

      <section id="banner" className="parallex-bg section-padding" style={{ paddingTop: '20px' }}>
        <div className="container">
          <div className="intro_text white-text div_zindex">
            <img src={`${process.env.PUBLIC_URL}/static/images/df_logo_big_white.svg`} alt="" />
            <h3 style={{ fontFamily: 'Ethnocentric' }}>Find everything desi - Classifieds, businesses, events, deals & more!</h3>
            <Search categories={categories} redirectOnSearch={true} />
            <CategoryCards />
          </div>
        </div>
      </section>

      <CategorySlider />
      <FeaturedClassifiedSlider />
 
    
      <div id="featured_business_container">
        <FeaturedBusiness />
      </div>
      <PopularCities />
    </div>
  );
};

export default Home;