import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { getCookie } from '../../utils/csrf';

const Navbar = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const response = await fetch('/api/auth-status/', {
          credentials: 'include'
        });
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
      } catch (error) {
        // console.error('Error checking auth status:', error);
      }
    };

    checkAuthStatus();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('/api/logout/', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie('csrftoken'),
        },
      });
      if (response.ok) {
        setIsAuthenticated(false);
        window.location.href = '/';
      } else {
        // console.error('Logout failed');
      }
    } catch (error) {
      // console.error('Error during logout:', error);
    }
  };

  return (
    <header id="header">
      {/* <nav className="navbar navbar-expand-lg fixed-top" id="header_nav"> */}
      <nav className="navbar navbar-expand-lg bg-black" id="header_nav">
        <div className="container-fluid">
          <div className="row header_row">
            <div className="col-md-3 col-sm-12 col-xs-12">
              <div className="navbar-header">
                <div className="logo">
                  <Link to="/">
                    <img src={`${process.env.PUBLIC_URL}/static/images/df_logo.svg`} alt="Desi Firms Logo" />
                  </Link>
                </div>
              </div>
              <button 
                id="menu_slide" 
                onClick={toggleMobileMenu} 
                className="navbar-toggler" 
                type="button" 
                aria-expanded={isMobileMenuOpen}
                aria-label="Toggle navigation"
              >
                <i className="fa fa-bars"></i>
              </button>
            </div>
            <div className="col-md-9 col-sm-12 col-xs-12">
              <div className={`collapse navbar-collapse ${isMobileMenuOpen ? 'show' : ''}`} id="navigation">
                <ul className="nav navbar-nav mr-auto">
                  <li>
                    <NavLink exact to="/" activeClassName="active" className="nav-link" onClick={toggleMobileMenu}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/all-listings" activeClassName="active" className="nav-link" onClick={toggleMobileMenu}>Listing</NavLink>
                  </li>
                  <li>
                    <NavLink to="/deals-list" activeClassName="active" className="nav-link" onClick={toggleMobileMenu}>Deals</NavLink>
                  </li>
                  <li>
                    <NavLink to="/events-list" activeClassName="active" className="nav-link" onClick={toggleMobileMenu}>Events</NavLink>
                  </li>
                  <li>
                    <NavLink to="/classifieds" activeClassName="active" className="nav-link" onClick={toggleMobileMenu}>Classifieds</NavLink>
                  </li> 
                  <li>
                    <NavLink to="/how-it-works" activeClassName="active" className="nav-link" onClick={toggleMobileMenu}>How It Works</NavLink>
                  </li>
                  {isMobile ? (
                    <>
                  {isAuthenticated ? (
                    <>
                      <li>
                        <a href="/api/dashboard/" className="nav-link" onClick={toggleMobileMenu}>Dashboard</a>
                      </li>
                      <li>
                      <a href="#" onClick={handleLogout} className="nav-link">Logout</a>
                      </li>
                    </>
                    ) : (
                      <li>
                        <a href="/api/login/" className="nav-link" onClick={toggleMobileMenu}>Sign In</a>
                      </li>
                    )}
                  </>
                  ) : (
                    <li className="menu-item-has-children">
                      <a href="#" className="nav-link user-panel-toggle">
                        User Panel <span className="arrow"></span>
                      </a>
                      <ul className="sub-menu">
                        {isAuthenticated ? (
                          <>
                            <li><a href="/api/dashboard/" className="nav-link">Dashboard</a></li>
                            <li><a href="/api/dashboard/reviews/" className="nav-link">Reviews</a></li>
                            <li><a href="/api/dashboard/interactions/" className="nav-link">Interactions</a></li>
                            <li><a href="/api/add-listing/" className="nav-link">Add Listing</a></li>
                            <li><a href="/api/add_deal/" className="nav-link">Add Deals</a></li>
                            <li><a href="/api/add_event/" className="nav-link">Add Event</a></li>
                            <li><a href="/api/profile/" className="nav-link">My Profile</a></li>
                            <li>
                              <a href="#" onClick={handleLogout} className="nav-link">Logout</a>
                            </li>
                          </>
                        ) : (
                          <>
                            <li>
                              <a href="/api/login/" className="nav-link">Sign In</a>
                            </li>
                            <li>
                              <a href="/api/register/" className="nav-link">Sign Up</a>
                            </li>
                          </>
                        )}
                      </ul>
                    </li>
                  )}
                </ul>
                <div className="submit_listing">
                  <a href="/api/add-listing/" className="btn outline-btn">
                    <i className="fa fa-plus-circle"></i> Submit Listing
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;