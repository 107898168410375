
import React from 'react';
import { MapPin, Calendar, Tag, Globe } from 'lucide-react';
import StarRating from '../ratings/StarRating';  // Import the StarRating component

const DealListingCard = ({ deal, isSortedByDistance, showTypeLabel }) => {
  const calculateDiscountPercentage = (price, discountedPrice) => {
    if (price && discountedPrice) {
      return ((price - discountedPrice) / price) * 100;
    }
    return 0;
  };

  const discountPercentage = calculateDiscountPercentage(deal.price, deal.discounted_price);

  const isUpcoming = new Date(deal.start_date) > new Date();
  const isExpired = new Date(deal.end_date) < new Date();
  const isWithinWeekAfterExpiry = isExpired && new Date(deal.end_date) >= new Date(new Date().setDate(new Date().getDate() - 7));

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className={`card h-100 shadow-sm ${isUpcoming ? 'bg-light' : ''}`}>
        <div className="position-relative">
          <img 
            src={deal.image || '/assets/images/default_deal_image.jpg'} 
            className="card-img-top" 
            alt={deal.title}
            style={{ height: '200px', objectFit: 'cover' }}
          />
          {discountPercentage > 0 && (
            <span className="position-absolute top-0 end-0 m-2 badge bg-danger">
              {Math.round(discountPercentage)}% OFF
            </span>
          )}
          {deal.status === 'hot' && (
            <span className="position-absolute top-0 start-0 m-2 badge bg-danger text-white">
              HOT
            </span>
          )}
          {deal.status === 'new' && (
            <span className="position-absolute top-0 start-0 m-2 badge bg-success text-white">
              NEW
            </span>
          )}
          {isSortedByDistance && deal.distance !== undefined && (
            <span className="position-absolute bottom-0 start-0 m-2 badge bg-info">
              {typeof deal.distance === 'number' ? `${deal.distance.toFixed(2)} km away` : deal.distance}
            </span>
          )}
          {isUpcoming && (
            <span className="position-absolute bottom-0 end-0 m-2 badge bg-success">
              Upcoming
            </span>
          )}
          {isExpired && !isWithinWeekAfterExpiry && (
            <span className="position-absolute bottom-0 end-0 m-2 badge bg-secondary">
              Expired
            </span>
          )}
        </div>
        <div className="card-body">
          {showTypeLabel && <span className="badge bg-warning mb-2">Deal</span>}
          <h5 className="card-title mb-1">{deal.title || 'Unnamed Deal'}</h5>
          <p className="badge bg-primary small mb-2">{deal.business_name || 'Business not available'}</p>
          <div className="d-flex justify-content-between mb-2">
            <span className="badge bg-secondary">{deal.city_name || 'City not available'}</span>
            <span className="badge bg-secondary">{deal.category_name || 'Category not available'}</span>
          </div>
          <p className="card-text text-muted small">
            {deal.description?.substring(0, 100) || 'No description available'}
          </p>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <StarRating rating={Math.round(deal.average_rating || 0)} />
            <span className="text-muted small">
              {deal.average_rating ? deal.average_rating.toFixed(1) : 0} ({deal.reviews_with_rating || 0} reviews)
            </span>
          </div>
          <ul className="list-unstyled mb-2">
            <li className="mb-1">
              <Tag size={16} className="me-2" />
              <span className="text-decoration-line-through">${deal.price}</span> 
              {' '}
              <span className="text-danger fw-bold">${deal.discounted_price}</span>
            </li>
            {deal.business_address && (
              <li className="mb-1">
                <MapPin size={16} className="me-2" />
                {deal.business_address}
              </li>
            )}
            {deal.deal_website_link && (
              <li className="mb-1">
                <Globe size={16} className="me-2" />
                <a href={deal.deal_website_link} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                  {deal.deal_website_link.replace(/^https?:\/\//, '')}
                </a>
              </li>
            )}
          </ul>
          <p className="card-text fw-bold text-primary mb-2">
            <Calendar size={16} className="me-2" />
            {isUpcoming ? `Starts on: ${new Date(deal.start_date).toLocaleDateString()}` : `Valid Until: ${new Date(deal.end_date).toLocaleDateString()}`}
          </p>
        </div>
        <div className="card-footer">
          <a href={`/deal/${deal.slug}`} className="btn btn-primary btn-sm">View Deal</a>
        </div>
      </div>
    </div>
  );
};

export default DealListingCard;
// import React from 'react';
// import { MapPin, Calendar, Tag, Globe } from 'lucide-react';

// const DealListingCard = ({ deal, isSortedByDistance, showTypeLabel }) => {
//   const calculateDiscountPercentage = (price, discountedPrice) => {
//     if (price && discountedPrice) {
//       return ((price - discountedPrice) / price) * 100;
//     }
//     return 0;
//   };

//   const discountPercentage = calculateDiscountPercentage(deal.price, deal.discounted_price);

//   const isUpcoming = new Date(deal.start_date) > new Date();
//   const isExpired = new Date(deal.end_date) < new Date();
//   const isWithinWeekAfterExpiry = isExpired && new Date(deal.end_date) >= new Date(new Date().setDate(new Date().getDate() - 7));
//   // console.log("Rendering DealCard with data: ", deal);

//   return (
//     <div className="col-lg-4 col-md-6 mb-4">
//       <div className={`card h-100 shadow-sm ${isUpcoming ? 'bg-light' : ''}`}>
//         <div className="position-relative">
//           <img 
//             src={deal.image || '/assets/images/default_deal_image.jpg'} 
//             className="card-img-top" 
//             alt={deal.title}
//             style={{ height: '200px', objectFit: 'cover' }}
//           />
//           {discountPercentage > 0 && (
//             <span className="position-absolute top-0 end-0 m-2 badge bg-danger">
//               {Math.round(discountPercentage)}% OFF
//             </span>
//           )}
//           {deal.status === 'hot' && (
//             <span className="position-absolute top-0 start-0 m-2 badge bg-danger text-white">
//               HOT
//             </span>
//           )}
//           {deal.status === 'new' && (
//             <span className="position-absolute top-0 start-0 m-2 badge bg-success text-white">
//               NEW
//             </span>
//           )}
//           {isSortedByDistance && deal.distance !== undefined && (
//             <span className="position-absolute bottom-0 start-0 m-2 badge bg-info">
//               {typeof deal.distance === 'number' ? `${deal.distance.toFixed(2)} km away` : deal.distance}
//             </span>
//           )}
//           {isUpcoming && (
//             <span className="position-absolute bottom-0 end-0 m-2 badge bg-success">
//               Upcoming
//             </span>
//           )}
//           {isExpired && !isWithinWeekAfterExpiry && (
//             <span className="position-absolute bottom-0 end-0 m-2 badge bg-secondary">
//               Expired
//             </span>
//           )}
//         </div>
//         <div className="card-body">
//           {showTypeLabel && <span className="badge bg-warning mb-2">Deal</span>}  {/* Type label */}
//           <h5 className="card-title mb-1">{deal.title || 'Unnamed Deal'}</h5>
//           <p className="badge bg-primary small mb-2">{deal.business_name || 'Business not available'}</p>
//           <div className="d-flex justify-content-between mb-2">
//             <span className="badge bg-secondary">{deal.city_name || 'City not available'}</span>
//             <span className="badge bg-secondary">{deal.category_name || 'Category not available'}</span>
//           </div>
//           <p className="card-text text-muted small">
//             {deal.description?.substring(0, 100) || 'No description available'}
//           </p>
//           <ul className="list-unstyled mb-2">
//             <li className="mb-1">
//               <Tag size={16} className="me-2" />
//               <span className="text-decoration-line-through">${deal.price}</span> 
//               {' '}
//               <span className="text-danger fw-bold">${deal.discounted_price}</span>
//             </li>
//             {deal.business?.address && (
//               <li className="mb-1">
//                 <MapPin size={16} className="me-2" />
//                 {deal.business.address}
//               </li>
//             )}
//             {deal.deal_website_link && (
//               <li className="mb-1">
//                 <Globe size={16} className="me-2" />
//                 <a href={deal.deal_website_link} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
//                   {deal.deal_website_link.replace(/^https?:\/\//, '')}
//                 </a>
//               </li>
//             )}
//           </ul>
//           <p className="card-text fw-bold text-primary mb-2">
//             <Calendar size={16} className="me-2" />
//             {isUpcoming ? `Starts on: ${new Date(deal.start_date).toLocaleDateString()}` : `Valid Until: ${new Date(deal.end_date).toLocaleDateString()}`}
//           </p>
//         </div>
//         <div className="card-footer">
//           <a href={`/deal/${deal.slug}`} className="btn btn-primary btn-sm">View Deal</a>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DealListingCard;