import React from 'react';
import { MapPin, Clock, Phone, Globe, Star } from 'lucide-react';
import { Link } from 'react-router-dom';
import StarRating from '../ratings/StarRating'; 

const BusinessListingCard = ({ business, isSortedByDistance, showTypeLabel }) => {
  const isOpen = () => {
    if (!business.business_hours || !Array.isArray(business.business_hours) || !business.timezone_selection) {
      return false;
    }

    const now = new Date();
    const businessTz = business.timezone_selection;
    const options = { timeZone: businessTz, hour12: false, hour: '2-digit', minute: '2-digit' };
    const localTime = now.toLocaleString('en-US', options);
    const [localHours, localMinutes] = localTime.split(':').map(Number);
    
    const day = now.toLocaleString('en-US', { timeZone: businessTz, weekday: 'long' });
    const todayHours = business.business_hours.find(h => h.day_of_week === day);
    
    if (!todayHours || todayHours.is_closed) return false;

    const [openHours, openMinutes] = todayHours.opening_time.split(':').map(Number);
    const [closeHours, closeMinutes] = todayHours.closing_time.split(':').map(Number);

    const currentMinutes = localHours * 60 + localMinutes;
    const openingMinutes = openHours * 60 + openMinutes;
    const closingMinutes = closeHours * 60 + closeMinutes;

    return currentMinutes >= openingMinutes && currentMinutes < closingMinutes;
  };

  const convertTo12Hour = (time) => {
    if (!time) return '';
    const [hours, minutes] = time.split(':');
    const date = new Date(2000, 0, 1, parseInt(hours, 10), parseInt(minutes, 10));
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
  };

  const formatHours = (hours) => {
    if (!hours || !Array.isArray(hours)) {
      return 'Business hours not available';
    }
  
    const groupedHours = hours.reduce((acc, curr) => {
      const key = curr.is_closed ? 'Closed' : `${convertTo12Hour(curr.opening_time)} - ${convertTo12Hour(curr.closing_time)}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr.day_of_week);
      return acc;
    }, {});
  
    return Object.entries(groupedHours).map(([hours, days]) => {
      const daysString = days.length > 1 ? `${days[0]}-${days[days.length - 1]}` : days[0];
      return `${daysString}: ${hours}`;
    }).join(', ');
  };

  return (
    <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100 shadow-sm">
        <div className="position-relative">
          <img 
            src={business.cover_photo || '/assets/images/default_cover.jpg'} 
            className="card-img-top" 
            alt={business.title}
            style={{ height: '200px', objectFit: 'cover' }}
          />
          <div className="position-absolute top-0 start-0 m-2">
            <img 
              src={business.logo || '/assets/images/default_logo.png'} 
              alt={business.title}
              className="rounded-circle"
              style={{ width: '60px', height: '60px', border: '2px solid white' }}
            />
          </div>
          {isOpen() && (
            <span className="position-absolute top-0 end-0 m-2 badge bg-success">
              Open Now
            </span>
          )}
          {!isOpen() && (
            <span className="position-absolute top-0 end-0 m-2 badge bg-danger">
              Closed
            </span>
          )}
          {isSortedByDistance && business.distance !== undefined && (
            <span className="position-absolute bottom-0 start-0 m-2 badge bg-info">
              {typeof business.distance === 'number' ? `${business.distance.toFixed(2)} km away` : business.distance}
            </span>
          )}
        </div>
        <div className="card-body">
        {showTypeLabel && <span className="badge bg-warning mb-2">Business</span>}
          <h5 className="card-title mb-1">{business.title || 'Unnamed Business'}</h5>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <span className="badge bg-primary small">{business.city_name || 'City not available'}</span>
            <span className="badge bg-light text-dark">{business.category_name || 'Uncategorized'}</span>
          </div>
          <p className="card-text text-muted small mb-2">{business.tagline || business.description?.substring(0, 100) || 'No description available'}</p>
          <div className="d-flex justify-content-between align-items-center mb-3">
            <StarRating rating={Math.round(business.average_rating || 0)} />
            <span className="text-muted small">
              {business.average_rating ? business.average_rating.toFixed(1) : 0} ({business.review_count} reviews)
            </span>
          </div>
          <ul className="list-unstyled mb-3">
            <li className="mb-1"><MapPin size={16} className="me-2" />{business.address || 'Address not available'}</li>
            {business.phone_number && <li className="mb-1"><Phone size={16} className="me-2" />{business.phone_number}</li>}
            {business.website_url && (
              <li className="mb-1">
                <Globe size={16} className="me-2" />
                <a href={business.website_url} target="_blank" rel="noopener noreferrer" className="text-decoration-none">
                  {business.website_url.replace(/^https?:\/\//, '')}
                </a>
              </li>
            )}
          </ul>
          {business.user_profile?.pricing_plan?.name !== 'Basic Plan' && business.total_deals !== undefined && (
            <p className="mb-2">
              <span className="badge bg-primary">
                {business.total_deals} {business.total_deals === 1 ? 'Deal' : 'Deals'} Available
              </span>
            </p>
          )}
        </div>
        <div className="card-footer">
          <small className="text-muted d-block mb-1">
            <Clock size={16} className="me-1" /> 
            {formatHours(business.business_hours)}
          </small>
          {business.timezone_selection && (
            <small className="text-muted d-block">Time Zone: {business.timezone_selection}</small>
          )}
        </div>
        <Link to={`/business/${business.slug}`} className="btn btn-primary btn-sm">Visit Store</Link>
      </div>
    </div>
  );
};

export default BusinessListingCard;