import React, { useEffect, useState } from 'react';
import Spinner from '../common/Spinner';
import LikeButton from './LikeButton'; // Import LikeButton component
import BookmarkButton from './BookmarkButton'; // Import BookmarkButton component
import InterestedButton from './InterestedButton';

const ContentStats = ({ contentType, contentId }) => {
    const [stats, setStats] = useState({
        likes: 0,
        views: 0,
        bookmarks: 0,
        interested: 0, 
        userHasLiked: false,
        userHasBookmarked: false,
        userHasInterested: false,
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            try {
                // Trigger the backend to increase the view count and fetch the updated stats
                const response = await fetch(`/api/content-stats/${contentType}/${contentId}/`);
                
                if (response.ok) {
                    const data = await response.json();
                    setStats({
                        likes: data.likes,
                        views: data.views,
                        bookmarks: data.bookmarks,
                        interested: data.interested, 
                        userHasLiked: data.user_has_liked,
                        userHasBookmarked: data.user_has_bookmarked,
                        userHasInterested: data.user_has_interested,
                    });
                } else {
                    // console.error('Error fetching content stats:', response.statusText);
                }
            } catch (error) {
                // console.error('Error fetching content stats:', error);
            } finally {
                setLoading(false);
            }
        };

        if (contentId) {
            fetchStats();
        }
    }, [contentType, contentId]);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className="row">
            <div className="col-md-9">
                {/* Use LikeButton and pass the initial likes and userHasLiked */}
                <LikeButton contentType={contentType} contentId={contentId} initialLikes={stats.likes} userHasLiked={stats.userHasLiked} />

                 {/* Conditionally render the BookmarkButton for non-events */}
                 {contentType !== 'event' && (
                    <BookmarkButton contentType={contentType} contentId={contentId} initialBookmarks={stats.bookmarks} userHasBookmarked={stats.userHasBookmarked} />
                )}

                {/* Use InterestedButton only for events */}
                {contentType === 'event' && (
                    <InterestedButton contentType={contentType} contentId={contentId} initialInterested={stats.userHasInterested} initialInterestedCount={stats.interested} />
                )}

                {/* Display the view count statically */}
                <div className="meta-wp">
                    <i className="fa fa-eye"></i> {stats.views} Views
                </div>
            </div>
        </div>
    );
};

export default ContentStats;