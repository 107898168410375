import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { AlertCircle, User, Mail, Paperclip, Calendar, DollarSign,  Phone, MessageSquare, X } from 'lucide-react';
import { getCookie } from '../../utils/csrf'; 
import LoginPopup from './LoginPopup';

// Styled components
const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  width: 500px;
  max-width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalHeader = styled.div`
  background-color: #f8f8f8;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

const ModalBody = styled.div`
  padding: 20px;
`;

const SafetyTips = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const InputGroup = styled.div`
  position: relative;
  margin-bottom: 15px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
`;

const InputIcon = styled.span`
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
`;

const SendButton = styled.button`
  background-color: #009fe3;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  margin-top: 10px;

  &:hover {
    background-color: #ff4346;
  }
`;

const Checkbox = styled.input`
  margin-right: 10px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const SuccessMessage = styled.div`
  background-color: #d4edda;
  color: #155724;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  margin-top: 20px;
`;
const ConsentLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-top: 10px;
`;
const FileAttachmentSection = styled.div`
  margin-top: 15px;
  border: 1px dashed #ccc;
  padding: 10px;
  border-radius: 4px;
`;

const FileInput = styled.input`
  display: none;
`;

const FileLabel = styled.label`
  display: inline-block;
  padding: 10px 15px;
  background-color: #f0f0f0;
  color: #333;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const SelectedFile = styled.div`
  display: flex;
  align-items: center;
  background-color: #e9ecef;
  padding: 5px 10px;
  border-radius: 4px;
  margin-top: 10px;
`;

const FileName = styled.span`
  margin-left: 10px;
  flex-grow: 1;
`;

const RemoveFileButton = styled.button`
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
`;

const InquiryPopup = ({ onClose, onSendInquiry, initialData = {}, classifiedFields = [] , classifiedId}) => {
  const [formData, setFormData] = useState({
    name: initialData.name || '',
    email: initialData.email || '',
    phone: '',
    message: '',
    ...classifiedFields.reduce((acc, field) => ({ ...acc, [field.name]: '' }), {})
  });
  const [attachment, setAttachment] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);

  useEffect(() => {
    const fetchAuthStatus = async () => {
      try {
        const response = await fetch('/api/auth-status/', { credentials: 'include' });
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated);
      } catch (error) {
        console.error('Error checking auth status:', error);
      }
    };
    fetchAuthStatus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAttachment(file);
    }
  };

  const handleRemoveFile = () => {
    setAttachment(null);
  };

  const handleConsentChange = (e) => {
    setConsentGiven(e.target.checked);
  };

  const subscribeNewsletter = async (email) => {
    try {
      const response = await fetch('/api/subscribe/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: new URLSearchParams({ 'nl_email': email }),
      });
      if (response.ok) {
        console.log('Newsletter subscription successful');
      } else {
        console.error('Failed to subscribe to newsletter');
      }
    } catch (error) {
      console.error('Error subscribing to newsletter:', error);
    }
  };

  const handleSend = async () => {
    if (!isAuthenticated) {
      setShowLoginPopup(true);
      return;
    }
    if (!classifiedId) {
      console.error('Classified ID is undefined');
      // Handle error (e.g., show error message to user)
      return;
    }
  
    setIsSending(true);
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      formDataToSend.append(key, formData[key]);
    });
    if (attachment) {
      formDataToSend.append('attachment', attachment);
    }
  
    try {
      const response = await fetch(`/api/inquiry/classified/${classifiedId}/`, {
        method: 'POST',
        body: formDataToSend,
        headers: {
          'X-CSRFToken': getCookie('csrftoken'),
        },
      });
  
      if (response.ok) {
        if (consentGiven) {
          await subscribeNewsletter(formData.email);
        }
        setIsSuccess(true);
        setTimeout(() => { onClose(); }, 3000);
      } else {
        const errorData = await response.json();
        console.error('Error sending inquiry:', errorData);
        // Handle error (e.g., show error message to user)
      }
    } catch (error) {
      console.error('Error sending inquiry:', error);
      // Handle error (e.g., show error message to user)
    }
  
    setIsSending(false);
  };
  // Dynamic Field Rendering based on `classifiedFields`
  const renderField = (field) => {
    switch (field.field_type) {
      case 'text':
      case 'char':
        return (
          <InputGroup key={field.name}>
            <InputIcon><Mail size={18} /></InputIcon>
            <StyledInput
              type="text"
              name={field.name}
              placeholder={field.placeholder || 'Enter details'}
              value={formData[field.name]}
              onChange={handleInputChange}
            />
          </InputGroup>
        );
      case 'textarea':
        return (
          <StyledTextArea
            key={field.name}
            name={field.name}
            rows="5"
            placeholder={field.placeholder || 'Enter your message'}
            value={formData[field.name]}
            onChange={handleInputChange}
          />
        );
      case 'date':
        return (
          <InputGroup key={field.name}>
            <InputIcon><Calendar size={18} /></InputIcon>
            <StyledInput
              type="date"
              name={field.name}
              value={formData[field.name]}
              onChange={handleInputChange}
            />
          </InputGroup>
        );
      case 'number':
        return (
          <InputGroup key={field.name}>
            <InputIcon><DollarSign size={18} /></InputIcon>
            <StyledInput
              type="number"
              name={field.name}
              placeholder={field.placeholder || 'Enter number'}
              value={formData[field.name]}
              onChange={handleInputChange}
            />
          </InputGroup>
        );
      default:
        return null;
    }
  };

  if (isSending) {
    return (
      <Modal>
        <ModalContent>
          <SpinnerContainer>
            <Spinner />
            <p>Sending inquiry...</p>
          </SpinnerContainer>
        </ModalContent>
      </Modal>
    );
  }

  if (isSuccess) {
    return (
      <Modal>
        <ModalContent>
          <SuccessMessage>
            Inquiry sent successfully! We will get back to you soon.
            {consentGiven && <p>You've been subscribed to our newsletter.</p>}
          </SuccessMessage>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal>
      <ModalContent>
        <ModalHeader>
          <h2>Send Inquiry</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalBody>
          <SafetyTips>
            <AlertCircle size={16} />
            <span>Safety tip: Always verify the seller's information before making a purchase.</span>
          </SafetyTips>
          <InputGroup>
            <InputIcon><User size={18} /></InputIcon>
            <StyledInput
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </InputGroup>
          <InputGroup>
            <InputIcon><Mail size={18} /></InputIcon>
            <StyledInput
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </InputGroup>
          <InputGroup>
            <InputIcon><Phone size={18} /></InputIcon>
            <StyledInput
              type="tel"
              name="phone"
              placeholder="Your Phone Number"
              value={formData.phone}
              onChange={handleInputChange}
            />
          </InputGroup>
          <InputGroup>
            <InputIcon><MessageSquare size={18} /></InputIcon>
            <StyledTextArea
              name="message"
              rows="5"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleInputChange}
            />
          </InputGroup>

          <FileAttachmentSection>
            <FileLabel htmlFor="file-upload">
              <Paperclip size={18} /> Attach File
            </FileLabel>
            <FileInput
              id="file-upload"
              type="file"
              accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
              onChange={handleFileChange}
            />
            {attachment && (
              <SelectedFile>
                <Paperclip size={18} />
                <FileName>{attachment.name}</FileName>
                <RemoveFileButton onClick={handleRemoveFile}>
                  <X size={18} />
                </RemoveFileButton>
              </SelectedFile>
            )}
          </FileAttachmentSection>

          {/* Dynamic field rendering */}
          {classifiedFields.map(renderField)}

          <ConsentLabel>
            <Checkbox 
              type="checkbox" 
              id="consent" 
              checked={consentGiven}
              onChange={handleConsentChange}
            />
            I consent to receive newsletter and marketing material
          </ConsentLabel>
          <SendButton onClick={handleSend}>Send Inquiry</SendButton>
        </ModalBody>
      </ModalContent>
      {showLoginPopup && (
        <LoginPopup
          message="You need to be logged in to send an inquiry."
          onConfirm={() => window.location.href = `/api/login/?next=${window.location.pathname}`}
          onCancel={() => setShowLoginPopup(false)}
        />
      )}
    </Modal>
  );
};

export default InquiryPopup;