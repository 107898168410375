import React, { useState, useEffect,  useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import Search from '../common/Search';
import Filters from '../layout/Filters';
import { useUser } from '../../context/UserContext';
import ClassifiedCard from './ClassifiedCard';
import Spinner from '../common/Spinner';
import LoadMore from '../common/LoadMore';
import styled from 'styled-components';

const StickyFilterContainer = styled.div`
  position: sticky;
  top: 100px; /* Adjust this based on your navbar height */
  z-index: 10;
`;

const ScrollableContainer = styled.div`
  max-height: 200px;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 10px;

  /* Scrollbar styling */
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SearchInfo = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);

  .search-term {
    font-weight: 500;
    color: #0d6efd;
  }

  .clear-search {
    color: #dc3545;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    
    &:hover {
      background-color: #fff;
      text-decoration: underline;
    }
  }
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);

  .header-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;  /* Increased gap between title and count */

    h5 {
      margin: 0;
      font-size: 1.1rem;
      font-weight: 500;
      color: #2c3e50;  /* Darker text for better contrast */
    }

    .result-count {
      position: relative;
      color: #6c757d;
      font-size: 0.875rem;
      font-weight: normal;
      padding-left: 1.5rem;  /* Added padding for the separator */
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 1px;
        background-color: #dee2e6;  /* Light separator color */
      }
    }
  }

  .sort-section {
    select {
      min-width: 200px;
      border-color: #dee2e6;
      background-color: white;
      
      &:focus {
        border-color: #adb5bd;
        box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.25);
      }
    }
  }

  background: linear-gradient(to bottom, #ffffff, #f8f9fa);  /* Subtle gradient */
  border: 1px solid #e9ecef;  /* Light border */
`;
const ClearButton = styled.button`
    color: #0d6efd;
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    
    &:hover {
        color: #0a58ca;
    }
`;


const ClassifiedList = () => {
    const [allClassifieds, setAllClassifieds] = useState([]);
    const [filteredClassifieds, setFilteredClassifieds] = useState([]); // Add this state
    const [visibleClassifieds, setVisibleClassifieds] = useState([]);
    const [classifiedTypes, setClassifiedTypes] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        category: '',
        city: '',
        classifiedType: '',
        rating: []  // Keep rating as array if multiple ratings are allowed
    });
    const [sort, setSort] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const itemsPerPage = 12;
    const [visibleCount, setVisibleCount] = useState(itemsPerPage);
    const { categories, cities } = useUser();
    const [userLocation, setUserLocation] = useState(null);

    const keyword = searchParams.get('keyword') || '';
    const category = searchParams.get('category') || '';

    // Location init stays exactly the same
    useEffect(() => {
        const initLocation = () => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`),
                    () => setUserLocation('144.9631 -37.8136')
                );
            } else {
                setUserLocation('144.9631 -37.8136');
            }
        };
        initLocation();
    }, []);

    // Modified to only fetch on search/location changes
    const fetchClassifieds = useCallback(async () => {
        if (!userLocation) return;
    
        setIsLoading(true);
        try {
            const params = new URLSearchParams();
            params.append('location', userLocation);
            
            // Only include search and location params
            if (keyword) params.append('keyword', keyword);
            if (category) params.append('category', category);
    
            const response = await fetch(`/api/classifieds/?${params.toString()}`);
            const data = await response.json();
    
            if (data.classifieds && Array.isArray(data.classifieds)) {
                const classifieds = data.classifieds;
                setAllClassifieds(classifieds);
                setFilteredClassifieds(classifieds);
                setVisibleClassifieds(classifieds.slice(0, visibleCount));
                setHasMore(classifieds.length > visibleCount);
                
                if (data.classified_types) {
                    setClassifiedTypes(data.classified_types);
                    
                    // Move this logic to a separate useEffect to avoid the dependency
                    if (keyword) {
                        const matchingType = data.classified_types.find(type => 
                            type.name.toLowerCase() === keyword.toLowerCase()
                        );
                        if (matchingType) {
                            setClassifiedTypes(prev => {
                                // Store matching type for use in separate effect
                                return prev.map(type => ({
                                    ...type,
                                    isMatch: type.name === matchingType.name
                                }));
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.error('Error fetching classifieds:', error);
            setAllClassifieds([]);
            setFilteredClassifieds([]);
            setVisibleClassifieds([]);
            setHasMore(false);
        } finally {
            setIsLoading(false);
        }
    }, [userLocation, keyword, category, visibleCount]);
    
    // Add separate effect for handling matching type
    useEffect(() => {
        if (keyword && classifiedTypes.length) {
            const matchingType = classifiedTypes.find(type => type.isMatch);
            if (matchingType && !selectedFilters.classifiedType) {
                setSelectedFilters(prev => ({
                    ...prev,
                    classifiedType: matchingType.name
                }));
            }
        }
    }, [keyword, classifiedTypes, selectedFilters.classifiedType]);

    // Fetch only when location or search changes
    useEffect(() => {
        fetchClassifieds();
    }, [fetchClassifieds]);

    // Modified filtering effect to handle frontend filtering
    useEffect(() => {
        if (!allClassifieds.length) return;
    
        const filtered = [...allClassifieds].filter(classified => {
            let matches = true;
            
            if (selectedFilters.category) {
                matches = matches && classified.category_name === selectedFilters.category;
            }
            if (selectedFilters.city) {
                matches = matches && classified.city_name === selectedFilters.city;
            }
            if (selectedFilters.classifiedType) {
                matches = matches && classified.classified_type_name === selectedFilters.classifiedType;
            }
            if (keyword) {
                const lowKeyword = keyword.toLowerCase();
                matches = matches && (
                    (classified.title?.toLowerCase().includes(lowKeyword)) ||
                    (classified.description?.toLowerCase().includes(lowKeyword)) ||
                    (classified.classified_type_name?.toLowerCase().includes(lowKeyword))
                );
            }
            return matches;
        });
    

        const sorted = filtered.sort((a, b) => {
            const getPriority = (c) => c.is_featured ? 0 : c.is_urgent ? 1 : 2;
            const priorityDiff = getPriority(a) - getPriority(b);
            if (priorityDiff !== 0) return priorityDiff;

            switch(sort) {
                case 'location_asc':
                    return (a.distance || Infinity) - (b.distance || Infinity);
                case 'location_desc':
                    return (b.distance || -Infinity) - (a.distance || -Infinity);
                case 'date_asc':
                    return new Date(a.created_at) - new Date(b.created_at);
                case 'date_desc':
                    return new Date(b.created_at) - new Date(a.created_at);
                default:
                    return new Date(b.created_at) - new Date(a.created_at);
            }
        });

        setFilteredClassifieds(sorted);
        setVisibleClassifieds(sorted.slice(0, visibleCount));
        setHasMore(sorted.length > visibleCount);
    }, [allClassifieds, selectedFilters, sort, keyword, visibleCount]);

    // Modified to use filteredClassifieds
    const handleLoadMore = useCallback(() => {
        const newVisibleCount = visibleCount + itemsPerPage;
        setVisibleCount(newVisibleCount);
        setVisibleClassifieds(filteredClassifieds.slice(0, newVisibleCount));
        setHasMore(filteredClassifieds.length > newVisibleCount);
    }, [filteredClassifieds, visibleCount, itemsPerPage]);

    // Rest of the handlers stay exactly the same...
  
    const handleSearch = ({ category, keyword }) => {
        setSearchParams({ category, keyword });
        setVisibleCount(itemsPerPage);
        setHasMore(true);
        setSelectedFilters({
            category: category || '',  // Single value
            city: '',
            classifiedType: '',
            rating: []
        });
    };


    // Update handleFilterChange to handle single selection
    const handleFilterChange = (e) => {
        const [type, value] = e.target.value.split(':');
        const isChecked = e.target.checked;

        setSelectedFilters(prev => ({
            ...prev,
            [type]: isChecked ? value : '' // Set to value when checked, empty when unchecked
        }));
        setVisibleCount(itemsPerPage);
    };

    const handleClearFilters = () => {
        setSelectedFilters({
            category: '',
            city: '',
            classifiedType: '',
            rating: []
        });
        setSort('');
        setVisibleCount(itemsPerPage);
        setSearchParams({});
    };

    const handleSortChange = (e) => {
        setSort(e.target.value);
    };

    const selectedFiltersForComponent = {
        category: selectedFilters.category ? [selectedFilters.category] : [], 
        city: selectedFilters.city ? [selectedFilters.city] : [],
        classifiedType: selectedFilters.classifiedType ? [selectedFilters.classifiedType] : [],
        rating: selectedFilters.rating
    };

    const renderSearchInfo = () => {
        if (!keyword && !category && !selectedFilters.classifiedType) {
            return null;
        }
    
        const searchTerms = [];
        if (keyword) searchTerms.push(`"${keyword}"`);
        if (category) searchTerms.push(`in ${category}`);
        if (selectedFilters.classifiedType) {
            searchTerms.push(`Type: ${selectedFilters.classifiedType}`);
        }
    
        return (
            <SearchInfo>
                <div>
                    Showing results for <span className="search-term">{searchTerms.join(' ')}</span>
                </div>
                <span className="clear-search" onClick={handleClearFilters}>
                    Clear search
                </span>
            </SearchInfo>
        );
    };

    const isSortedByDistance = sort === 'location_asc' || sort === 'location_desc';

    return (
        <div data-new-title="Classified Listings | Your Site Title">
            <section id="banner" className="parallex-bg section-padding">
                <div className="container">
                    <div className="white-text text-center div_zindex">
                        <h1>Classified Listings</h1>
                        <Search categories={categories} onSearch={handleSearch} />
                    </div>
                </div>
                <div className="dark-overlay"></div>
            </section>

            <section id="inner_pages">
                <div className="container content-space-1">
                    <div className="row">
                        <div className="col-lg-3">
                            <StickyFilterContainer>
                                <div className="classified-filters">
                                  
                                <Filters
                                    categories={categories}
                                    cities={cities}
                                    onFilterChange={handleFilterChange}
                                    onClearFilters={handleClearFilters}
                                    selectedFilters={selectedFiltersForComponent}  // <- Updated to use selectedFiltersForComponent
                                    hideRatingFilter={true}
                                    classifiedTypes={classifiedTypes}
                                />
                                   
                                </div>
                                <ScrollableContainer>
                                  {/* You may wrap filter options inside this to make them scrollable */}
                              </ScrollableContainer>
                            </StickyFilterContainer>
                        </div>

                        <div className="col-lg-9">
                            {renderSearchInfo()}
                            <div className="sticky-below-nav sticky-sort-dropdown">
                                <ListHeader className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
                                    <div className="header-content">
                                        <h5>Classified Lists</h5>
                                        {visibleClassifieds.length > 0 && (
                                            <span className="result-count">
                                                {allClassifieds.length} results found
                                            </span>
                                        )}
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="mb-2 mb-sm-0 me-sm-2">
                                            <select
                                                className="form-select form-select-sm"
                                                onChange={handleSortChange}
                                                value={sort}
                                            >
                                                <option value="">Sort by</option>
                                                <option value="date_asc">Date (oldest to newest)</option>
                                                <option value="date_desc">Date (newest to oldest)</option>
                                                <option value="location_asc">Location (nearest to farthest)</option>
                                                <option value="location_desc">Location (farthest to nearest)</option>
                                            </select>
                                        </div>
                                    </div>
                                </ListHeader>
                            </div>

                            {isLoading && visibleCount === itemsPerPage ? (
                                <Spinner />
                            ) : visibleClassifieds.length > 0 ? (
                                <>
                                    <div className="row">
                                        {visibleClassifieds.map(classified => (
                                            <ClassifiedCard 
                                                key={classified.id} 
                                                classified={classified}
                                                isSortedByDistance={isSortedByDistance}
                                                showTypeLabel={true}
                                            />
                                        ))}
                                    </div>
                                    <LoadMore 
                                        isLoading={isLoading} 
                                        hasMore={hasMore} 
                                        onLoadMore={handleLoadMore} 
                                    />
                                </>
                            ) : (
                                <div className="alert alert-info text-center">
                                    <p>No results found for your search criteria.</p>
                                    {(keyword || category || selectedFilters.classifiedType) && (
                                        <ClearButton onClick={handleClearFilters}>
                                            Clear all filters
                                        </ClearButton>
                                                                            )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default ClassifiedList;


// import React, { useState, useEffect,  useCallback } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import Search from '../common/Search';
// import Filters from '../layout/Filters';
// import { useUser } from '../../context/UserContext';
// import ClassifiedCard from './ClassifiedCard';
// import Spinner from '../common/Spinner';
// import LoadMore from '../common/LoadMore';
// import styled from 'styled-components';

// const StickyFilterContainer = styled.div`
//   position: sticky;
//   top: 100px; /* Adjust this based on your navbar height */
//   z-index: 10;
// `;

// const ScrollableContainer = styled.div`
//   max-height: 200px;
//   overflow-y: auto;
//   padding-right: 10px;
//   margin-bottom: 10px;

//   /* Scrollbar styling */
//   &::-webkit-scrollbar {
//     width: 6px;
//   }
//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }
//   &::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 3px;
//   }
//   &::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }
// `;

// const SearchInfo = styled.div`
//   padding: 0.75rem 1rem;
//   margin-bottom: 1rem;
//   background-color: #f8f9fa;
//   border-radius: 0.25rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   box-shadow: 0 1px 3px rgba(0,0,0,0.1);

//   .search-term {
//     font-weight: 500;
//     color: #0d6efd;
//   }

//   .clear-search {
//     color: #dc3545;
//     cursor: pointer;
//     font-size: 0.875rem;
//     padding: 0.25rem 0.5rem;
//     border-radius: 0.25rem;
    
//     &:hover {
//       background-color: #fff;
//       text-decoration: underline;
//     }
//   }
// `;

// const ListHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 1rem;
//   width: 100%;
//   padding: 1rem;
//   background-color: #f8f9fa;
//   border-radius: 0.25rem;
//   box-shadow: 0 1px 3px rgba(0,0,0,0.05);

//   .header-content {
//     display: flex;
//     align-items: center;
//     gap: 1.5rem;  /* Increased gap between title and count */

//     h5 {
//       margin: 0;
//       font-size: 1.1rem;
//       font-weight: 500;
//       color: #2c3e50;  /* Darker text for better contrast */
//     }

//     .result-count {
//       position: relative;
//       color: #6c757d;
//       font-size: 0.875rem;
//       font-weight: normal;
//       padding-left: 1.5rem;  /* Added padding for the separator */
      
//       &::before {
//         content: '';
//         position: absolute;
//         left: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         height: 15px;
//         width: 1px;
//         background-color: #dee2e6;  /* Light separator color */
//       }
//     }
//   }

//   .sort-section {
//     select {
//       min-width: 200px;
//       border-color: #dee2e6;
//       background-color: white;
      
//       &:focus {
//         border-color: #adb5bd;
//         box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.25);
//       }
//     }
//   }

//   background: linear-gradient(to bottom, #ffffff, #f8f9fa);  /* Subtle gradient */
//   border: 1px solid #e9ecef;  /* Light border */
// `;


// const ClassifiedList = () => {
//     const [allClassifieds, setAllClassifieds] = useState([]);
//     const [visibleClassifieds, setVisibleClassifieds] = useState([]);
//     const [classifiedTypes, setClassifiedTypes] = useState([]);
//     const [selectedFilters, setSelectedFilters] = useState({
//         category: '',
//         city: '',
//         classifiedType: '',
//         rating: []  // Keep rating as array if multiple ratings are allowed
//     });
//     const [sort, setSort] = useState('');
//     const [searchParams, setSearchParams] = useSearchParams();
//     const [isLoading, setIsLoading] = useState(true);
//     const [hasMore, setHasMore] = useState(true);
//     const itemsPerPage = 12;
//     const [visibleCount, setVisibleCount] = useState(itemsPerPage);
//     const { categories, cities } = useUser();
//     const [userLocation, setUserLocation] = useState(null);

//     const keyword = searchParams.get('keyword') || '';
//     const category = searchParams.get('category') || '';

//     useEffect(() => {
//         const initLocation = () => {
//             if (navigator.geolocation) {
//                 navigator.geolocation.getCurrentPosition(
//                     (position) => setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`),
//                     () => setUserLocation('144.9631 -37.8136')
//                 );
//             } else {
//                 setUserLocation('144.9631 -37.8136');
//             }
//         };
//         initLocation();
//     }, []);

//     const fetchClassifieds = useCallback(async () => {
//         if (!userLocation) return;

//         setIsLoading(true);
//         try {
//             const params = new URLSearchParams();
//             params.append('location', userLocation);
            
//             if (sort) params.append('sort_by', sort);
//             if (keyword) params.append('keyword', keyword);
//             if (category) params.append('category', category);
            
//             selectedFilters.category.forEach(cat => params.append('category', cat));
//             selectedFilters.city.forEach(city => params.append('city', city));
//             selectedFilters.classifiedType.forEach(type => params.append('classified_type', type));

//             const response = await fetch(`/api/classifieds/?${params.toString()}`);
//             const data = await response.json();

//             if (data.classifieds && Array.isArray(data.classifieds)) {
//                 setAllClassifieds(data.classifieds);
//                 setVisibleClassifieds(data.classifieds.slice(0, visibleCount));
//                 setHasMore(data.classifieds.length > visibleCount);
                
//                 if (data.classified_types) {
//                     setClassifiedTypes(data.classified_types);
                    
//                     // Auto-select classified type if it matches keyword
//                     if (keyword && !selectedFilters.classifiedType.length) {
//                         const matchingType = data.classified_types.find(type => 
//                             type.name.toLowerCase() === keyword.toLowerCase()
//                         );
//                         if (matchingType) {
//                             setSelectedFilters(prev => ({
//                                 ...prev,
//                                 classifiedType: [matchingType.name]
//                             }));
//                         }
//                     }
//                 }
//             }
//         } catch (error) {
//             console.error('Error fetching classifieds:', error);
//             setAllClassifieds([]);
//             setVisibleClassifieds([]);
//             setHasMore(false);
//         } finally {
//             setIsLoading(false);
//         }
//     }, [userLocation, sort, keyword, category, selectedFilters, visibleCount]);

//     useEffect(() => {
//         fetchClassifieds();
//     }, [fetchClassifieds]);

//     useEffect(() => {
//         if (!allClassifieds.length) return;

//         const filteredAndSorted = [...allClassifieds]
//             .filter(classified => {
//                 let matches = true;
                
//                 if (selectedFilters.category.length) {
//                     matches = matches && selectedFilters.category.includes(classified.category_name);
//                 }
//                 if (selectedFilters.city.length) {
//                     matches = matches && selectedFilters.city.includes(classified.city_name);
//                 }
//                 if (keyword) {
//                     const lowKeyword = keyword.toLowerCase();
//                     matches = matches && (
//                         (classified.title?.toLowerCase().includes(lowKeyword)) ||
//                         (classified.description?.toLowerCase().includes(lowKeyword)) ||
//                         (classified.classified_type_name?.toLowerCase().includes(lowKeyword))
//                     );
//                 }
//                 return matches;
//             })
//             .sort((a, b) => {
//                 const getPriority = (c) => c.is_featured ? 0 : c.is_urgent ? 1 : 2;
//                 const priorityDiff = getPriority(a) - getPriority(b);
//                 if (priorityDiff !== 0) return priorityDiff;

//                 switch(sort) {
//                     case 'location_asc':
//                         return (a.distance || Infinity) - (b.distance || Infinity);
//                     case 'location_desc':
//                         return (b.distance || -Infinity) - (a.distance || -Infinity);
//                     case 'date_asc':
//                         return new Date(a.created_at) - new Date(b.created_at);
//                     case 'date_desc':
//                         return new Date(b.created_at) - new Date(a.created_at);
//                     default:
//                         return new Date(b.created_at) - new Date(a.created_at);
//                 }
//             });

//         setVisibleClassifieds(filteredAndSorted.slice(0, visibleCount));
//         setHasMore(filteredAndSorted.length > visibleCount);
//     }, [allClassifieds, selectedFilters, sort, keyword, visibleCount]);

//     const handleLoadMore = () => {
//         setVisibleCount(prev => prev + itemsPerPage);
//     };

//     const handleSearch = ({ category, keyword }) => {
//         setSearchParams({ category, keyword });
//         setVisibleCount(itemsPerPage);
//         setHasMore(true);
//         // Reset filters when performing a new search
//         setSelectedFilters({
//             category: category ? [category] : [],
//             city: [],
//             classifiedType: [],
//             rating: []
//         });
//     };

//     const handleFilterChange = (e) => {
//         const [type, value] = e.target.value.split(':');
//         const isChecked = e.target.checked;

//         setSelectedFilters(prev => ({
//             ...prev,
//             [type]: isChecked 
//                 ? [...prev[type], value]
//                 : prev[type].filter(item => item !== value)
//         }));
//         setVisibleCount(itemsPerPage);
//     };

//     const handleClearFilters = () => {
//         setSelectedFilters({
//             category: [],
//             city: [],
//             classifiedType: [],
//             rating: []
//         });
//         setSort('');
//         setVisibleCount(itemsPerPage);
//         setSearchParams({});
//     };

//     const handleSortChange = (e) => {
//         setSort(e.target.value);
//     };

//     const renderSearchInfo = () => {
//         if (!keyword && !category && !selectedFilters.classifiedType.length) {
//             return null;
//         }

//         const searchTerms = [];
//         if (keyword) searchTerms.push(`"${keyword}"`);
//         if (category) searchTerms.push(`in ${category}`);
//         if (selectedFilters.classifiedType.length) {
//             searchTerms.push(`Type: ${selectedFilters.classifiedType.join(', ')}`);
//         }

//         return (
//             <SearchInfo>
//                 <div>
//                     Showing results for <span className="search-term">{searchTerms.join(' ')}</span>
//                 </div>
//                 <span className="clear-search" onClick={handleClearFilters}>
//                     Clear search
//                 </span>
//             </SearchInfo>
//         );
//     };

//     const isSortedByDistance = sort === 'location_asc' || sort === 'location_desc';

//     return (
//         <div data-new-title="Classified Listings | Your Site Title">
//             <section id="banner" className="parallex-bg section-padding">
//                 <div className="container">
//                     <div className="white-text text-center div_zindex">
//                         <h1>Classified Listings</h1>
//                         <Search categories={categories} onSearch={handleSearch} />
//                     </div>
//                 </div>
//                 <div className="dark-overlay"></div>
//             </section>

//             <section id="inner_pages">
//                 <div className="container content-space-1">
//                     <div className="row">
//                         <div className="col-lg-3">
//                             <StickyFilterContainer>
//                                 <div className="classified-filters">
                                  
//                                         <Filters
//                                             categories={categories}
//                                             cities={cities}
//                                             onFilterChange={handleFilterChange}
//                                             onClearFilters={handleClearFilters}
//                                             selectedFilters={selectedFilters}
//                                             hideRatingFilter={true}
//                                             classifiedTypes={classifiedTypes}
//                                         />
                                   
//                                 </div>
//                                 <ScrollableContainer>
//                                   {/* You may wrap filter options inside this to make them scrollable */}
//                               </ScrollableContainer>
//                             </StickyFilterContainer>
//                         </div>

//                         <div className="col-lg-9">
//                             {renderSearchInfo()}
//                             <div className="sticky-below-nav sticky-sort-dropdown">
//                                 <ListHeader className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
//                                     <div className="header-content">
//                                         <h5>Classified Lists</h5>
//                                         {visibleClassifieds.length > 0 && (
//                                             <span className="result-count">
//                                                 {allClassifieds.length} results found
//                                             </span>
//                                         )}
//                                     </div>
//                                     <div className="d-flex align-items-center">
//                                         <div className="mb-2 mb-sm-0 me-sm-2">
//                                             <select
//                                                 className="form-select form-select-sm"
//                                                 onChange={handleSortChange}
//                                                 value={sort}
//                                             >
//                                                 <option value="">Sort by</option>
//                                                 <option value="date_asc">Date (oldest to newest)</option>
//                                                 <option value="date_desc">Date (newest to oldest)</option>
//                                                 <option value="location_asc">Location (nearest to farthest)</option>
//                                                 <option value="location_desc">Location (farthest to nearest)</option>
//                                             </select>
//                                         </div>
//                                     </div>
//                                 </ListHeader>
//                             </div>

//                             {isLoading && visibleCount === itemsPerPage ? (
//                                 <Spinner />
//                             ) : visibleClassifieds.length > 0 ? (
//                                 <>
//                                     <div className="row">
//                                         {visibleClassifieds.map(classified => (
//                                             <ClassifiedCard 
//                                                 key={classified.id} 
//                                                 classified={classified}
//                                                 isSortedByDistance={isSortedByDistance}
//                                                 showTypeLabel={true}
//                                             />
//                                         ))}
//                                     </div>
//                                     <LoadMore 
//                                         isLoading={isLoading} 
//                                         hasMore={hasMore} 
//                                         onLoadMore={handleLoadMore} 
//                                     />
//                                 </>
//                             ) : (
//                                 <div className="alert alert-info text-center">
//                                     <p>No results found for your search criteria.</p>
//                                     {(keyword || category || selectedFilters.classifiedType.length > 0) && (
//                                         <button 
//                                             className="btn btn-link"
//                                             onClick={handleClearFilters}
//                                         >
//                                             Clear all filters
//                                         </button>
//                                     )}
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//     );
// };

// export default ClassifiedList;











