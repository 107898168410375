import React from 'react';
import { Loader } from 'lucide-react';

const Spinner = ({ size = 48, fullScreen = false, height = '200px' }) => (
  <div
    className={`d-flex justify-content-center align-items-center ${fullScreen ? 'spinner-fullscreen' : ''}`}
    style={{ height: fullScreen ? '100vh' : height }}
  >
    <Loader className="animate-spin" size={size} />
  </div>
);

export default Spinner;