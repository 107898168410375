// Load more managed by frontend


import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Search from '../common/Search';
import Filters from '../layout/Filters';
import { useUser } from '../../context/UserContext';
import EventCard from './EventCard';
import Spinner from '../common/Spinner';
import LoadMore from '../common/LoadMore';  // Import the LoadMore component

const EventList = () => {
    const [allEvents, setAllEvents] = useState([]); // Store all events
    const [visibleEvents, setVisibleEvents] = useState([]); // Store the currently visible events
    const [filter, setFilter] = useState('');
    const [sort, setSort] = useState('');
    const [cityFilter, setCityFilter] = useState('');
    const [ratingFilter, setRatingFilter] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true); // Track if there are more events to load
    const itemsPerPage = 12;  // Number of items to show per "Load More" click
    const [visibleCount, setVisibleCount] = useState(itemsPerPage); // Number of visible items
    const { categories, cities } = useUser();
    const [userLocation, setUserLocation] = useState(null);

    const keyword = searchParams.get('keyword') || '';
    const category = searchParams.get('category') || '';

    useEffect(() => {
        // Get user's location
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
                },
                (error) => {
                    // console.error("Error getting user location:", error);
                    setUserLocation('144.9631 -37.8136'); // Default to Melbourne's coordinates
                }
            );
        } else {
            // console.log("Geolocation is not supported by this browser.");
            setUserLocation('144.9631 -37.8136'); // Default to Melbourne's coordinates
        }
    }, []);

    useEffect(() => {
        const fetchEvents = async () => {
            setIsLoading(true);
            try {
                const params = new URLSearchParams();
                if (userLocation) params.append('location', userLocation);
                if (sort) params.append('sort_by', sort);
                if (ratingFilter.length > 0) {
                    const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
                    params.append('rating', minRating);
                  }
    
                const response = await fetch(`/api/event_list/?${params.toString()}`);
                const data = await response.json();
    
                if (data && data.features && Array.isArray(data.features)) {
                    const eventsData = data.features.map(feature => ({
                        ...feature.properties,
                        geometry: feature.geometry
                    }));
                    setAllEvents(eventsData);
                    setVisibleEvents(eventsData.slice(0, itemsPerPage)); // Initialize visible events
                    setHasMore(eventsData.length > itemsPerPage);
                } else {
                    // console.error('Unexpected API response:', data);
                    setAllEvents([]);
                    setVisibleEvents([]);
                    setHasMore(false);
                }
            } catch (error) {
                // console.error('Error fetching events:', error);
                setAllEvents([]);
                setVisibleEvents([]);
                setHasMore(false);
            } finally {
                setIsLoading(false);
            }
        };
    
        fetchEvents();
    }, [searchParams, category, keyword, userLocation, sort, ratingFilter]);

    const handleLoadMore = () => {
        const newVisibleCount = visibleCount + itemsPerPage;
        setVisibleCount(newVisibleCount);
    };

    const handleSearch = ({ category, keyword }) => {
        setSearchParams({ category, keyword });
        setVisibleCount(itemsPerPage); // Reset visible count when search changes
        setHasMore(true); // Reset hasMore to true
    };

    const handleFilterChange = (e) => {
        const [type, value] = e.target.value.split(':');
        setVisibleCount(itemsPerPage);  // Reset visible items count
        setHasMore(true);  // Reset hasMore to true
    
        switch (type) {
            case 'category':
                setFilter(value);
                break;
            case 'city':
                setCityFilter(value);
                break;
            case 'rating':
                setRatingFilter(prevRating => {
                    if (prevRating.includes(value)) {
                    return prevRating.filter(r => r !== value);
                    } else {
                    return [...prevRating, value];
                    }
                });
                break;
            default:
                setSort(e.target.value);
        }
    };
    
    const handleClearFilters = () => {
        setFilter('');
        setCityFilter('');
        setRatingFilter('');
        setSort('');
        setVisibleCount(itemsPerPage);  // Reset visible items count
        setHasMore(true);  // Reset hasMore to true
    };

    useEffect(() => {
        const filteredEvents = allEvents.filter(event => {
            let matchesFilter = true;
    
            if (filter) {
                const categoryMatch = categories.find(cat => cat.name.toLowerCase() === filter.toLowerCase());
                matchesFilter = matchesFilter && event.category === categoryMatch?.id;
            }
    
            if (cityFilter) {
                const cityMatch = cities.find(c => c.name.toLowerCase() === cityFilter.toLowerCase());
                matchesFilter = matchesFilter && event.city === cityMatch?.id;
            }
    
            if (ratingFilter.length > 0) {
                const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
                // console.log(`Checking if event meets rating: ${event.title} with average_rating: ${event.average_rating}`);
                matchesFilter = matchesFilter && event.average_rating >= minRating;
            }
    
            if (category) {
                const searchCategoryMatch = categories.find(cat => cat.name.toLowerCase() === category.toLowerCase());
                matchesFilter = matchesFilter && event.category === searchCategoryMatch?.id;
            }
    
            if (keyword) {
                const lowerKeyword = keyword.toLowerCase();
                matchesFilter = matchesFilter && (
                    (event.title && event.title.toLowerCase().includes(lowerKeyword)) ||
                    (event.description && event.description.toLowerCase().includes(lowerKeyword)) ||
                    (event.tagline && event.tagline.toLowerCase().includes(lowerKeyword))
                );
            }
    
            return matchesFilter;
        });
    
        const sortedEvents = filteredEvents.sort((a, b) => {
            if (sort === 'location_asc') {
                return (a.distance || Infinity) - (b.distance || Infinity);
            } else if (sort === 'location_desc') {
                return (b.distance || -Infinity) - (a.distance || -Infinity);
            } else if (sort === 'date_asc') {
                return new Date(a.start_date) - new Date(b.start_date);
            } else if (sort === 'date_desc') {
                return new Date(b.start_date) - new Date(a.start_date);
            }
            return 0;
        });
    
        setVisibleEvents(sortedEvents.slice(0, visibleCount));
        setHasMore(visibleCount < sortedEvents.length);
    }, [allEvents, filter, cityFilter, ratingFilter, sort, keyword, category, visibleCount, categories, cities]);

    const isSortedByDistance = sort === 'location_asc' || sort === 'location_desc';

    return (
        <div data-new-title="Event Listings | Your Site Title">
            <section id="banner" className="parallex-bg section-padding">
                <div className="container">
                    <div className="white-text text-center div_zindex">
                        <h1>Event Listings</h1>
                        <Search categories={categories} onSearch={handleSearch} />
                    </div>
                </div>
                <div className="dark-overlay"></div>
            </section>

            <section id="inner_pages">
                <div className="container content-space-1">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="sticky-below-nav">
                                <div className="navbar-expand-lg mb-5">
                                    <div className="d-grid">
                                        <button
                                            type="button"
                                            className="navbar-toggler btn btn-white mb-3"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#navbarVerticalNavMenuEg2"
                                            aria-label="Toggle navigation"
                                            aria-expanded="false"
                                            aria-controls="navbarVerticalNavMenuEg2"
                                        >
                                            <span className="d-flex justify-content-between align-items-center">
                                                <span className="text-dark">Filter</span>
                                                <span className="navbar-toggler-default">
                                                    <i className="bi-list"></i>
                                                </span>
                                                <span className="navbar-toggler-toggled">
                                                    <i className="bi-x"></i>
                                                </span>
                                            </span>
                                        </button>
                                    </div>

                                    <div id="navbarVerticalNavMenuEg2" className="collapse navbar-collapse">
                                        <div className="w-100">
                                            <Filters
                                                categories={categories}
                                                cities={[...new Set(allEvents.map(event => event.city).filter(city => city))]}
                                                onFilterChange={handleFilterChange}
                                                onClearFilters={handleClearFilters}
                                                selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9">
                            {keyword || category ? (
                                <div className="alert alert-info text-center">
                                    <p>Your search results for {keyword && `"${keyword}"`} {category && `in category "${category}"`}:</p>
                                </div>
                            ) : (
                                <div className="alert alert-info text-center">
                                    <p>Showing all Events:</p>
                                </div>
                            )}
                            <div className="sticky-below-nav sticky-sort-dropdown">
                            <div className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
                                    <h5>Events Lists</h5>
                                    <div className="d-flex align-items-center">
                                        
                                        <div className="mb-2 mb-sm-0 me-sm-2">
                                            <select
                                                className="form-select form-select-sm"
                                                onChange={handleFilterChange}
                                                value={sort}
                                            >
                                                                                        <option value="">Sort by</option>
                                            <option value="date_asc">Date (earliest to latest)</option>
                                            <option value="date_desc">Date (latest to earliest)</option>
                                            <option value="location_asc">Location (nearest to farthest)</option>
                                            <option value="location_desc">Location (farthest to nearest)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {isLoading && visibleCount === itemsPerPage ? (
                            <Spinner />
                        ) : visibleEvents.length > 0 ? (
                            <>
                                <div className="row">
                                    {visibleEvents.map(event => (
                                        <EventCard 
                                            key={event.id} 
                                            event={event}
                                            isSortedByDistance={isSortedByDistance}
                                        />
                                    ))}
                                </div>
                                <LoadMore isLoading={isLoading} hasMore={hasMore} onLoadMore={handleLoadMore} />
                            </>
                        ) : (
                            <div className="alert alert-info text-center">
                                <p>No events found.</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    </div>
);
};

export default EventList;


