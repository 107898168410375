import React from 'react';
import { Star } from 'lucide-react';

const StarRating = ({ rating }) => {
    const maxStars = 5;

    return (
        <div className="listing-rating">
            {[...Array(maxStars)].map((_, index) => (
                <Star
                    key={index}
                    fill={index < rating ? "#FFD700" : "none"}  // Fill stars based on the rating
                    stroke={index < rating ? "#FFD700" : "#000000"}
                    size={24} // Adjust size as needed
                    style={{ marginRight: '5px' }} // Adjust spacing as needed
                />
            ))}
        </div>
    );
};

export default StarRating;