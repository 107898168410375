import React, { useState, useEffect } from 'react';
import { getCookie } from '../../utils/csrf';
import LoginPopup from '../common/LoginPopup';

const BookmarkButton = ({ contentType, contentId, initialBookmarks, userHasBookmarked }) => {
    const [bookmarks, setBookmarks] = useState(initialBookmarks);
    const [bookmarked, setBookmarked] = useState(userHasBookmarked);
    const [showPopup, setShowPopup] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const fetchAuthStatus = async () => {
            try {
                const response = await fetch('/api/auth-status/', {
                    credentials: 'include',
                });
                const data = await response.json();
                setIsAuthenticated(data.isAuthenticated);
            } catch (error) {
                // console.error('Error checking auth status:', error);
            }
        };
        fetchAuthStatus();
    }, []);

    const handleBookmark = async () => {
        if (!isAuthenticated) {
            setShowPopup(true);
            return;
        }

        try {
            const response = await fetch(`/api/toggle-bookmark/${contentType}/${contentId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            });

            if (response.ok) {
                const data = await response.json();
                setBookmarks(data.bookmark_count);
                setBookmarked(data.bookmarked);  // Set the bookmarked state based on the backend response
            } else {
                // console.error('Error toggling bookmark:', response.statusText);
            }
        } catch (error) {
            // console.error('Error toggling bookmark:', error);
        }
    };

    const handleConfirmLogin = () => {
        window.location.href = `/api/login/?next=${window.location.pathname}`;
    };

    return (
        <>
            <div className={`meta-wp ${bookmarked ? 'bookmarked-animation' : ''}`}>
                <i className={`fa ${bookmarked ? 'fa-bookmark' : 'fa-bookmark-o'}`} onClick={handleBookmark}></i> {bookmarks} Bookmarks
            </div>
            {showPopup && (
                <LoginPopup 
                    message="You need to be logged in to bookmark this content."
                    onConfirm={handleConfirmLogin}
                    onCancel={() => setShowPopup(false)}
                />
            )}
        </>
    );
};

export default BookmarkButton;