import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Search from './common/Search';
import Filters from './layout/Filters';
import BusinessCard from './businesses/BusinessListingCard';
import EventCard from './events/EventCard';
import DealCard from './deals/DealListingCard';
import ClassifiedCard from './classifieds/ClassifiedCard'; 
import Spinner from './common/Spinner';
import LoadMore from './common/LoadMore';
import { useUser } from '../context/UserContext';

const CombinedList = () => {
    const { id } = useParams(); // Using id instead of slug
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [allListings, setAllListings] = useState([]);
    const [visibleListings, setVisibleListings] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const itemsPerPage = 12;
    const [visibleCount, setVisibleCount] = useState(itemsPerPage);
    
    const { categories, cities } = useUser();
    const [userLocation, setUserLocation] = useState(null);
    const [categoryName, setCategoryName] = useState('');
    const [cityName, setCityName] = useState('');
    const [ratingFilter, setRatingFilter] = useState([]);

    const filter = searchParams.get('filter') || '';
    const cityFilter = searchParams.get('city') || '';
    
    const sort = searchParams.get('sort') || '';
    

    // Determine if sorting is by distance
    const isSortedByDistance = sort === 'distance_asc' || sort === 'distance_desc';



    // Get user's location
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
                },
                (error) => {
                    setUserLocation('144.9631 -37.8136'); // Default to Melbourne's coordinates
                }
            );
        } else {
            setUserLocation('144.9631 -37.8136'); // Default to Melbourne's coordinates
        }
    }, []);

    // Fetch listings based on filters, sort, and user location
    useEffect(() => {
        const fetchListings = async () => {
            setIsLoading(true);
            try {
                const id_type = searchParams.get('id_type') || 'category';
                const params = new URLSearchParams({
                    filter,
                    sort,
                    id_type,
                });

                if (cityFilter) {
                    params.append('city', cityFilter);
                }

                if (ratingFilter.length > 0) {
                    params.append('rating', ratingFilter.join(','));
                }

                if (userLocation) {
                    params.append('location', userLocation);
                }

                const response = await fetch(`/api/total-list/${id}/?${params.toString()}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                const transformedData = data.map(item => {
                    if (item.type === 'business' || item.type === 'event') {
                        return {
                            ...item.properties,
                            id: item.id,
                            type: item.type,
                            geometry: item.geometry,
                            distance: item.distance || item.properties.distance,
                        };
                    }
                    return item;
                });

                setAllListings(transformedData);
                setVisibleListings(transformedData.slice(0, itemsPerPage));
                setHasMore(transformedData.length > itemsPerPage);

                if (id_type === 'category') {
                    const selectedCategory = categories.find(cat => cat.id === parseInt(id));
                    if (selectedCategory) setCategoryName(selectedCategory.name);
                    else setCategoryName('');
                } else if (id_type === 'city') {
                    const selectedCity = cities.find(cty => cty.id === parseInt(id));
                    if (selectedCity) setCityName(selectedCity.name);
                    else setCityName('');
                } else {
                    setCategoryName('');
                    setCityName('');
                }

            } catch (error) {
                setAllListings([]);
                setVisibleListings([]);
                setHasMore(false);
            } finally {
                setIsLoading(false);
            }
        };

        fetchListings();
    }, [id, searchParams, categories, cities, userLocation, filter, cityFilter, ratingFilter, sort]);

    // Handle Load More
    const handleLoadMore = () => {
        const newVisibleCount = visibleCount + itemsPerPage;
        setVisibleCount(newVisibleCount);
        setVisibleListings(allListings.slice(0, newVisibleCount));
        setHasMore(allListings.length > newVisibleCount);
    };

    const handleFilterChange = (e) => {
        const [type, value] = e.target.value.split(':');
        const newSearchParams = new URLSearchParams(searchParams.toString());

        switch (type) {
            case 'category':
                newSearchParams.set('filter', value);
                break;
            case 'city':
                newSearchParams.set('city', value);
                break;
                case 'rating':
                    setRatingFilter(prevRating => {
                      if (prevRating.includes(value)) {
                        return prevRating.filter(r => r !== value);
                      } else {
                        return [...prevRating, value];
                      }
                    });
                    break;
            default:
                newSearchParams.set('sort', value);
        }

        setSearchParams(newSearchParams);
        setVisibleCount(itemsPerPage);
    };

    const handleClearFilters = () => {
        const newSearchParams = new URLSearchParams();
        
        // Preserve only essential parameters
        const id_type = searchParams.get('id_type');
        if (id_type) {
            newSearchParams.set('id_type', id_type);
        }
    
        // Preserve the id parameter
        const idValue = searchParams.get('id');
        if (idValue) {
            newSearchParams.set('id', idValue);
        }
    
        // Preserve the location parameter
        const locationValue = searchParams.get('location');
        if (locationValue) newSearchParams.set('location', locationValue);
    
        // Clear all other filters
        newSearchParams.delete('filter');
        newSearchParams.delete('city');
        newSearchParams.delete('rating');
        newSearchParams.delete('sort');
    
        setSearchParams(newSearchParams);
        setVisibleCount(itemsPerPage);
    
        // Additional logic specific to CombinedList
        
        setRatingFilter([]);
       
        setVisibleCount(itemsPerPage);
    };

    // Apply filters and sorting
    useEffect(() => {
        const filteredListings = allListings.filter(listing => {
            const matchesCategory = !filter || listing.category_name === filter;
            const matchesCity = !cityFilter || listing.city_name === cityFilter;
            const matchesRating = !ratingFilter.length || listing.average_rating >= Math.min(...ratingFilter);
            return matchesCategory && matchesCity && matchesRating;
        });
    
        const sortedListings = filteredListings.sort((a, b) => {
            if (sort === 'distance_asc') {
                return (a.distance || Infinity) - (b.distance || Infinity);
            } else if (sort === 'distance_desc') {
                return (b.distance || -Infinity) - (a.distance || -Infinity);
            } else if (sort === 'asc') {
                return a.title.localeCompare(b.title);
            } else if (sort === 'desc') {
                return b.title.localeCompare(a.title);
            }
            return 0;
        });
    
        setVisibleListings(sortedListings.slice(0, visibleCount));
        setHasMore(visibleCount < sortedListings.length);
    }, [allListings, filter, cityFilter, ratingFilter, sort, visibleCount]);


        return (
            <div data-new-title="Total Listings | Your Site Title">
                <section id="banner" className="parallex-bg section-padding">
                    <div className="container">
                        <div className="intro_text white-text div_zindex">
                            <h1>Total Listings</h1>
                            <Search categories={categories} redirectOnSearch={true} />
                        </div>
                    </div>
                </section>
    
                <section id="inner_pages">
                    <div className="container content-space-1">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="sticky-below-nav">
                                    <Filters
                                        categories={categories}
                                        cities={cities}
                                        onFilterChange={handleFilterChange}
                                        onClearFilters={handleClearFilters}
                                        selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
                                    />
                                </div>
                            </div>
    
                            <div className="col-lg-9">
                                <div className="sticky-below-nav sticky-sort-dropdown">
                                    {categoryName && (
                                        <div className="alert alert-info text-center">
                                            <p>Showing listings for category: <strong>{categoryName}</strong></p>
                                        </div>
                                    )}
                                    {cityName && (
                                        <div className="alert alert-info text-center">
                                            <p>Showing listings for city: <strong>{cityName}</strong></p>
                                        </div>
                                    )}
                                    <div className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
                                        <h5>Listings</h5>
                                        <div className="d-flex align-items-center">
                                            <select
                                                className="form-select form-select-sm"
                                                onChange={handleFilterChange}
                                                value={sort}
                                            >
                                                <option value="">Sort by</option>
                                                <option value="asc">A-Z (asc order)</option>
                                                <option value="desc">Z-A (desc order)</option>
                                                <option value="distance_asc">Location (nearest to farthest)</option>
                                                <option value="distance_desc">Location (farthest to nearest)</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
    
                                {isLoading ? (
                                    <Spinner />
                                ) : visibleListings.length > 0 ? (
                                    <>
                                        <div className="row">
                                        {visibleListings.map(listing => (
                                                listing.type === 'business' ? (
                                                    <BusinessCard 
                                                        key={`business-${listing.id}`} 
                                                        business={listing} 
                                                        showTypeLabel={true} 
                                                        isSortedByDistance={isSortedByDistance} 
                                                    />
                                                ) : listing.type === 'event' ? (
                                                    <EventCard 
                                                        key={`event-${listing.id}`} 
                                                        event={listing} 
                                                        showTypeLabel={true} 
                                                        isSortedByDistance={isSortedByDistance} 
                                                    />
                                                ) : listing.type === 'deal' ? (
                                                    <DealCard 
                                                        key={`deal-${listing.id}`} 
                                                        deal={listing} 
                                                        showTypeLabel={true} 
                                                        isSortedByDistance={isSortedByDistance} 
                                                    />
                                                ) : listing.type === 'classified' ? ( // Corrected line
                                                    <ClassifiedCard // Handle classified listings
                                                        key={`classified-${listing.id}`} 
                                                        classified={listing} 
                                                        showTypeLabel={true} 
                                                        isSortedByDistance={isSortedByDistance} 
                                                    />
                                                ) : null
                                            ))}
                                                                                        
                                        </div>
                                        <LoadMore isLoading={isLoading} hasMore={hasMore} onLoadMore={handleLoadMore} />
                                    </>
                                ) : (
                                    <div className="alert alert-info text-center">
                                        <p>No listings found.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
    
export default CombinedList;

