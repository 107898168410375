import React, { useState, useEffect } from 'react';
import { getCookie } from '../../utils/csrf';
import LoginPopup from '../common/LoginPopup';

const InterestedButton = ({ contentType, contentId, initialInterested, initialInterestedCount }) => {
    const [interested, setInterested] = useState(initialInterested);
    const [interestedCount, setInterestedCount] = useState(initialInterestedCount);
    const [showPopup, setShowPopup] = useState(false);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        const fetchAuthStatus = async () => {
            try {
                const response = await fetch('/api/auth-status/', {
                    credentials: 'include',
                });
                const data = await response.json();
                setIsAuthenticated(data.isAuthenticated);
            } catch (error) {
                // console.error('Error checking auth status:', error);
            }
        };
        fetchAuthStatus();
    }, []);

    const handleToggleInterested = async () => {
        if (!isAuthenticated) {
            setShowPopup(true);
            return;
        }

        try {
            const response = await fetch(`/api/toggle-interested/${contentType}/${contentId}/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookie('csrftoken'),
                },
            });

            if (response.ok) {
                const data = await response.json();
                setInterested(data.interested);  // Set the interested state based on the backend response
                setInterestedCount(data.interested_count);  // Update the interested count
            } else {
                // console.error('Error toggling interested status:', response.statusText);
            }
        } catch (error) {
            // console.error('Error toggling interested status:', error);
        }
    };

    const handleConfirmLogin = () => {
        window.location.href = `/api/login/?next=${window.location.pathname}`;
    };

    return (
        <>
            <div className={`meta-wp ${interested ? 'interested-animation' : ''}`}>
                <i className={`fa ${interested ? 'fa-star' : 'fa-star-o'}`} onClick={handleToggleInterested}></i> {interestedCount} Interested
            </div>
            {showPopup && (
                <LoginPopup 
                    message="You need to be logged in to mark your interest in this content."
                    onConfirm={handleConfirmLogin}
                    onCancel={() => setShowPopup(false)}
                />
            )}
        </>
    );
};

export default InterestedButton;