import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <>
      {/* Inner-Banner */}
      <section id="banner" className="parallex-bg section-padding">
        <div className="container">
          <div className="white-text text-center div_zindex">
            <h1>Error 404</h1>
          </div>
        </div>
        <div className="dark-overlay"></div>
      </section>
      {/* /Inner-Banner */}

      {/* Not Found Content */}
      <section id="inner_pages">
        <div className="container">
          <div className="not_found_msg text-center">
            <div className="error_msg_div">
              <h2><span>404</span>Oops! That page not found</h2>
              <p>We're sorry, but the page you're looking for doesn't exist or has been moved. Please check the URL or try using our navigation menu to find what you're looking for.</p>
              <Link to="/" className="btn">Back To Home</Link>
            </div>
          </div>
        </div>
      </section>
      {/* /Not Found Content */}
    </>
  );
};

export default NotFound;