import React from 'react';

const LoadMore = ({ isLoading, hasMore, onLoadMore }) => {
  if (!hasMore) return null;

  return (
    <div className="text-center mt-4 mb-4">
      <button 
        className="btn btn-primary"
        onClick={onLoadMore}
        disabled={isLoading}
      >
        {isLoading ? 'Loading...' : 'Load More'}
      </button>
    </div>
  );
};

export default LoadMore;