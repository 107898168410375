

import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ContentStats from '../ratings/ContentStats';
import Spinner from '../common/Spinner';

const FeaturedBusiness = () => {
  const [featuredBusiness, setFeaturedBusiness] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFeaturedBusiness = async () => {
      try {
        const response = await fetch('/api/featured_business/');
        const data = await response.json();
        setFeaturedBusiness(data);
      } catch (error) {
        console.error('Error fetching featured businesses:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchFeaturedBusiness();
  }, []);

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: 'block',
          background: 'transparent',
          color: '#007bff',
          right: '-40px'
        }}
        onClick={onClick}
      >
        <ChevronRight size={40} />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={className}
        style={{
          display: 'block',
          background: 'transparent',
          color: '#007bff',
          left: '-60px',
          zIndex: 1
        }}
        onClick={onClick}
      >
        <ChevronLeft size={40} />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <section id="featured_listings" className="section-padding">
      <div className="container">
        <div className="section-header text-center">
          <h2>Featured Businesses</h2>
          <p>Check out these businesses that are featured in our directory.</p>
        </div>
        <div id="featured_listing_slider">
          {featuredBusiness.length > 0 ? (
            <Slider {...settings}>
              {featuredBusiness.map((ad) => (
                <div className="listing_wrap d-flex flex-column" key={ad.id}>
                  <div className="listing_img">
                    <a href={ad.ad_URL || `/business-details/${ad.id}/`} target={ad.ad_URL ? "_blank" : "_self"} rel="noopener noreferrer">
                    <img src={ad.ad_image_url} alt={ad.ad_title} />
                    </a>
                  </div>
                  <div className="listing_info h-100">
                    <span className="badge bg-warning mb-2">Ad</span>
                    <div className="post_category">
                      <a href={ad.ad_URL || `/business-details/${ad.id}/`} target={ad.ad_URL ? "_blank" : "_self"} rel="noopener noreferrer">
                        {ad.ad_title}
                      </a>
                    </div>
                    <p>{ad.ad_description.substring(0, 50)}...</p>
                    <p className="listing_map_m"><i className="fa fa-map-marker"></i> {ad.address}</p>
                  </div>
                  <div className="listing_footer d-flex justify-content-around pt-3 pb-3 ml-3">
                    <ContentStats
                      contentType={ad.content_type}
                      contentId={ad.id}
                      initialLikes={ad.likes}
                      initialBookmarks={ad.bookmarks}
                      userHasLiked={ad.user_has_liked}
                      userHasBookmarked={ad.user_has_bookmarked}
                    />
                  </div>
                </div>
              ))}
            </Slider>
          ) : (
            <p>No featured businesses available at the moment.</p>
          )}
        </div>
      </div>
    </section>
  );
};

export default FeaturedBusiness;