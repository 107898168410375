// src/components/Footer.js
import React from 'react';
import NewsletterForm from './NewsletterForm';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer id="footer" className="footer_2 secondary-bg">
      <div className="container">
      <NewsletterForm/>
        {/* <div className="footer_widgets">
          <h5>Our Newsletter</h5>
          <div className="newsletter_wrap">
            <form id="NewsletterForm">
              <input type="email" className="form-control" placeholder="Enter Email Address" name="nl_email" id="nl_email" />
              <input type="submit" value="Subscribe" className="btn" />
            </form>
            <div id="success-message" style={{ display: 'none' }}>
              Subscription successful!
            </div>
          </div>
        </div> */}
        <div className="footer_widgets">
          <div className="footer_nav">
            <ul>
              <li><a href="/how-it-works">How it Work</a></li>
              {/* <li><a href="#">Pricing</a></li> */}
              {/* <li><a href="#">Blog</a></li> */}
              <li><a href="/terms-of-service">Terms Of Service</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
              <li><a href="/terms-and-conditions">Terms & condition</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="footer_bottom">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-md-push-6">
              <div className="follow_us">
                <ul>
                  <li><a href="#"><i className="fa fa-facebook" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-twitter" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                  <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-md-pull-6">
              <p>Copyright &copy; {currentYear} Desi Firms. All Rights Reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;