import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Search from '../common/Search';
import Filters from '../layout/Filters';
import { useUser } from '../../context/UserContext';
import BusinessListingCard from './BusinessListingCard';
import Spinner from '../common/Spinner';
import LoadMore from '../common/LoadMore';
import { Link } from 'react-router-dom';
import styled from 'styled-components';


// Styled components for the matching products section
const MatchingProductsContainer = styled.div`
  background-color: #d1e7dd;
  border-radius: 0.375rem;
  margin-bottom: 1.5rem;
  max-height: ${props => props.isExpanded ? '600px' : '300px'};
  transition: max-height 0.3s ease-in-out;
`;

const ScrollableContent = styled.div`
  max-height: ${props => props.isExpanded ? '500px' : '200px'};
  overflow-y: auto;
  padding: 1rem;
  
  &::-webkit-scrollbar {
    width: 8px;
  }
  
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const BusinessSection = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
  &:last-child {
    border-bottom: none;
  }
`;

const BusinessLink = styled(Link)`
  color: #009fe3;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.1rem;
  display: block;
  margin-bottom: 0.5rem;
  
  &:hover {
    color: #0a58ca;
    text-decoration: underline;
  }
`;

const ProductItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.25rem 0;
  margin-left: 1rem;
`;

const ToggleButton = styled.button`
  background: none;
  border: none;
  color: #0d6efd;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  
  &:hover {
    text-decoration: underline;
  }
`;

const SearchInfo = styled.div`
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);

  .search-term {
    font-weight: 500;
    color: #0d6efd;
  }

  .clear-search {
    color: #dc3545;
    cursor: pointer;
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    
    &:hover {
      background-color: #fff;
      text-decoration: underline;
    }
  }
`;

const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.05);

  .header-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;  /* Increased gap between title and count */

    h5 {
      margin: 0;
      font-size: 1.1rem;
      font-weight: 500;
      color: #2c3e50;  /* Darker text for better contrast */
    }

    .result-count {
      position: relative;
      color: #6c757d;
      font-size: 0.875rem;
      font-weight: normal;
      padding-left: 1.5rem;  /* Added padding for the separator */
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 15px;
        width: 1px;
        background-color: #dee2e6;  /* Light separator color */
      }
    }
  }

  .sort-section {
    select {
      min-width: 200px;
      border-color: #dee2e6;
      background-color: white;
      
      &:focus {
        border-color: #adb5bd;
        box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.25);
      }
    }
  }

  background: linear-gradient(to bottom, #ffffff, #f8f9fa);  /* Subtle gradient */
  border: 1px solid #e9ecef;  /* Light border */
`;
const ClearButton = styled.button`
    color: #0d6efd;
    text-decoration: underline;
    border: none;
    background: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    
    &:hover {
        color: #0a58ca;
    }
`;


const BusinessList = () => {
  const [matchingProducts, setMatchingProducts] = useState([]);
  const [allBusinesses, setAllBusinesses] = useState([]);
  const [visibleBusinesses, setVisibleBusinesses] = useState([]);
  const [filter, setFilter] = useState('');
  const [sort, setSort] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [ratingFilter, setRatingFilter] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [userLocation, setUserLocation] = useState(null);
  const { categories, cities } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const itemsPerPage = 12;
  const [visibleCount, setVisibleCount] = useState(itemsPerPage);
  const [filteredCount, setFilteredCount] = useState(0);

  const keyword = searchParams.get('keyword') || '';
  const category = searchParams.get('category') || '';

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
        },
        (error) => {
          setUserLocation('144.9631 -37.8136');  // Default location
        }
      );
    } else {
      setUserLocation('144.9631 -37.8136');  // Default location if geolocation is unsupported
    }
  }, []);


  useEffect(() => {
    // In the fetchBusinesses function, update the data processing:
    const fetchBusinesses = async () => {
      setIsLoading(true);
      try {
        const params = new URLSearchParams();
        if (category) params.append('category', category);
        if (keyword) params.append('keyword', keyword);
        if (userLocation) params.append('location', userLocation);
        if (ratingFilter.length > 0) {
          const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
          params.append('rating', minRating);
        }
    
        const response = await fetch(`/api/business_list/?${params.toString()}`);
        const data = await response.json();
        const businessFeatures = data?.features?.features || [];
        const businessesData = businessFeatures.map(feature => ({
          ...feature.properties,
          id: feature.id,
          geometry: feature.geometry,
          business_hours: feature.properties.business_hours || [],
          physical_products: feature.properties.physical_products || [],
          service_products: feature.properties.service_products || [],
          distance: feature.properties.distance
        }));
    
        // Enhanced product and category matching
        if (keyword || category) {
          const allMatchingProducts = businessesData.flatMap(business => {
            const matchingProducts = [];
            
            // Function to check if a product matches the search criteria
            const productMatches = (product) => {
              let keywordMatch = true;
              let categoryMatch = true;
              
              if (keyword) {
                const searchTerms = [
                  product.name,
                  product.description,
                  product.category_name,
                  business.title,
                  business.description,
                  business.tagline
                ].map(term => term?.toLowerCase() || '');
                
                keywordMatch = searchTerms.some(term => 
                  term.includes(keyword.toLowerCase())
                );
              }
              
              if (category) {
                // Make category matching more flexible
                categoryMatch = product.category_name.toLowerCase().includes(category.toLowerCase()) ||
                              business.categories?.some(cat => 
                                cat.name.toLowerCase().includes(category.toLowerCase())
                              );
              }
              
              return keywordMatch && categoryMatch;
            };
            
            // Check physical products
            const physicalProducts = business.physical_products
              .filter(productMatches)
              .map(product => ({
                ...product,
                businessName: business.title,
                businessSlug: business.slug,
                businessId: business.id,
                type: 'physical'
              }));
            
            // Check service products
            const serviceProducts = business.service_products
              .filter(productMatches)
              .map(product => ({
                ...product,
                businessName: business.title,
                businessSlug: business.slug,
                businessId: business.id,
                type: 'service'
              }));
    
            matchingProducts.push(...physicalProducts, ...serviceProducts);
            return matchingProducts;
          });
    
          setMatchingProducts(allMatchingProducts.slice(0, 10));
        } else {
          setMatchingProducts([]);
        }
    
        setAllBusinesses(businessesData);
        setVisibleBusinesses(businessesData.slice(0, itemsPerPage));
        setHasMore(businessesData.length > itemsPerPage);
    
      } catch (error) {
        console.error("Error fetching businesses:", error);
        setAllBusinesses([]);
        setVisibleBusinesses([]);
        setHasMore(false);
      } finally {
        setIsLoading(false);
      }
    };
    
    fetchBusinesses();
  }, [searchParams, category, keyword, userLocation, ratingFilter]);


  const handleLoadMore = () => {
    const newVisibleCount = visibleCount + itemsPerPage;
    setVisibleBusinesses(allBusinesses.slice(0, newVisibleCount));
    setVisibleCount(newVisibleCount);
    setHasMore(newVisibleCount < allBusinesses.length);
  };

  const handleSearch = ({ category, keyword }) => {
    setSearchParams({ category, keyword });
    setVisibleCount(itemsPerPage);
  };

  useEffect(() => {
    const filteredBusinesses = allBusinesses.filter(business => {
      let matchesFilter = true;

      if (filter) {
        const category = categories.find(cat => cat.name.toLowerCase() === filter.toLowerCase());
        matchesFilter = matchesFilter && business.category === category?.id;
      }

      if (cityFilter) {
        const city = cities.find(c => c.name.toLowerCase() === cityFilter.toLowerCase());
        matchesFilter = matchesFilter && business.city === city?.id;
      }

      if (ratingFilter.length > 0) {
        const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
        matchesFilter = matchesFilter && business.average_rating >= minRating;
      }

      if (category) {
        const searchCategory = categories.find(cat => cat.name.toLowerCase() === category.toLowerCase());
        matchesFilter = matchesFilter && business.category === searchCategory?.id;
      }

      if (keyword) {
        const lowerKeyword = keyword.toLowerCase();
        matchesFilter = matchesFilter && (
          business.is_product_match ||
          (business.title && business.title.toLowerCase().includes(lowerKeyword)) ||
          (business.description && business.description.toLowerCase().includes(lowerKeyword)) ||
          (business.tagline && business.tagline.toLowerCase().includes(lowerKeyword))
        );
      }

      return matchesFilter;
    });

    console.log("Filtered Businesses:", filteredBusinesses);

    const sortedBusinesses = filteredBusinesses.sort((a, b) => {
      if (keyword) {
        if (a.is_product_match && !b.is_product_match) return -1;
        if (!a.is_product_match && b.is_product_match) return 1;
      }

      if (sort === 'distance_asc') {
        return (a.distance || Infinity) - (b.distance || Infinity);
      } else if (sort === 'distance_desc') {
        return (b.distance || -Infinity) - (a.distance || -Infinity);
      } else if (sort === 'asc') {
        return a.title.localeCompare(b.title);
      } else if (sort === 'desc') {
        return b.title.localeCompare(a.title);
      }
      return 0;
    });

    setFilteredCount(filteredBusinesses.length);
    setVisibleBusinesses(sortedBusinesses.slice(0, visibleCount));
    setHasMore(visibleCount < sortedBusinesses.length);
  }, [allBusinesses, filter, cityFilter, ratingFilter, sort, keyword, category, visibleCount, categories, cities]);

  const handleFilterChange = (e) => {
    const [type, value] = e.target.value.split(':');
    switch (type) {
      case 'category':
        setFilter(value);
        break;
      case 'city':
        setCityFilter(value);
        break;
      case 'rating':
        setRatingFilter(prevRating => {
          if (prevRating.includes(value)) {
            return prevRating.filter(r => r !== value);
          } else {
            return [...prevRating, value];
          }
        });
        break;
      default:
        setSort(e.target.value);
    }
    setVisibleCount(itemsPerPage);
  };

  const handleClearFilters = () => {
    setFilter('');
    setCityFilter('');
    setRatingFilter([]);
    setSort('');
    setVisibleCount(itemsPerPage);
  };



  const MatchingProductsNotification = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    
    if (!matchingProducts.length) return null;
  
    // Filter matching products based on current filters
    const filteredProducts = matchingProducts.filter(product => {
      let matchesFilter = true;
      const business = allBusinesses.find(b => b.id === product.businessId);
      
      if (!business) return false;

      // Apply business-level filters
      if (filter) {
        const category = categories.find(cat => cat.name.toLowerCase() === filter.toLowerCase());
        matchesFilter = matchesFilter && business.category === category?.id;
      }

      if (cityFilter) {
        const city = cities.find(c => c.name.toLowerCase() === cityFilter.toLowerCase());
        matchesFilter = matchesFilter && business.city === city?.id;
      }

      if (ratingFilter.length > 0) {
        const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
        matchesFilter = matchesFilter && business.average_rating >= minRating;
      }

      return matchesFilter;
    });

    // Sort the filtered products
    const sortedProducts = [...filteredProducts].sort((a, b) => {
      const businessA = allBusinesses.find(business => business.id === a.businessId);
      const businessB = allBusinesses.find(business => business.id === b.businessId);

      if (sort === 'distance_asc') {
        return (businessA?.distance || Infinity) - (businessB?.distance || Infinity);
      } else if (sort === 'distance_desc') {
        return (businessB?.distance || -Infinity) - (businessA?.distance || -Infinity);
      } else if (sort === 'asc') {
        return a.businessName.localeCompare(b.businessName);
      } else if (sort === 'desc') {
        return b.businessName.localeCompare(a.businessName);
      }
      return 0;
    });

    // Group filtered and sorted products by business
    const productsByBusiness = sortedProducts.reduce((acc, product) => {
      if (!acc[product.businessId]) {
        acc[product.businessId] = {
          businessName: product.businessName,
          businessSlug: product.businessSlug,
          products: []
        };
      }
      acc[product.businessId].products.push(product);
      return acc;
    }, {});
  
    const businessCount = Object.keys(productsByBusiness).length;
    const totalProducts = sortedProducts.length;

    if (totalProducts === 0) return null;
  
    return (
      <MatchingProductsContainer isExpanded={isExpanded}>
        <div className="p-3 border-bottom">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="mb-0">Matching Products Found:</h6>
            <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? (
                <>
                  <i className="bi bi-chevron-up me-1"></i>
                  Show Less
                </>
              ) : (
                <>
                  <i className="bi bi-chevron-down me-1"></i>
                  Show More
                </>
              )}
            </ToggleButton>
          </div>
        </div>
        
        <ScrollableContent isExpanded={isExpanded}>
          {Object.entries(productsByBusiness).map(([businessId, business]) => (
            <BusinessSection key={businessId}>
              <BusinessLink to={`/business/${business.businessSlug}`}>
                {business.businessName}
              </BusinessLink>
              {business.products.map((product, index) => (
                <ProductItem key={index}>
                  <span className="badge bg-light text-dark me-2">
                    {product.type === 'physical' ? 'Product' : 'Service'}
                  </span>
                  <span>
                    {product.name} - {product.category_name}
                    <span className="ms-2">
                      {product.sale_price ? (
                        <span className="text-danger">
                          ${product.sale_price} {' '}
                          <del className="text-muted">${product.original_price}</del>
                        </span>
                      ) : (
                        <span>${product.original_price}</span>
                      )}
                    </span>
                  </span>
                </ProductItem>
              ))}
            </BusinessSection>
          ))}
        </ScrollableContent>
        
        <div className="p-3 border-top bg-light">
          <div className="d-flex justify-content-between align-items-center">
            <small className="fw-bold">
              Found {totalProducts} matching products in {businessCount} businesses
            </small>
            {totalProducts > 0 && (
              <small className="text-muted">
                Scroll to see more products...
              </small>
            )}
          </div>
        </div>
      </MatchingProductsContainer>
    );
  };
  const handleClearSearch = () => {
    setSearchParams({});
    handleClearFilters();
  };



  const renderSearchInfo = () => {
    // Return null if no filters or search terms are active
    if (!keyword && !category && !filter && !cityFilter) {
        return (
            <div className="alert alert-info text-center">
                <p>Showing all listings</p>
            </div>
        );
    }

    const searchTerms = [];
    if (keyword) searchTerms.push(`"${keyword}"`);
    if (category) searchTerms.push(`in ${category}`);
    if (filter) searchTerms.push(`category: ${filter}`);
    if (cityFilter) searchTerms.push(`city: ${cityFilter}`);

    return (
        <SearchInfo>
            <div>
                Showing results for <span className="search-term">{searchTerms.join(' ')}</span>
            </div>
            <span className="clear-search" onClick={handleClearSearch}>
                Clear search
            </span>
        </SearchInfo>
    );
  };

  const isSortedByDistance = sort === 'distance_asc' || sort === 'distance_desc';

  return (
    <div data-new-title="Business Listings | Your Site Title">
      <section id="banner" className="parallex-bg section-padding">
        <div className="container">
          <div className="white-text text-center div_zindex">
            <h1>Business Listings</h1>
            <Search categories={categories} onSearch={handleSearch} />
          </div>
        </div>
        <div className="dark-overlay"></div>
      </section>

      <section id="inner_pages">
        <div className="container content-space-1">
          <div className="row">
            <div className="col-lg-3">
              <div className="sticky-below-nav">
                <div className="navbar-expand-lg mb-5">
                  <div className="d-grid">
                    <button
                      type="button"
                      className="navbar-toggler btn btn-white mb-3"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarVerticalNavMenuEg2"
                      aria-label="Toggle navigation"
                      aria-expanded="false"
                      aria-controls="navbarVerticalNavMenuEg2"
                    >
                      <span className="d-flex justify-content-between align-items-center">
                        <span className="text-dark">Filter</span>
                        <span className="navbar-toggler-default">
                          <i className="bi-list"></i>
                        </span>
                        <span className="navbar-toggler-toggled">
                          <i className="bi-x"></i>
                        </span>
                      </span>
                    </button>
                  </div>

                  <div id="navbarVerticalNavMenuEg2" className="collapse navbar-collapse show">
                    <div className="w-100">
                      <Filters
                        categories={categories}
                        cities={[...new Set(allBusinesses.map(business => business.city).filter(city => city))]}
                        onFilterChange={handleFilterChange}
                        onClearFilters={handleClearFilters}
                        selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9">
                {renderSearchInfo()}
                <MatchingProductsNotification />
                <div className="sticky-below-nav sticky-sort-dropdown">
                    <ListHeader>
                        <div className="header-content">
                            <h5>Business Listings</h5>
                            {visibleBusinesses.length > 0 && (
                              <span className="result-count">
                                  {filteredCount} results found
                              </span>
                          )}
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="mb-2 mb-sm-0 me-sm-2">
                                <select
                                    className="form-select form-select-sm"
                                    onChange={handleFilterChange}
                                    value={sort}
                                >
                                    <option value="">Sort by</option>
                                    <option value="asc">A-Z (asc order)</option>
                                    <option value="desc">Z-A (desc order)</option>
                                    <option value="distance_asc">Location (nearest to farthest)</option>
                                    <option value="distance_desc">Location (farthest to nearest)</option>
                                </select>
                            </div>
                        </div>
                    </ListHeader>
                </div>

                {isLoading && visibleCount === itemsPerPage ? (
                    <Spinner />
                ) : visibleBusinesses.length > 0 ? (
                    <>
                        <div className="row">
                            {visibleBusinesses.map(business => (
                                <BusinessListingCard
                                    key={business.id}
                                    business={business}
                                    isSortedByDistance={isSortedByDistance}
                                />
                            ))}
                        </div>
                        <LoadMore 
                            isLoading={isLoading} 
                            hasMore={hasMore} 
                            onLoadMore={handleLoadMore} 
                        />
                    </>
                ) : (
                    <div className="alert alert-info text-center">
                        <p>Sorry, there are no businesses to show at the moment.</p>
                        {(keyword || category || filter || cityFilter) && (
                            <ClearButton onClick={handleClearSearch}>
                                Clear all filters
                            </ClearButton>
                        )}
                    </div>
                )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BusinessList;



// import React, { useState, useEffect } from 'react';
// import { useSearchParams } from 'react-router-dom';
// import Search from '../common/Search';
// import Filters from '../layout/Filters';
// import { useUser } from '../../context/UserContext';
// import BusinessListingCard from './BusinessListingCard';
// import Spinner from '../common/Spinner';
// import LoadMore from '../common/LoadMore';
// import { Link } from 'react-router-dom';
// import styled from 'styled-components';


// // Styled components for the matching products section
// const MatchingProductsContainer = styled.div`
//   background-color: #d1e7dd;
//   border-radius: 0.375rem;
//   margin-bottom: 1.5rem;
//   max-height: ${props => props.isExpanded ? '600px' : '300px'};
//   transition: max-height 0.3s ease-in-out;
// `;

// const ScrollableContent = styled.div`
//   max-height: ${props => props.isExpanded ? '500px' : '200px'};
//   overflow-y: auto;
//   padding: 1rem;
  
//   &::-webkit-scrollbar {
//     width: 8px;
//   }
  
//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//     border-radius: 4px;
//   }
  
//   &::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 4px;
//   }
  
//   &::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }
// `;

// const BusinessSection = styled.div`
//   margin-bottom: 1rem;
//   padding-bottom: 1rem;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
//   &:last-child {
//     border-bottom: none;
//   }
// `;

// const BusinessLink = styled(Link)`
//   color: #009fe3;
//   text-decoration: none;
//   font-weight: bold;
//   font-size: 1.1rem;
//   display: block;
//   margin-bottom: 0.5rem;
  
//   &:hover {
//     color: #0a58ca;
//     text-decoration: underline;
//   }
// `;

// const ProductItem = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0.25rem 0;
//   margin-left: 1rem;
// `;

// const ToggleButton = styled.button`
//   background: none;
//   border: none;
//   color: #0d6efd;
//   padding: 0.5rem;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   font-size: 0.875rem;
  
//   &:hover {
//     text-decoration: underline;
//   }
// `;

// const SearchInfo = styled.div`
//   padding: 0.75rem 1rem;
//   margin-bottom: 1rem;
//   background-color: #f8f9fa;
//   border-radius: 0.25rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   box-shadow: 0 1px 3px rgba(0,0,0,0.1);

//   .search-term {
//     font-weight: 500;
//     color: #0d6efd;
//   }

//   .clear-search {
//     color: #dc3545;
//     cursor: pointer;
//     font-size: 0.875rem;
//     padding: 0.25rem 0.5rem;
//     border-radius: 0.25rem;
    
//     &:hover {
//       background-color: #fff;
//       text-decoration: underline;
//     }
//   }
// `;

// const ListHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-bottom: 1rem;
//   width: 100%;
//   padding: 1rem;
//   background-color: #f8f9fa;
//   border-radius: 0.25rem;
//   box-shadow: 0 1px 3px rgba(0,0,0,0.05);

//   .header-content {
//     display: flex;
//     align-items: center;
//     gap: 1.5rem;  /* Increased gap between title and count */

//     h5 {
//       margin: 0;
//       font-size: 1.1rem;
//       font-weight: 500;
//       color: #2c3e50;  /* Darker text for better contrast */
//     }

//     .result-count {
//       position: relative;
//       color: #6c757d;
//       font-size: 0.875rem;
//       font-weight: normal;
//       padding-left: 1.5rem;  /* Added padding for the separator */
      
//       &::before {
//         content: '';
//         position: absolute;
//         left: 0;
//         top: 50%;
//         transform: translateY(-50%);
//         height: 15px;
//         width: 1px;
//         background-color: #dee2e6;  /* Light separator color */
//       }
//     }
//   }

//   .sort-section {
//     select {
//       min-width: 200px;
//       border-color: #dee2e6;
//       background-color: white;
      
//       &:focus {
//         border-color: #adb5bd;
//         box-shadow: 0 0 0 0.2rem rgba(222,226,230,0.25);
//       }
//     }
//   }

//   background: linear-gradient(to bottom, #ffffff, #f8f9fa);  /* Subtle gradient */
//   border: 1px solid #e9ecef;  /* Light border */
// `;
// const ClearButton = styled.button`
//     color: #0d6efd;
//     text-decoration: underline;
//     border: none;
//     background: none;
//     padding: 0.5rem 1rem;
//     cursor: pointer;
    
//     &:hover {
//         color: #0a58ca;
//     }
// `;


// const BusinessList = () => {
//   const [matchingProducts, setMatchingProducts] = useState([]);
//   const [allBusinesses, setAllBusinesses] = useState([]);
//   const [visibleBusinesses, setVisibleBusinesses] = useState([]);
//   const [filter, setFilter] = useState('');
//   const [sort, setSort] = useState('');
//   const [cityFilter, setCityFilter] = useState('');
//   const [ratingFilter, setRatingFilter] = useState([]);
//   const [searchParams, setSearchParams] = useSearchParams();
//   const [userLocation, setUserLocation] = useState(null);
//   const { categories, cities } = useUser();
//   const [isLoading, setIsLoading] = useState(true);
//   const [hasMore, setHasMore] = useState(true);
//   const itemsPerPage = 12;
//   const [visibleCount, setVisibleCount] = useState(itemsPerPage);

//   const keyword = searchParams.get('keyword') || '';
//   const category = searchParams.get('category') || '';

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           setUserLocation(`${position.coords.longitude} ${position.coords.latitude}`);
//         },
//         (error) => {
//           setUserLocation('144.9631 -37.8136');  // Default location
//         }
//       );
//     } else {
//       setUserLocation('144.9631 -37.8136');  // Default location if geolocation is unsupported
//     }
//   }, []);


//   useEffect(() => {
//     // In the fetchBusinesses function, update the data processing:
//     const fetchBusinesses = async () => {
//       setIsLoading(true);
//       try {
//         const params = new URLSearchParams();
//         if (category) params.append('category', category);
//         if (keyword) params.append('keyword', keyword);
//         if (userLocation) params.append('location', userLocation);
//         if (ratingFilter.length > 0) {
//           const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
//           params.append('rating', minRating);
//         }
    
//         const response = await fetch(`/api/business_list/?${params.toString()}`);
//         const data = await response.json();
//         const businessFeatures = data?.features?.features || [];
//         const businessesData = businessFeatures.map(feature => ({
//           ...feature.properties,
//           id: feature.id,
//           geometry: feature.geometry,
//           business_hours: feature.properties.business_hours || [],
//           physical_products: feature.properties.physical_products || [],
//           service_products: feature.properties.service_products || [],
//           distance: feature.properties.distance
//         }));
    
//         // Enhanced product and category matching
//         if (keyword || category) {
//           const allMatchingProducts = businessesData.flatMap(business => {
//             const matchingProducts = [];
            
//             // Function to check if a product matches the search criteria
//             const productMatches = (product) => {
//               let keywordMatch = true;
//               let categoryMatch = true;
              
//               if (keyword) {
//                 const searchTerms = [
//                   product.name,
//                   product.description,
//                   product.category_name,
//                   business.title,
//                   business.description,
//                   business.tagline
//                 ].map(term => term?.toLowerCase() || '');
                
//                 keywordMatch = searchTerms.some(term => 
//                   term.includes(keyword.toLowerCase())
//                 );
//               }
              
//               if (category) {
//                 // Make category matching more flexible
//                 categoryMatch = product.category_name.toLowerCase().includes(category.toLowerCase()) ||
//                               business.categories?.some(cat => 
//                                 cat.name.toLowerCase().includes(category.toLowerCase())
//                               );
//               }
              
//               return keywordMatch && categoryMatch;
//             };
            
//             // Check physical products
//             const physicalProducts = business.physical_products
//               .filter(productMatches)
//               .map(product => ({
//                 ...product,
//                 businessName: business.title,
//                 businessSlug: business.slug,
//                 businessId: business.id,
//                 type: 'physical'
//               }));
            
//             // Check service products
//             const serviceProducts = business.service_products
//               .filter(productMatches)
//               .map(product => ({
//                 ...product,
//                 businessName: business.title,
//                 businessSlug: business.slug,
//                 businessId: business.id,
//                 type: 'service'
//               }));
    
//             matchingProducts.push(...physicalProducts, ...serviceProducts);
//             return matchingProducts;
//           });
    
//           setMatchingProducts(allMatchingProducts.slice(0, 10));
//         } else {
//           setMatchingProducts([]);
//         }
    
//         setAllBusinesses(businessesData);
//         setVisibleBusinesses(businessesData.slice(0, itemsPerPage));
//         setHasMore(businessesData.length > itemsPerPage);
    
//       } catch (error) {
//         console.error("Error fetching businesses:", error);
//         setAllBusinesses([]);
//         setVisibleBusinesses([]);
//         setHasMore(false);
//       } finally {
//         setIsLoading(false);
//       }
//     };
    
//     fetchBusinesses();
//   }, [searchParams, category, keyword, userLocation, ratingFilter]);


//   const handleLoadMore = () => {
//     const newVisibleCount = visibleCount + itemsPerPage;
//     setVisibleBusinesses(allBusinesses.slice(0, newVisibleCount));
//     setVisibleCount(newVisibleCount);
//     setHasMore(newVisibleCount < allBusinesses.length);
//   };

//   const handleSearch = ({ category, keyword }) => {
//     setSearchParams({ category, keyword });
//     setVisibleCount(itemsPerPage);
//   };

//   useEffect(() => {
//     const filteredBusinesses = allBusinesses.filter(business => {
//       let matchesFilter = true;

//       if (filter) {
//         const category = categories.find(cat => cat.name.toLowerCase() === filter.toLowerCase());
//         matchesFilter = matchesFilter && business.category === category?.id;
//       }

//       if (cityFilter) {
//         const city = cities.find(c => c.name.toLowerCase() === cityFilter.toLowerCase());
//         matchesFilter = matchesFilter && business.city === city?.id;
//       }

//       if (ratingFilter.length > 0) {
//         const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
//         matchesFilter = matchesFilter && business.average_rating >= minRating;
//       }

//       if (category) {
//         const searchCategory = categories.find(cat => cat.name.toLowerCase() === category.toLowerCase());
//         matchesFilter = matchesFilter && business.category === searchCategory?.id;
//       }

//       if (keyword) {
//         const lowerKeyword = keyword.toLowerCase();
//         matchesFilter = matchesFilter && (
//           business.is_product_match ||
//           (business.title && business.title.toLowerCase().includes(lowerKeyword)) ||
//           (business.description && business.description.toLowerCase().includes(lowerKeyword)) ||
//           (business.tagline && business.tagline.toLowerCase().includes(lowerKeyword))
//         );
//       }

//       return matchesFilter;
//     });

//     console.log("Filtered Businesses:", filteredBusinesses);

//     const sortedBusinesses = filteredBusinesses.sort((a, b) => {
//       if (keyword) {
//         if (a.is_product_match && !b.is_product_match) return -1;
//         if (!a.is_product_match && b.is_product_match) return 1;
//       }

//       if (sort === 'distance_asc') {
//         return (a.distance || Infinity) - (b.distance || Infinity);
//       } else if (sort === 'distance_desc') {
//         return (b.distance || -Infinity) - (a.distance || -Infinity);
//       } else if (sort === 'asc') {
//         return a.title.localeCompare(b.title);
//       } else if (sort === 'desc') {
//         return b.title.localeCompare(a.title);
//       }
//       return 0;
//     });

//     setVisibleBusinesses(sortedBusinesses.slice(0, visibleCount));
//     setHasMore(visibleCount < sortedBusinesses.length);
//   }, [allBusinesses, filter, cityFilter, ratingFilter, sort, keyword, category, visibleCount, categories, cities]);

//   const handleFilterChange = (e) => {
//     const [type, value] = e.target.value.split(':');
//     switch (type) {
//       case 'category':
//         setFilter(value);
//         break;
//       case 'city':
//         setCityFilter(value);
//         break;
//       case 'rating':
//         setRatingFilter(prevRating => {
//           if (prevRating.includes(value)) {
//             return prevRating.filter(r => r !== value);
//           } else {
//             return [...prevRating, value];
//           }
//         });
//         break;
//       default:
//         setSort(e.target.value);
//     }
//     setVisibleCount(itemsPerPage);
//   };

//   const handleClearFilters = () => {
//     setFilter('');
//     setCityFilter('');
//     setRatingFilter([]);
//     setSort('');
//     setVisibleCount(itemsPerPage);
//   };



//   const MatchingProductsNotification = () => {
//     const [isExpanded, setIsExpanded] = useState(false);
    
//     if (!matchingProducts.length) return null;
  
//     // Filter matching products based on current filters
//     const filteredProducts = matchingProducts.filter(product => {
//       let matchesFilter = true;
//       const business = allBusinesses.find(b => b.id === product.businessId);
      
//       if (!business) return false;

//       // Apply business-level filters
//       if (filter) {
//         const category = categories.find(cat => cat.name.toLowerCase() === filter.toLowerCase());
//         matchesFilter = matchesFilter && business.category === category?.id;
//       }

//       if (cityFilter) {
//         const city = cities.find(c => c.name.toLowerCase() === cityFilter.toLowerCase());
//         matchesFilter = matchesFilter && business.city === city?.id;
//       }

//       if (ratingFilter.length > 0) {
//         const minRating = Math.min(...ratingFilter.map(r => parseInt(r)));
//         matchesFilter = matchesFilter && business.average_rating >= minRating;
//       }

//       return matchesFilter;
//     });

//     // Sort the filtered products
//     const sortedProducts = [...filteredProducts].sort((a, b) => {
//       const businessA = allBusinesses.find(business => business.id === a.businessId);
//       const businessB = allBusinesses.find(business => business.id === b.businessId);

//       if (sort === 'distance_asc') {
//         return (businessA?.distance || Infinity) - (businessB?.distance || Infinity);
//       } else if (sort === 'distance_desc') {
//         return (businessB?.distance || -Infinity) - (businessA?.distance || -Infinity);
//       } else if (sort === 'asc') {
//         return a.businessName.localeCompare(b.businessName);
//       } else if (sort === 'desc') {
//         return b.businessName.localeCompare(a.businessName);
//       }
//       return 0;
//     });

//     // Group filtered and sorted products by business
//     const productsByBusiness = sortedProducts.reduce((acc, product) => {
//       if (!acc[product.businessId]) {
//         acc[product.businessId] = {
//           businessName: product.businessName,
//           businessSlug: product.businessSlug,
//           products: []
//         };
//       }
//       acc[product.businessId].products.push(product);
//       return acc;
//     }, {});
  
//     const businessCount = Object.keys(productsByBusiness).length;
//     const totalProducts = sortedProducts.length;

//     if (totalProducts === 0) return null;
  
//     return (
//       <MatchingProductsContainer isExpanded={isExpanded}>
//         <div className="p-3 border-bottom">
//           <div className="d-flex justify-content-between align-items-center">
//             <h6 className="mb-0">Matching Products Found:</h6>
//             <ToggleButton onClick={() => setIsExpanded(!isExpanded)}>
//               {isExpanded ? (
//                 <>
//                   <i className="bi bi-chevron-up me-1"></i>
//                   Show Less
//                 </>
//               ) : (
//                 <>
//                   <i className="bi bi-chevron-down me-1"></i>
//                   Show More
//                 </>
//               )}
//             </ToggleButton>
//           </div>
//         </div>
        
//         <ScrollableContent isExpanded={isExpanded}>
//           {Object.entries(productsByBusiness).map(([businessId, business]) => (
//             <BusinessSection key={businessId}>
//               <BusinessLink to={`/business/${business.businessSlug}`}>
//                 {business.businessName}
//               </BusinessLink>
//               {business.products.map((product, index) => (
//                 <ProductItem key={index}>
//                   <span className="badge bg-light text-dark me-2">
//                     {product.type === 'physical' ? 'Product' : 'Service'}
//                   </span>
//                   <span>
//                     {product.name} - {product.category_name}
//                     <span className="ms-2">
//                       {product.sale_price ? (
//                         <span className="text-danger">
//                           ${product.sale_price} {' '}
//                           <del className="text-muted">${product.original_price}</del>
//                         </span>
//                       ) : (
//                         <span>${product.original_price}</span>
//                       )}
//                     </span>
//                   </span>
//                 </ProductItem>
//               ))}
//             </BusinessSection>
//           ))}
//         </ScrollableContent>
        
//         <div className="p-3 border-top bg-light">
//           <div className="d-flex justify-content-between align-items-center">
//             <small className="fw-bold">
//               Found {totalProducts} matching products in {businessCount} businesses
//             </small>
//             {totalProducts > 0 && (
//               <small className="text-muted">
//                 Scroll to see more products...
//               </small>
//             )}
//           </div>
//         </div>
//       </MatchingProductsContainer>
//     );
//   };

//   const renderSearchInfo = () => {
//     if (!keyword && !category) {
//         return null;
//     }

//     const searchTerms = [];
//     if (keyword) searchTerms.push(`"${keyword}"`);
//     if (category) searchTerms.push(`in ${category}`);

//     return (
//         <SearchInfo>
//             <div>
//                 Showing results for <span className="search-term">{searchTerms.join(' ')}</span>
//             </div>
//             <span className="clear-search" onClick={handleClearFilters}>
//                 Clear search
//             </span>
//         </SearchInfo>
//     );
// };

//   const isSortedByDistance = sort === 'distance_asc' || sort === 'distance_desc';

//   return (
//     <div data-new-title="Business Listings | Your Site Title">
//       <section id="banner" className="parallex-bg section-padding">
//         <div className="container">
//           <div className="white-text text-center div_zindex">
//             <h1>Business Listings</h1>
//             <Search categories={categories} onSearch={handleSearch} />
//           </div>
//         </div>
//         <div className="dark-overlay"></div>
//       </section>

//       <section id="inner_pages">
//         <div className="container content-space-1">
//           <div className="row">
//             <div className="col-lg-3">
//               <div className="sticky-below-nav">
//                 <div className="navbar-expand-lg mb-5">
//                   <div className="d-grid">
//                     <button
//                       type="button"
//                       className="navbar-toggler btn btn-white mb-3"
//                       data-bs-toggle="collapse"
//                       data-bs-target="#navbarVerticalNavMenuEg2"
//                       aria-label="Toggle navigation"
//                       aria-expanded="false"
//                       aria-controls="navbarVerticalNavMenuEg2"
//                     >
//                       <span className="d-flex justify-content-between align-items-center">
//                         <span className="text-dark">Filter</span>
//                         <span className="navbar-toggler-default">
//                           <i className="bi-list"></i>
//                         </span>
//                         <span className="navbar-toggler-toggled">
//                           <i className="bi-x"></i>
//                         </span>
//                       </span>
//                     </button>
//                   </div>

//                   <div id="navbarVerticalNavMenuEg2" className="collapse navbar-collapse show">
//                     <div className="w-100">
//                       <Filters
//                         categories={categories}
//                         cities={[...new Set(allBusinesses.map(business => business.city).filter(city => city))]}
//                         onFilterChange={handleFilterChange}
//                         onClearFilters={handleClearFilters}
//                         selectedFilters={{ category: filter, city: cityFilter, rating: ratingFilter }}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="col-lg-9">
//             {renderSearchInfo()}
//             {keyword || category ? (
//                 <div className="alert alert-info text-center">
//                     <p>Your search results for {keyword && `"${keyword}"`} {category && `in category "${category}"`}:</p>
//                 </div>
//             ) : (
//                 <div className="alert alert-info text-center">
//                     <p>Showing all listings:</p>
//                 </div>
//             )}
//               <MatchingProductsNotification />
//               <div className="sticky-below-nav sticky-sort-dropdown">
//                 {/* <div className="listing_header d-flex justify-content-between align-items-center mb-4 bg-light p-3 rounded">
//                   <h5>Business Listings</h5>
//                   <div className="d-flex align-items-center">
//                     <div className="mb-2 mb-sm-0 me-sm-2">
//                       <select
//                         className="form-select form-select-sm"
//                         onChange={handleFilterChange}
//                         value={sort}
//                       >
//                         <option value="">Sort by</option>
//                         <option value="asc">A-Z (asc order)</option>
//                         <option value="desc">Z-A (desc order)</option>
//                         <option value="distance_asc">Location (nearest to farthest)</option>
//                         <option value="distance_desc">Location (farthest to nearest)</option>
//                       </select>
//                     </div>
//                   </div>
//                 </div> */}
//                 <ListHeader>
//                   <div className="header-content">
//                       <h5>Business Listings</h5>
//                       {visibleBusinesses.length > 0 && (
//                           <span className="result-count">
//                               {allBusinesses.length} results found
//                           </span>
//                       )}
//                   </div>
//                   <div className="d-flex align-items-center">
//                       <div className="mb-2 mb-sm-0 me-sm-2">
//                           <select
//                               className="form-select form-select-sm"
//                               onChange={handleFilterChange}
//                               value={sort}
//                           >
//                               <option value="">Sort by</option>
//                               <option value="asc">A-Z (asc order)</option>
//                               <option value="desc">Z-A (desc order)</option>
//                               <option value="distance_asc">Location (nearest to farthest)</option>
//                               <option value="distance_desc">Location (farthest to nearest)</option>
//                           </select>
//                       </div>
//                   </div>
//               </ListHeader>
//               </div>

//               {isLoading && visibleCount === itemsPerPage ? (
//                 <Spinner />
//               ) : visibleBusinesses.length > 0 ? (
//                 <>
//                   <div className="row">
//                     {visibleBusinesses.map(business => (
//                       <BusinessListingCard
//                         key={business.id}
//                         business={business}
//                         isSortedByDistance={isSortedByDistance}
//                       />
//                     ))}
//                   </div>
//                   <LoadMore isLoading={isLoading} hasMore={hasMore} onLoadMore={handleLoadMore} />
//                 </>
//               ) : (
//                 <div className="alert alert-info text-center">
//                     <p>Sorry, there are no businesses to show at the moment.</p>
//                     {(keyword || category || filter || cityFilter) && (
//                         <ClearButton onClick={handleClearFilters}>
//                             Clear all filters
//                         </ClearButton>
//                     )}
//                 </div>
//             )}
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default BusinessList;




