import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { FaBriefcase, FaCar, FaCogs, FaTag, FaHome, FaHeart, FaShoppingCart, FaUtensils } from 'react-icons/fa';
import { Link } from 'react-router-dom';

// Existing styled components remain the same...
const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  overflow: hidden;
`;
// Add new styled component for the Link
const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  
  &:hover {
    text-decoration: none;
  }
`;

const Heading = styled.h2`
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: 'Roboto', sans-serif;
`;

const SliderWrapper = styled.div`
  display: flex;
  transition: transform 0.5s ease;
  transform: translateX(-${props => props.currentSlide * 100}%);
`;

const Slide = styled.div`
  flex: 0 0 100%;
  padding: 0 1rem;
  box-sizing: border-box;

  @media (min-width: 768px) {
    flex: 0 0 50%;
  }

  @media (min-width: 1024px) {
    flex: 0 0 33.333%;
  }
`;

const Card = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  overflow: hidden;
  height: 100%;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  }
`;

const ImageContainer = styled.div`
  position: relative;
  padding-top: 66.67%; /* 3:2 Aspect Ratio */
  background: #f5f5f5;
  overflow: hidden;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TopBadge = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #FFD700;
  color: #000;
  padding: 4px 12px;
  border-radius: 4px;
  font-weight: 600;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

// New styled component for type badge
const TypeBadge = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.9);
  color: #333;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: 500;
  z-index: 2;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 0.9rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);

  svg {
    width: 16px;
    height: 16px;
  }
`;

const Content = styled.div`
  padding: 1rem;
`;

const Title = styled.h3`
  margin: 0 0 0.5rem;
  font-size: 1.1rem;
  color: #333;
`;

const Price = styled.div`
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c5282;
  margin: 0.5rem 0;
`;

const Details = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #666;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 6px rgba(0,0,0,0.2);
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;

  &:hover {
    background: #f8f9fa;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
  }

  &:disabled {
    background: #e9ecef;
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${props => props.direction === 'prev' ? 'left: 10px;' : 'right: 10px;'}
`;

// Define icon mappings for classified types
const classifiedTypeIcons = {
  Jobs: <FaBriefcase />,
  Automotive: <FaCar />,
  'Service For Hire': <FaCogs />,
  'Real Estate': <FaHome />,
  FoodAndDrink: <FaUtensils />,
  'Item For Sale': <FaShoppingCart />,
  Healthcare: <FaHeart />,
  Default: <FaTag />
};

const getClassifiedTypeIcon = (classifiedType) => {
  return classifiedTypeIcons[classifiedType] || classifiedTypeIcons.Default;
};

const FeaturedClassifiedSlider = () => {
  const [classifieds, setClassifieds] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  // Auto-slide effect
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPaused && classifieds.length > 0) {
        setCurrentSlide(current => 
          current >= Math.ceil(classifieds.length / 3) - 1 ? 0 : current + 1
        );
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [classifieds.length, isPaused]);

  useEffect(() => {
    const fetchFeaturedClassifieds = async () => {
      try {
        const response = await fetch('/api/featured-classifieds/', {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
          },
        });
        const data = await response.json();
        if (response.ok && data.success) {
          setClassifieds(data.classifieds);
        } else {
          throw new Error(data.error || 'Failed to fetch featured classifieds');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFeaturedClassifieds();
  }, []);

  const nextSlide = () => {
    setCurrentSlide(current => (current >= Math.ceil(classifieds.length / 3) - 1 ? 0 : current + 1));
  };

  const prevSlide = () => {
    setCurrentSlide(current => (current <= 0 ? Math.ceil(classifieds.length / 3) - 1 : current - 1));
  };

  if (isLoading) return <div>Loading featured classifieds...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!classifieds.length) return null;

  return (
    <SliderContainer>
      <Heading>Featured Classifieds</Heading>
      
      <div 
        onMouseEnter={() => setIsPaused(true)}
        onMouseLeave={() => setIsPaused(false)}
      >
        <NavButton direction="prev" onClick={prevSlide} disabled={currentSlide === 0}>
          <ChevronLeft size={24} />
        </NavButton>
        
        <SliderWrapper currentSlide={currentSlide}>
          {classifieds.map(classified => (
            <Slide key={classified.id}>
            <StyledLink to={`/classified/${classified.id}`}>
              <Card>
                <ImageContainer>
                  <TypeBadge>
                    {getClassifiedTypeIcon(classified.classified_type_name)}
                    <span>{classified.classified_type_name}</span>
                  </TypeBadge>
                  
                  {classified.premium_badge && (
                    <TopBadge 
                      style={{
                        backgroundColor: classified.premium_status === 'urgent' ? '#FF4444' : '#FFD700',
                        color: classified.premium_status === 'urgent' ? '#fff' : '#000'
                      }}
                    >
                      {classified.premium_badge}
                    </TopBadge>
                  )}
                  <Image 
                    src={classified.image || '/static/images/product-placeholder.png'} 
                    alt={classified.title}
                    onError={(e) => {
                      e.target.src = '/static/images/product-placeholder.png';
                    }}
                  />
                </ImageContainer>
                
                <Content>
                  <Title>{classified.title}</Title>
                  <Price>{classified.pricing_info?.formatted || `$${classified.pricing_field_value}`}</Price>
                  <Details>
                    <span>{classified.city_name}</span>
                    <span>{new Date(classified.created_at).toLocaleDateString()}</span>
                  </Details>
                </Content>
              </Card>
              </StyledLink>
            </Slide>
          ))}
        </SliderWrapper>

        <NavButton direction="next" onClick={nextSlide} disabled={currentSlide === Math.ceil(classifieds.length / 3) - 1}>
          <ChevronRight size={24} />
        </NavButton>
      </div>
    </SliderContainer>
  );
};

export default FeaturedClassifiedSlider;