import React from 'react';
import { Link } from 'react-router-dom';

const TermsAndConditions = () => {
  return (
    <section className="listing-header parallex-bg">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2>Terms and Conditions</h2>
            <p>
              Welcome to Desi Firms! These Terms and Conditions outline the rules and regulations for the use of Desi Firms by both users and vendors. By registering
              and using our platform, you agree to comply with these terms. Please read them carefully.
            </p>
            <h3>Last Updated:</h3>
            <p>[ 02 September 2024 ]</p>

            <h3>General Terms for All Users</h3>
            <ol>
              <li>
                <strong>Registration:</strong> When creating an account on our platform, you must provide accurate and complete information. You are responsible for
                maintaining the confidentiality of your account credentials and for all activities that occur under your account.
              </li>
              <li>
                <strong>User Conduct:</strong> You agree to use our platform in a lawful manner and to respect the rights of other users. Any abusive, fraudulent, or illegal
                activities may result in the suspension or termination of your account.
              </li>
              <li>
                <strong>Data Privacy:</strong> We take your privacy seriously. Please review our <Link to="/privacy-policy">Privacy Policy</Link> to understand how we collect, use, and protect your personal information.
              </li>
              <li>
                <strong>Intellectual Property:</strong> All content on Desi Firms, including text, images, and logos, is protected by intellectual property laws. You may not
                use any content from our platform without our prior written consent.
              </li>
              <li>
                <strong>Termination:</strong> We reserve the right to terminate your account at any time if you violate these Terms and Conditions or engage in any activities
                that we deem harmful to our platform or other users.
              </li>
              <li>
                <strong>Pricing and Fees:</strong> Desi Firms reserves the right to increase subscription fees and other charges at any time without prior notice. We recommend that users review the pricing page periodically to stay informed about any changes.
              </li>
            </ol>

            <h3>Terms Specific to Vendors</h3>
            <p>
              In addition to the general terms above, vendors must adhere to the following terms:
            </p>
            <ol>
              <li>
                <strong>Vendor Registration:</strong> Vendors are required to provide accurate and complete information during registration. This includes business details
                such as name, address, and contact information. Vendors are responsible for keeping this information up to date.
              </li>
              <li>
                <strong>Product Listings:</strong> Vendors must ensure that all product and service listings are accurate, lawful, and comply with all applicable regulations.
                Desi Firms reserves the right to remove any listings that violate these terms or our policies.
              </li>
              <li>
                <strong>Regulatory Compliance:</strong> Vendors are responsible for ensuring that their business operations and the products/services they offer comply
                with all applicable laws and regulations.
              </li>
              <li>
                <strong>Intellectual Property Rights:</strong> Vendors retain ownership of any intellectual property rights related to their listings. By submitting content
                to our platform, vendors grant Desi Firms a non-exclusive, royalty-free license to use, reproduce, and distribute this content for promotional purposes.
              </li>
              <li>
                <strong>Fees and Payments:</strong> Vendors agree to pay all applicable fees for using our platform. These fees will be communicated clearly during the
                registration and subscription process. Failure to pay fees may result in the suspension or termination of vendor accounts.
              </li>
              <li>
                <strong>Refund and Return Policies:</strong> Vendors are responsible for setting and clearly communicating their refund and return policies to customers
                before purchases are made.
              </li>
              <li>
                <strong>Customer Relations:</strong> Vendors must maintain a high standard of service, responding to customer inquiries, complaints, and feedback in a
                professional and timely manner.
              </li>
              <li>
                <strong>Account Termination:</strong> Desi Firms reserves the right to terminate vendor accounts at any time if they violate these terms, engage in fraudulent
                activities, or fail to comply with applicable laws.
              </li>
            </ol>

            <h3>Subscription Model and Payment Processing</h3>
            <p>
              Desi Firms offers subscription plans for users and vendors. For secure payment processing, we integrate with Stripe, a third-party provider. Stripe handles
              payments directly, and we never store your full payment details. We may retain minimal information like payment method IDs and subscription IDs for
              billing purposes. Stripe's privacy practices are subject to their own policies, which you agree to when using our platform.
            </p>

            <h3>Data Use and Sharing</h3>
            <p>
              We collect and use data to improve your experience on Desi Firms. This includes using your data to manage subscriptions, verify business legitimacy,
              personalize your experience, and improve search results. For more details, please refer to our <Link to="/privacy-policy">Privacy Policy</Link>.
            </p>

            <h3>Location Data and Cookies</h3>
            <p>
              Desi Firms uses location data derived from your IP address to personalize your experience, improve platform performance, and ensure relevant
              content is displayed. We also use cookies to manage session data and gather analytics. You can manage your cookie preferences through your browser
              settings.
            </p>

            <h3>Business Approval and Banning</h3>
            <p>
              Desi Firms reserves the right to approve, deny, or ban businesses from listing on our platform. This decision may be based on various factors, including
              compliance with our terms of service, the nature of the business, or other relevant considerations. These decisions are final and not subject to appeal.
            </p>

            <h3>Updates to These Terms</h3>
            <p>
              These Terms and Conditions may change from time to time to reflect changes in our practices or legal requirements. We will post any updates on our
              website and notify you of significant changes as required.
            </p>

            <h3>Contact Us</h3>
            <p>
              If you have any questions or concerns about these Terms and Conditions, please contact our support team at <a href="mailto:desifirms@gmail.com">desifirms@gmail.com</a>.
            </p>

            <p>
              By registering as a user or vendor on our platform, you confirm that you have read, understood, and agree to be bound by these Terms and Conditions.
              <a href="/api/register/"  className="ml-2">
                <span className="label">Click here to register.</span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditions;