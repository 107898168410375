import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FaMapMarkerAlt, FaCalendarAlt, FaCity, FaPhone, FaEnvelope, FaShare, FaUser } from 'react-icons/fa';
import { FaBriefcase, FaCar, FaCogs, FaTag, FaHome, FaHeart, FaUtensils, FaShoppingCart } from 'react-icons/fa';


import Spinner from '../components/common/Spinner';
import Map from '../components/common/Map';
import ContentStats from '../components/ratings/ContentStats';
import SharePopup from '../components/common/SharePopup';
import InquiryPopup from '../components/common/InquiryPopup';
import LoginPopup from '../components/common/LoginPopup';
import { getCookie } from '../utils/csrf';

const PageContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const ClassifiedHeader = styled.div`
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 28px;
  color: #333;
  margin-bottom: 10px;
`;

const Price = styled.div`
  font-size: 24px;
  color: #009fe3;
  font-weight: bold;
  margin-bottom: 10px;
`;

const MetaInfo = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

const MetaItem = styled.span`
  display: flex;
  align-items: center;
  color: #666;
  font-size: 14px;

  svg {
    margin-right: 5px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const MainContent = styled.div`
  flex: 2;
`;

const Sidebar = styled.div`
  flex: 1;
`;

const ClassifiedImage = styled.img`
  width: 100%;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const Description = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const ContactInfo = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
`;

const ContactItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    margin-right: 10px;
    color: #009fe3;
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 10px;
  background-color: #009fe3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-bottom: 10px;

  &:hover {
    background-color: #0077b3;
  }

  svg {
    margin-right: 5px;
  }
`;

const TypeTag = styled.span`
  display: inline-block;
  background-color: #28a745;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  margin-bottom: 10px;
`;

// Define the missing `renderFieldValue` function here
const renderFieldValue = (field) => {
  // Capitalize the field name
  const capitalizeFieldName = (fieldName) => {
    return fieldName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  // Format the field value to capitalize and space out
  const formatFieldValue = (value) => {
    if (typeof value === 'string') {
      return value
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
    return value;
  };

  const capitalizedFieldName = capitalizeFieldName(field.field_name);
  const formattedValue = formatFieldValue(field.value);

  // Return formatted display for each field type
  switch (field.field_type) {
    case 'char':
    case 'text':
    case 'int':
    case 'decimal':
      return <p key={field.field_name}><strong>{capitalizedFieldName}:</strong> {formattedValue}</p>;
    case 'bool':
      return <p key={field.field_name}><strong>{capitalizedFieldName}:</strong> {formattedValue ? 'Yes' : 'No'}</p>;
    case 'date':
      return <p key={field.field_name}><strong>{capitalizedFieldName}:</strong> {new Date(formattedValue).toLocaleDateString()}</p>;
    case 'choice':
      return <p key={field.field_name}><strong>{capitalizedFieldName}:</strong> {formattedValue}</p>;
    case 'file':
      return <p key={field.field_name}><strong>{capitalizedFieldName}:</strong> <a href={formattedValue} target="_blank" rel="noopener noreferrer">View File</a></p>;
    default:
      return null;
  }
};


// Define icon mappings for classified types
const classifiedTypeIcons = {
  Jobs: <FaBriefcase />,      // Job classified types
  Automotive: <FaCar />,      // Automotive classified types
  'Service For Hire': <FaCogs />,  // Services classified types
  'Real Estate': <FaHome />,     // Real Estate classified types
  FoodAndDrink: <FaUtensils />,    // Food and drink related classified types
  'Item For Sale': <FaShoppingCart />,  // Item For Sale classified types  //Item for sale classified types
  Healthcare: <FaHeart />, //
  Default: <FaTag />          // Default fallback icon
};

const getClassifiedTypeIcon = (classifiedType) => {
  return classifiedTypeIcons[classifiedType] || classifiedTypeIcons.Default;
};


const ClassifiedDetails = () => {
  const { id } = useParams();
  const [classified, setClassified] = useState(null);
  const [loading, setLoading] = useState(true);
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showInquiryPopup, setShowInquiryPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false); // State for showing the login popup
  const [isAuthenticated, setIsAuthenticated] = useState(false); // To track user authentication status
  const [classifiedFields, setClassifiedFields] = useState([]);

  // Fetch classified details
  useEffect(() => {
    const fetchClassifiedDetails = async () => {
      try {
        const response = await fetch(`/api/classified/${id}/`);
        const data = await response.json();
        setClassified(data);


        if (data.classified_fields) {
          setClassifiedFields(data.classified_fields); // Set the fields dynamically based on classified type
        }
        
        

        if (data.geometry) {
          const pointRegex = /POINT \(([-\d.]+) ([-\d.]+)\)/;
          const match = pointRegex.exec(data.geometry);
          if (match) {
            setCoordinates({
              lat: parseFloat(match[2]),
              lng: parseFloat(match[1]),
            });
          }
        }
      } catch (error) {
        console.error('Error fetching classified details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchClassifiedDetails();
  }, [id]);

  // Check user authentication status
  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetch('/api/auth-status/', { credentials: 'include' });
        const data = await response.json();
        setIsAuthenticated(data.isAuthenticated); // Update the state based on the response
      } catch (error) {
        console.error('Error checking authentication status:', error);
      }
    };

    checkAuthentication();
  }, []);

  const handleSendInquiry = async (inquiryData) => {
    const formData = new FormData();
    formData.append('name', inquiryData.name);
    formData.append('email', inquiryData.email);
    formData.append('message', inquiryData.message);

    try {
      const csrfToken = getCookie('csrftoken');
      const response = await fetch(`/api/inquiry/classified/${id}/`, {
        method: 'POST',
        headers: {
          'X-CSRFToken': csrfToken,
        },
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error sending inquiry:', error);
      return false;
    }
  };

  // Handle inquiry click, show login popup if not authenticated
  const handleInquiryClick = () => {
    if (isAuthenticated) {
      setShowInquiryPopup(true);
    } else {
      setShowLoginPopup(true); // Show login popup if not authenticated
    }
  };

  if (loading) return <Spinner />;
  if (!classified) return <div>Classified not found</div>;

  const shareableLink = `${window.location.origin}/classified/${id}`;

  return (
    <PageContainer>
      <ClassifiedHeader>
         {/* Use dynamic icon based on classified type */}
         <TypeTag>{getClassifiedTypeIcon(classified.classified_type_name)} {classified.classified_type_name}</TypeTag>
        <Title>{classified.title}</Title>
        <Price> {classified.pricing_field_label}:{classified.pricing_field_value ? `$${classified.pricing_field_value}` : 'Contact for price'}</Price>
        <MetaInfo>
          <MetaItem><FaMapMarkerAlt /> {classified.address || 'Address not available'}</MetaItem>
          <MetaItem><FaCalendarAlt /> Posted: {classified.created_at ? new Date(classified.created_at).toLocaleDateString() : 'Date not available'}</MetaItem>
          <MetaItem><FaCity /> {classified.city_name || 'City not available'}</MetaItem>
        </MetaInfo>
        <ContentStats contentType="classified" contentId={classified.id} />
      </ClassifiedHeader>

      <ContentContainer>
        <MainContent>
          <ClassifiedImage src={classified.image || "assets/images/800x530.jpg"} alt={classified.title} />
          <Description>
            <h3>Description</h3>
            <p>{classified.description || 'No description available'}</p>
            <h4>{classified.classified_type_name} Details</h4>
            {classified.field_values && classified.field_values.map(renderFieldValue)}
          </Description>
        </MainContent>

        <Sidebar>
          <ContactInfo>
            <h3>Contact Information</h3>
            <ContactItem>
              <FaUser /> 
              {classified.display_name || 'Name not available'}
            </ContactItem>
            {classified.phone && <ContactItem><FaPhone /> {classified.phone}</ContactItem>}
            {classified.email && <ContactItem><FaEnvelope /> {classified.email}</ContactItem>}
            <ContactItem><FaMapMarkerAlt /> {classified.address || 'Address not available'}</ContactItem>
          </ContactInfo>
          
          <Button onClick={handleInquiryClick}>
            <FaEnvelope /> Send Message
          </Button>

          {classified.phone && (
            <Button onClick={() => console.log('Call seller')}>
              <FaPhone /> Call Seller
            </Button>
          )}
          
          <Button onClick={() => setShowSharePopup(true)}>
            <FaShare /> Share This Classified
          </Button>

          {coordinates.lat && coordinates.lng && (
            <Map latitude={coordinates.lat} longitude={coordinates.lng} />
          )}
        </Sidebar>
      </ContentContainer>

      {showSharePopup && <SharePopup link={shareableLink} onClose={() => setShowSharePopup(false)} />}
      
      {/* Show InquiryPopup only if authenticated */}
      {showInquiryPopup && (
        <InquiryPopup
          onClose={() => setShowInquiryPopup(false)}
          onSendInquiry={handleSendInquiry}
          classifiedFields={classifiedFields} 
          classifiedId={classified.id} 
        />
      )}
      
      {/* Show LoginPopup if not authenticated */}
      {showLoginPopup && (
        <LoginPopup
          message="You need to be logged in to send an inquiry."
          onConfirm={() => window.location.href = `/api/login/?next=${window.location.pathname}`}
          onCancel={() => setShowLoginPopup(false)}
        />
      )}
    </PageContainer>
  );
};

export default ClassifiedDetails;