// src/context/UserContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    isAuthenticated: !!localStorage.getItem('authToken'),
  });
  const [categories, setCategories] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setUser({ isAuthenticated: true });
    }

    const fetchData = async () => {
      setIsLoading(true);
      try {
        const [categoriesResponse, citiesResponse] = await Promise.all([
          fetch('/api/categories/'),
          fetch('/api/cities/')
        ]);
        
        const categoriesData = await categoriesResponse.json();
        const citiesData = await citiesResponse.json();
        
        setCategories(categoriesData);
        setCities(citiesData);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, categories, cities, isLoading, setIsLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);