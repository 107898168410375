// NewsletterForm.js
import React, { useState } from 'react';
import { getCookie } from '../../utils/csrf';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/subscribe/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRFToken': getCookie('csrftoken'),
        },
        body: new URLSearchParams({
          'nl_email': email,
        }),
      });

      if (response.ok) {
        setSuccessMessage(true);
        setErrorMessage('');
        setEmail(''); // Clear the input field
      } else {
        setErrorMessage('Failed to subscribe. Please try again.');
        setSuccessMessage(false);
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
      setSuccessMessage(false);
    }
  };

  return (
    <div className="footer_widgets">
      <h5>Our Newsletter</h5>
      <div className="newsletter_wrap">
        <form id="NewsletterForm" onSubmit={handleSubmit}>
          <input
            type="email"
            className="form-control"
            placeholder="Enter Email Address"
            name="nl_email"
            id="nl_email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input type="submit" value="Subscribe" className="btn" />
        </form>
        {successMessage && (
          <div id="success-message">
            Subscription successful!
          </div>
        )}
        {errorMessage && (
          <div id="error-message" style={{ color: 'red' }}>
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsletterForm;