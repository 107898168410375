import React, { useState, useMemo } from 'react';
import styled from 'styled-components';

const ProductTableContainer = styled.div`
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const TabContainer = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
`;

const TabButton = styled.button`
    padding: 10px 20px;
    border: none;
    background-color: ${props => props.active ? '#007bff' : 'transparent'};
    color: ${props => props.active ? 'white' : '#666'};
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;

    &:hover {
        background-color: ${props => props.active ? '#007bff' : '#f0f0f0'};
    }
`;

const TableControls = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
`;

const SearchInput = styled.input`
    padding: 10px;
    flex-grow: 1;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    gap: 20px;
`;

const FilterGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    label {
        font-weight: 500;
        color: #666;
    }
`;

const FilterSelect = styled.select`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    min-width: 150px;
    &:focus {
        border-color: #007bff;
        outline: none;
    }
`;

const TableWrapper = styled.div`
    max-height: 800px;
    overflow-y: auto;
    border: 1px solid #ddd;
    border-radius: 4px;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
`;

const TableHeader = styled.th`
    padding: 12px;
    background-color: #007bff;
    color: white;
    text-align: left;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
    
    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 2px solid #ddd;
    }

    &:not(:last-child) {
        border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f2f2f2;
    }

    &:hover {
        background-color: #e9ecef;
    }
`;

const TableCell = styled.td`
    padding: 12px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    vertical-align: top;

    &:not(:last-child) {
        border-right: 1px solid #ddd;
    }
`;

const ProductType = styled.span`
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 8px;
    ${props => props.type === 'physical' ? `
        background-color: #e3f2fd;
        color: #1976d2;
    ` : `
        background-color: #f3e5f5;
        color: #7b1fa2;
    `}
`;

const NoProductsMessage = styled.div`
    padding: 20px;
    text-align: center;
    color: #666;
    font-style: italic;
`;

const DescriptionContainer = styled.div`
    position: relative;
`;

const FullDescriptionPopup = styled.div`
    display: none;
    position: absolute;
    background-color: white;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    width: 300px;
    right: 0;
    top: 100%;
    font-size: 14px;
    line-height: 1.5;
    color: #333;
    white-space: normal;
    word-wrap: break-word;

    ${DescriptionContainer}:hover & {
        display: block;
    }
`;

// First, add this styled component with your existing styled components
const DurationDisplay = styled.span`
    color: ${props => {
        switch(props.value?.toLowerCase()) {
            case 'monthly recurring':
                return '#2e7d32'; // Green for recurring
            case 'upon requirement':
                return '#0277bd'; // Blue for flexible
            case '1 week':
                return '#d32f2f'; // Red for short term
            default:
                return 'inherit';
        }
    }};
    font-weight: ${props => 
        ['monthly recurring', 'upon requirement', '1 week'].includes(props.value?.toLowerCase()) 
        ? '600' 
        : 'normal'
    };
`;

const ProductTables = ({ physicalProducts, serviceProducts }) => {
    const [activeTab, setActiveTab] = useState('physical');
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('all');

    const categories = useMemo(() => {
        const uniqueCategories = new Set();
        [...physicalProducts, ...serviceProducts].forEach(product => {
            if (product.category_name) {
                uniqueCategories.add(product.category_name);
            }
        });
        return ['all', ...Array.from(uniqueCategories)].filter(Boolean);
    }, [physicalProducts, serviceProducts]);

    const filterProducts = (products) => {
        return products.filter(product => {
            const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesCategory = categoryFilter === 'all' || product.category_name === categoryFilter;
            return matchesSearch && matchesCategory;
        });
    };

    const formatPrice = (original_price, discount_price, duration_type) => {
        // Show "N/A" if the price is 0 and "Upon Requirement" if the duration type is 'requirement'
        if (original_price === 0) return 'N/A';
        if (duration_type === 'requirement') return 'Upon Requirement';
    
        const formatNumber = (num) => {
            if (typeof num === 'string') return num;
            const parsed = parseFloat(num);
            return isNaN(parsed) ? '0' : parsed.toFixed(2);
        };
    
        if (discount_price) {
            return (
                <span>
                    <del style={{ color: '#999' }}>${formatNumber(original_price)}</del>{' '}
                    <strong style={{ color: '#28a745' }}>${formatNumber(discount_price)}</strong>
                </span>
            );
        }
        return `$${formatNumber(original_price)}`;
    };
    
    const formatDuration = (duration, duration_type, price) => {
        // Show "Monthly Recurring" if duration type is 'recurring'
        if (duration_type === 'recurring') return 'Monthly Recurring';
        // Show "Upon Requirement" if duration type is 'requirement' or price is 0
        if (duration_type === 'requirement' || price === 0) return 'Upon Requirement';
    
        if (!duration) return 'N/A'; // Handle missing duration
    
        const durationInSeconds = typeof duration === 'string' ? parseInt(duration, 10) : duration;
        if (isNaN(durationInSeconds)) return 'N/A';
    
        const totalHours = Math.floor(durationInSeconds / 3600);
        if (totalHours >= 24) {
            const days = Math.floor(totalHours / 24);
            return `${days} day${days > 1 ? 's' : ''}`;
        } else if (totalHours > 0) {
            return `${totalHours} hour${totalHours > 1 ? 's' : ''}`;
        } else {
            const totalMinutes = Math.floor(durationInSeconds / 60);
            return `${totalMinutes} minute${totalMinutes > 1 ? 's' : ''}`;
        }
    };
    

    const renderDescription = (product) => {
        return (
            <DescriptionContainer>
                <div>{product.short_description}</div>
                {product.has_full_description && (
                    <FullDescriptionPopup>
                        {product.description}
                    </FullDescriptionPopup>
                )}
            </DescriptionContainer>
        );
    };
    const renderServiceDetails = (service) => {
        // Service-specific details with additional `duration_type`
        return (
            <div>
                <div><strong>Area:</strong> {service.service_area || 'N/A'}</div>
                <div><strong>Location:</strong> {service.service_location_type}</div>
                {service.appointment_required && <div>Appointment Required</div>}
                <div><strong>Capacity:</strong> {service.capacity} {service.capacity === 1 ? 'person' : 'people'}</div>
                <div><strong>Availability:</strong> {service.availability || 'N/A'}</div>
            </div>
        );
    };
    


    return (
        <ProductTableContainer>
            <TabContainer>
                <TabButton
                    active={activeTab === 'physical'}
                    onClick={() => setActiveTab('physical')}
                >
                    Physical Products ({physicalProducts.length})
                </TabButton>
                <TabButton
                    active={activeTab === 'service'}
                    onClick={() => setActiveTab('service')}
                >
                    Services ({serviceProducts.length})
                </TabButton>
            </TabContainer>

            <TableControls>
                <SearchInput
                    type="text"
                    placeholder={`Search ${activeTab === 'physical' ? 'products' : 'services'}...`}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
                <FiltersContainer>
                    <FilterGroup>
                        <label>Category:</label>
                        <FilterSelect
                            value={categoryFilter}
                            onChange={(e) => setCategoryFilter(e.target.value)}
                        >
                            <option value="all">All Categories</option>
                            {categories.filter(cat => cat !== 'all').map(category => (
                                <option key={category} value={category}>
                                    {category}
                                </option>
                            ))}
                        </FilterSelect>
                    </FilterGroup>
                </FiltersContainer>
            </TableControls>

            <TableWrapper>
                <StyledTable>
                    <thead>
                        <tr>
                            <TableHeader>Name</TableHeader>
                            <TableHeader>Category</TableHeader>
                            <TableHeader>Price</TableHeader>
                            {activeTab === 'physical' ? (
                                <>
                                    <TableHeader>Stock</TableHeader>
                                    <TableHeader>Weight/Dimensions</TableHeader>
                                </>
                            ) : (
                                <>
                                    <TableHeader>Duration Type</TableHeader>
                                    <TableHeader>Duration</TableHeader>
                                    <TableHeader>Service Details</TableHeader>
                                </>
                            )}
                            <TableHeader>Description</TableHeader>
                        </tr>
                    </thead>
                    <tbody>
                        {filterProducts(activeTab === 'physical' ? physicalProducts : serviceProducts)
                            .map(product => (
                            <TableRow key={product.id}>
                                <TableCell>
                                    {product.name}
                                    <ProductType type={activeTab}>
                                        {activeTab === 'physical' ? 'Product' : 'Service'}
                                    </ProductType>
                                </TableCell>
                                <TableCell>{product.category_name}</TableCell>
                                <TableCell>
                                    {formatPrice(product.original_price, product.discount_price)}
                                </TableCell>
                                {activeTab === 'physical' ? (
                                    <>
                                        <TableCell>
                                            Stock: {product.stock}
                                        </TableCell>
                                        <TableCell>
                                            {product.weight && `Weight: ${product.weight}kg`}
                                            {product.weight && product.dimensions && <br />}
                                            {product.dimensions && `Dimensions: ${product.dimensions}`}
                                        </TableCell>
                                    </>
                                ) : (
                                    <>
                                        <TableCell>{product.duration_type_display || product.duration_type}</TableCell>
                                        <TableCell>
                                            <DurationDisplay value={formatDuration(product.duration_display, product.duration_type, product.price)}>
                                                {formatDuration(product.duration_display, product.duration_type, product.price)}
                                            </DurationDisplay>
                                        </TableCell>
                                        <TableCell>{renderServiceDetails(product)}</TableCell>
                                    </>
                                )}
                                <TableCell>
                                    {renderDescription(product)}
                                </TableCell>
                            </TableRow>
                        ))}
                        {filterProducts(activeTab === 'physical' ? physicalProducts : serviceProducts).length === 0 && (
                            <TableRow>
                                <TableCell colSpan={activeTab === 'physical' ? 6 : 6}>
                                    <NoProductsMessage>
                                        No {activeTab === 'physical' ? 'products' : 'services'} found matching your criteria
                                    </NoProductsMessage>
                                </TableCell>
                            </TableRow>
                        )}
                    </tbody>
                </StyledTable>
            </TableWrapper>
        </ProductTableContainer>
    );
};

export default ProductTables;


// import React, { useState, useMemo } from 'react';
// import styled from 'styled-components';

// const ProductTableContainer = styled.div`
//     padding: 20px;
//     max-width: 100%;
//     margin: 0 auto;
//     background-color: #f9f9f9;
//     border-radius: 8px;
//     box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
// `;

// const TableControls = styled.div`
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 20px;
//     gap: 20px;
// `;

// const SearchInput = styled.input`
//     padding: 10px;
//     flex-grow: 1;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     font-size: 16px;
//     &:focus {
//         border-color: #007bff;
//         outline: none;
//     }
// `;

// const FiltersContainer = styled.div`
//     display: flex;
//     gap: 20px;
//     margin-bottom: 20px;
// `;

// const FilterGroup = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 10px;

//     label {
//         font-weight: 500;
//         color: #666;
//     }
// `;

// const FilterSelect = styled.select`
//     padding: 10px;
//     border: 1px solid #ddd;
//     border-radius: 4px;
//     font-size: 16px;
//     min-width: 150px;
//     &:focus {
//         border-color: #007bff;
//         outline: none;
//     }
// `;

// const TableWrapper = styled.div`
//     max-height: 800px;
//     overflow-y: auto;
//     border: 1px solid #ddd;
//     border-radius: 4px;

//     &::-webkit-scrollbar {
//         width: 8px;
//     }

//     &::-webkit-scrollbar-track {
//         background: #f1f1f1;
//     }

//     &::-webkit-scrollbar-thumb {
//         background: #888;
//         border-radius: 4px;
//     }

//     &::-webkit-scrollbar-thumb:hover {
//         background: #555;
//     }
// `;

// const StyledTable = styled.table`
//     width: 100%;
//     border-collapse: separate;
//     border-spacing: 0;
// `;

// const TableHeader = styled.th`
//     padding: 12px;
//     background-color: #007bff;
//     color: white;
//     text-align: left;
//     font-weight: 600;
//     position: sticky;
//     top: 0;
//     z-index: 10;
    
//     &:after {
//         content: '';
//         position: absolute;
//         left: 0;
//         bottom: 0;
//         width: 100%;
//         border-bottom: 2px solid #ddd;
//     }

//     &:not(:last-child) {
//         border-right: 1px solid rgba(255, 255, 255, 0.2);
//     }
// `;

// const TableRow = styled.tr`
//     &:nth-child(even) {
//         background-color: #f2f2f2;
//     }

//     &:hover {
//         background-color: #e9ecef;
//     }
// `;

// const TableCell = styled.td`
//     padding: 12px;
//     border-bottom: 1px solid #ddd;
//     text-align: left;
//     vertical-align: top;

//     &:not(:last-child) {
//         border-right: 1px solid #ddd;
//     }
// `;

// const ProductType = styled.span`
//     padding: 4px 8px;
//     border-radius: 4px;
//     font-size: 12px;
//     font-weight: 600;
//     margin-left: 8px;
//     ${props => props.type === 'physical' ? `
//         background-color: #e3f2fd;
//         color: #1976d2;
//     ` : `
//         background-color: #f3e5f5;
//         color: #7b1fa2;
//     `}
// `;

// const DescriptionContainer = styled.div`
//     position: relative;
// `;

// const Keywords = styled.div`
//     margin-top: 4px;
//     display: flex;
//     flex-wrap: wrap;
//     gap: 4px;
// `;

// const Keyword = styled.span`
//     background-color: #e3f2fd;
//     color: #1976d2;
//     padding: 2px 6px;
//     border-radius: 12px;
//     font-size: 11px;
//     white-space: nowrap;
// `;

// const FullDescriptionPopup = styled.div`
//     display: none;
//     position: absolute;
//     background-color: white;
//     border: 1px solid #ddd;
//     padding: 15px;
//     border-radius: 4px;
//     box-shadow: 0 2px 8px rgba(0,0,0,0.1);
//     z-index: 1000;
//     width: 300px;
//     right: 0;
//     top: 100%;
//     font-size: 14px;
//     line-height: 1.5;
//     color: #333;
//     white-space: normal;
//     word-wrap: break-word;

//     ${DescriptionContainer}:hover & {
//         display: block;
//     }
// `;

// const NoProductsMessage = styled.div`
//     padding: 20px;
//     text-align: center;
//     color: #666;
//     font-style: italic;
// `;

// const ProductTable = ({ products }) => {
//     const [searchTerm, setSearchTerm] = useState('');
//     const [typeFilter, setTypeFilter] = useState('all');
//     const [categoryFilter, setCategoryFilter] = useState('all');

//     const categories = useMemo(() => {
//         const uniqueCategories = new Set(products.map(product => product.category_name));
//         return ['all', ...Array.from(uniqueCategories)].filter(Boolean);
//     }, [products]);

//     const filteredProducts = products.filter(product => {
//         const matchesSearch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
//         const matchesType = typeFilter === 'all' || 
//             (typeFilter === 'physical' && product.stock !== undefined) || 
//             (typeFilter === 'service' && product.duration !== undefined);
//         const matchesCategory = categoryFilter === 'all' || product.category_name === categoryFilter;
        
//         return matchesSearch && matchesType && matchesCategory;
//     });

//     const formatPrice = (original_price, discount_price) => {
//         if (discount_price) {
//             return (
//                 <span>
//                     <del style={{ color: '#999' }}>${original_price}</del>{' '}
//                     <strong style={{ color: '#28a745' }}>${discount_price}</strong>
//                 </span>
//             );
//         }
//         return `$${original_price}`;
//     };

//     const renderDescription = (product) => {
//         return (
//             <DescriptionContainer>
//                 <div>{product.short_description}</div>
//                 {product.key_terms?.length > 0 && (
//                     <Keywords>
//                         {product.key_terms.map((term, index) => (
//                             <Keyword key={index}>{term}</Keyword>
//                         ))}
//                     </Keywords>
//                 )}
//                 {product.has_full_description && (
//                     <FullDescriptionPopup>
//                         {product.description}
//                     </FullDescriptionPopup>
//                 )}
//             </DescriptionContainer>
//         );
//     };

//     return (
//         <ProductTableContainer>
//             <TableControls>
//                 <SearchInput
//                     type="text"
//                     placeholder="Search products..."
//                     value={searchTerm}
//                     onChange={(e) => setSearchTerm(e.target.value)}
//                 />
//                 <FiltersContainer>
//                     <FilterGroup>
//                         <label>Type:</label>
//                         <FilterSelect 
//                             value={typeFilter} 
//                             onChange={(e) => setTypeFilter(e.target.value)}
//                         >
//                             <option value="all">All Types</option>
//                             <option value="physical">Physical Products</option>
//                             <option value="service">Services</option>
//                         </FilterSelect>
//                     </FilterGroup>
                    
//                     <FilterGroup>
//                         <label>Category:</label>
//                         <FilterSelect
//                             value={categoryFilter}
//                             onChange={(e) => setCategoryFilter(e.target.value)}
//                         >
//                             <option value="all">All Categories</option>
//                             {categories.filter(cat => cat !== 'all').map(category => (
//                                 <option key={category} value={category}>
//                                     {category}
//                                 </option>
//                             ))}
//                         </FilterSelect>
//                     </FilterGroup>
//                 </FiltersContainer>
//             </TableControls>

//             <TableWrapper>
//                 <StyledTable>
//                     <thead>
//                         <tr>
//                             <TableHeader>Name</TableHeader>
//                             <TableHeader>Category</TableHeader>
//                             <TableHeader>Price</TableHeader>
//                             <TableHeader>Details</TableHeader>
//                             <TableHeader>Description</TableHeader>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {filteredProducts.length > 0 ? (
//                             filteredProducts.map(product => (
//                                 <TableRow key={product.id}>
//                                     <TableCell>
//                                         {product.name}
//                                         <ProductType type={'stock' in product ? 'physical' : 'service'}>
//                                             {'stock' in product ? 'Product' : 'Service'}
//                                         </ProductType>
//                                     </TableCell>
//                                     <TableCell>{product.category_name}</TableCell>
//                                     <TableCell>
//                                         {formatPrice(product.original_price, product.discount_price)}
//                                     </TableCell>
//                                     <TableCell>
//                                         {product.stock !== undefined ? `Stock: ${product.stock}` : `Duration: ${product.duration_display}`}
//                                     </TableCell>
//                                     <TableCell>
//                                         {renderDescription(product)}
//                                     </TableCell>
//                                 </TableRow>
//                             ))
//                         ) : (
//                             <tr>
//                                 <td colSpan="5">
//                                     <NoProductsMessage>
//                                         No products found matching your criteria
//                                     </NoProductsMessage>
//                                 </td>
//                             </tr>
//                         )}
//                     </tbody>
//                 </StyledTable>
//             </TableWrapper>
//         </ProductTableContainer>
//     );
// };

// export default ProductTable;