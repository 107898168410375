import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { MdBusinessCenter, MdLocalOffer, MdAssignment, MdEvent } from 'react-icons/md';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const Card = styled(Link)`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 20px;
  width: calc(25% - 15px);
  text-align: center;
  text-decoration: none;
  color: #333 !important; /* Enforce the text color */
  transition: all 0.3s ease;
  animation: ${fadeIn} 0.5s ease-out;
  animation-fill-mode: backwards;
  box-shadow: 0 5px 15px rgba(0,0,0,0.2);
  backdrop-filter: blur(5px);

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 20px rgba(0,0,0,0.3);
  }

  @media (max-width: 768px) {
    width: calc(50% - 10px);
    margin-bottom: 20px;
  }
`;

const IconWrapper = styled.div`
  color: #00A8E8 !important; /* Enforce the icon color */
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 10px;
  color: #000 !important; /* Enforce the title color */
`;

const Description = styled.p`
  font-size: 14px;
  color: #333 !important; /* Enforce the description color */
`;

const CategoryCards = () => {
  const categories = [
    { icon: MdAssignment, title: "Classifieds", description: "Browse community classifieds and announcements", link: "/classifieds" },
    { icon: MdBusinessCenter, title: "Listings", description: "Discover local desi businesses and services", link: "/all-listings" },
    { icon: MdLocalOffer, title: "Deals", description: "Explore exclusive offers and discounts", link: "/deals-list" },
    { icon: MdEvent, title: "Events", description: "Find upcoming desi events near you", link: "/events-list" }
  ];

  return (
    <CardContainer>
      {categories.map((category, index) => (
        <Card to={category.link} key={index} style={{ animationDelay: `${index * 0.1}s` }}>
          <IconWrapper>
            <category.icon size={60} />
          </IconWrapper>
          <Title>{category.title}</Title>
          <Description>{category.description}</Description>
        </Card>
      ))}
    </CardContainer>
  );
};

export default CategoryCards;